import React from 'react';
import PropTypes from 'prop-types';
import { Header } from '../../journeys/common/header';
import Footer from '../../journeys/common/footer/Footer';
import { BodyWrapper } from './SiteWrapper.styled';

const SiteWrapper = (props) => {
    const { children } = props;
    const { headerText, height, headerButtonHandler, headerPrintButtonHandler, headerButtonText,
        showInfoIcon, ButtonSection, totalCount, showTotalCount, headerButtonHeight,
        headerButtonWidth, displayFormStatus, displayUnsavedChangesPopUp,
        showOfflineFormsCount, titleFontSize, switchSRAForm, formName, isDPSDisabled } = props; // props for header
    const { footerButtonHandler, footerButtonText, isDisabled, FooterButtonVisible, widerFooter, draftButtonHandler,
        openCameraButtonVisible, openCameraHandler, closeCameraButtonVisible, closeCameraHandler, draftButtonVisible,
        trfSelectionVisible, goBack, TagSelectionPrintButtonVisible, PrintButtonHandler,
        footerNoteMoVisible } = props; // footer button props
    return (
        <div className="container">
            <div id="trfimage">
                <div id="header">
                    <Header
                        headerText={headerText}
                        formName={formName}
                        titleFontSize={titleFontSize}
                        handleButton={headerButtonHandler}
                        headerPrintButtonHandler={headerPrintButtonHandler}
                        buttonText={headerButtonText}
                        showInfoIcon={showInfoIcon}
                        ButtonSection={ButtonSection}
                        showOfflineFormsCount={showOfflineFormsCount}
                        showTotalCount={showTotalCount}
                        totalCount={totalCount}
                        closeCameraButtonVisible={closeCameraButtonVisible}
                        displayFormStatus={displayFormStatus}
                        buttonHeight={headerButtonHeight}
                        buttonWidth={headerButtonWidth}
                        switchSRAForm={switchSRAForm}
                        goBack={goBack}
                        displayUnsavedChangesPopUp={displayUnsavedChangesPopUp}
                        isDPSDisabled={isDPSDisabled}
                    />
                </div>
                <BodyWrapper id="bodywrapper" headerText={headerText} height={height}>
                    {children}
                </BodyWrapper>
            </div>
            <Footer
                headerText={headerText}
                handleButton={footerButtonHandler}
                buttonText={footerButtonText}
                buttonVisible={FooterButtonVisible}
                draftButtonVisible={draftButtonVisible}
                printButtonVisible={TagSelectionPrintButtonVisible}
                printButtonHandler={PrintButtonHandler}
                draftButtonHandler={draftButtonHandler}
                widerFooter={widerFooter}
                openCameraButtonVisible={openCameraButtonVisible}
                openCameraHandler={openCameraHandler}
                displayFormStatus={displayFormStatus}
                closeCameraButtonVisible={closeCameraButtonVisible}
                closeCameraHandler={closeCameraHandler}
                trfSelectionVisible={trfSelectionVisible}
                isDisabled={isDisabled}
                footerNoteMoVisible={footerNoteMoVisible}
            />
        </div>
    );
};

export default SiteWrapper;

SiteWrapper.propTypes = {
    ButtonSection: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.string,
        PropTypes.object
    ]),
    children: PropTypes.node,
    closeCameraButtonVisible: PropTypes.bool,
    closeCameraHandler: PropTypes.func,
    displayFormStatus: PropTypes.bool,
    displayUnsavedChangesPopUp: PropTypes.bool,
    draftButtonHandler: PropTypes.func,
    draftButtonVisible: PropTypes.bool,
    footerButtonHandler: PropTypes.func,
    footerButtonText: PropTypes.string,
    FooterButtonVisible: PropTypes.any,
    footerNoteMoVisible: PropTypes.bool,
    formName: PropTypes.string,
    goBack: PropTypes.string,
    headerButtonHandler: PropTypes.func,
    headerButtonHeight: PropTypes.number,
    headerButtonText: PropTypes.string,
    headerButtonWidth: PropTypes.number,
    headerPrintButtonHandler: PropTypes.func,
    headerText: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ]),
    height: PropTypes.string,
    isDisabled: PropTypes.bool,
    isDPSDisabled: PropTypes.bool,
    openCameraButtonVisible: PropTypes.bool,
    openCameraHandler: PropTypes.func,
    PrintButtonHandler: PropTypes.func,
    showInfoIcon: PropTypes.bool,
    showOfflineFormsCount: PropTypes.bool,
    showTotalCount: PropTypes.bool,
    switchSRAForm: PropTypes.func,
    TagSelectionPrintButtonVisible: PropTypes.bool,
    titleFontSize: PropTypes.string,
    totalCount: PropTypes.number,
    trfSelectionVisible: PropTypes.bool,
    widerFooter: PropTypes.string
};
