import { Result } from '../../Sections.constants';

const generalLabels = [
    'Equipment carries an Ex Certification Identification plate and correct circuit identification',
    'Equipment and glands have IP rating appropriate for location and correctly tightened',
    'Equipment is not obviously damaged',
    'Internal components are not obviously damaged',
    'There is no evidence of unauthorized modifications',
    'Enclosure and gaskets, glass or glass-fibre parts and glass‐to‐metal sealing gaskets and/or compounds are satisfactory',
    'Bolts are of the correct type',
    'All unused cable entries are plugged and entry plugs are of the correct type',
    'Cable glands are of the correct type, complete and tight',
    'Cable type is appropriate',
    'Cables/cores not in use are correctly terminated',
    'Cable adequately supported and cable/conduits not obviously damaged',
    'Sealing of trunking, ducts, pipes and/or conduits is satisfactory',
    'All earth connections are satisfactory (tight, good mechanical connection),including bonding',
    'Equipment protected against corrosion/weather/vibration/adverse conditions',
    'There is no evidence of ingress of water or dust/dirt in the enclosure.',
    'Lamps are not indicating any end of life effects',
    'Motor fans have sufficient clearance to covers, cooling systems are undamaged, motor foundations have no indentations or cracks',
    'Ventilation airflow is not impeded',
    'Breathing and draining devices are satisfactory',
    'Lamp rating, type, position, pin configuration are correct and not indicating end of life effects',
    'Enclosure gasket condition is clean, undamaged, etc.',
    'All electrical connections are tight',
    'Integrity of conduit system and interface with mixed system is maintained',
    'Equipment is adequately protected against corrosion, weather, vibration and other adverse factors',
    'Electrical insulation is clean and dry'
];

const paddingValue = '0 0 10px 8px';
export const inspectionChecklist = (formName, formData) => {
    const rows = [
        {
            key: 1,
            fields: [{
                key: 'As-FoundTest',
                label: 'As-Found Inspection Checklist',
                isLabel: true,
                xs: 12,
                fontWeight: '700',
                padding: '5px 0 5px 8px'
            }]
        },
        {
            key: 2,
            fields: [{
                key: 'GExGeneral',
                label: 'G-Ex General',
                isLabel: true,
                fontWeight: '700',
                xs: 1,
                textAlign: 'left',
                padding: paddingValue,
                fontSize: '9pt',
            },
            {
                key: 'GExGeneralLabel',
                label: '',
                isLabel: true,
                fontWeight: '700',
                xs: 4,
                textAlign: 'center',
                padding: paddingValue,
                fontSize: '9pt',
            },
            {
                key: 'Result',
                label: '',
                isLabel: true,
                fontWeight: '700',
                xs: 1,
                textAlign: 'center',
                padding: paddingValue,
                fontSize: '9pt',
            },
            {
                key: 'Comments',
                label: 'Comments',
                isLabel: true,
                fontWeight: '700',
                xs: 6,
                textAlign: 'center',
                padding: paddingValue,
                fontSize: '9pt',
            }]
        },
    ];

    for (let i = 0; i <= 25; i += 1) {
        const label = `G${i + 1}`;
        rows.push({
            key: i + 3,
            fields: [
                {
                    key: `LabelGeneral${i}`,
                    label,
                    xs: 1,
                    labelWidth: '100%',
                    textAlign: 'center',
                    isLabel: true,
                    fontWeight: '700',
                    padding: '0',
                    fontSize: '9pt',
                },
                {
                    key: `Label${i}`,
                    label: generalLabels[i],
                    xs: 5,
                    textAlign: 'left',
                    fontWeight: '600',
                    fontSize: '9pt',
                    labelWidth: '100%',
                    isLabel: true,
                    padding: '0 2px 0 0'
                },
                {
                    key: `InspectionChecklistG${i + 1}`,
                    value: formData[`InspectionChecklistG${i + 1}`] ?? Result.RESULT_PASS,
                    isRequired: true,
                    xs: 1,
                    labelWidth: '0%',
                    textAlign: 'center',
                    width: '100%',
                    height: '2.3rem',
                    isDropdown: true,
                    options: [
                        { name: Result.RESULT_PASS, value: Result.RESULT_PASS },
                        { name: Result.RESULT_FAIL, value: Result.RESULT_FAIL },
                        { name: Result.RESULT_NA, value: Result.RESULT_NA }
                    ],
                    inputTextAlign: 'center',
                    borderBottom: '0',
                    marginBottom: i === (generalLabels.length - 1) ? '4px' : undefined,
                    paddingPlaceholder: '7px',
                    arrowMarginTop: '7px',
                    noDefaultOption: true
                },
                {
                    key: `InspectionCommentsG${i + 1}`,
                    value: formData[`InspectionCommentsG${i + 1}`],
                    xs: 5,
                    labelWidth: '0%',
                    inputWidth: '100%',
                    textAlign: 'center',
                    borderBottom: '0',
                    borderRight: '0',
                    borderLeft: '0',
                    inputMarginRight: '4px',
                    maxLength: '60',
                    marginBottom: i === (generalLabels.length - 1) ? '4px' : '',
                    isMultiLine: true,
                    marginRight: '0',
                }
            ]
        });
    }
    return { rows };
};

export const selectInspectionChecklistData = (selectedTag, formName, formData) => {
    let filterViewData;
    if (formName === 'M4-2') {
        filterViewData = inspectionChecklist(formName, formData);
    } else {
        filterViewData = { rows: [] };
    }
    return filterViewData;
};
