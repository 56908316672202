/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import PrintIcon from '@material-ui/icons/Print';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import PaletteIcon from '@material-ui/icons/Palette';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { headerIconButtonStyles } from './customHeaderButton.styles';

const GetIcon = ({ iconType, ...props }) => {
    switch (iconType) {
        case 'add':
            return <AddIcon {...props} />;
        case 'info':
            return <InfoIcon {...props} />;
        case 'account':
            return <AccountCircleIcon {...props} />;
        case 'print':
            return <PrintIcon {...props} />;
        case 'palette':
            return <PaletteIcon {...props} />;
        default:
            return undefined;
    }
};

/**
 * @param {function} buttonHandler custom handler for this button (Required)
 * @param {String} className string (Required)
 * @param {String} fontSize string
 * @param {String} iconType string (Required)
 * @param {Number} rotate Number ex: to rotate 90degree give 90
 */
export const HeaderButtonMaker = ({ buttonHandler, iconType, id, fontSize, className, rotate, tooltip }) => {
    const classes = headerIconButtonStyles({ rotate });
    return (
        <div className={classes.iconContainer} id={id ? id.concat('_container') : ''}>
            {tooltip
                ? (
                    <Tooltip title={tooltip} aria-label={tooltip} classes={{ tooltip: classes.tooltip }} placement="bottom">
                        <IconButton component="span" onClick={() => buttonHandler()}>
                            <GetIcon iconType={iconType} fontSize={fontSize} className={classes[className]} />
                        </IconButton>
                    </Tooltip>
                )
                : (
                    <IconButton component="span" onClick={() => buttonHandler()}>
                        <GetIcon iconType={iconType} fontSize={fontSize} className={classes[className]} />
                    </IconButton>
                )}

        </div>
    );
};

HeaderButtonMaker.defaultProps = {
    fontSize: 'large'
};

GetIcon.propTypes = {
    iconType: PropTypes.string.isRequired
};

HeaderButtonMaker.propTypes = {
    buttonHandler: PropTypes.func.isRequired,
    className: PropTypes.string.isRequired,
    fontSize: PropTypes.string,
    iconType: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    rotate: PropTypes.number,
    tooltip: PropTypes.string,
};
