import { ExponentialToDecimal, FieldHasValue, ValidateNumberField } from 'helpers/Validation';
import { commonConstants } from 'journeys/portal/Forms/Sections.constants';

export const AsLeftTestTableDeviationEu1Formula = (formData) => FieldHasValue(formData.F69Tx2Reading1) && FieldHasValue(formData.F803TestDeviceReading)
    ? ExponentialToDecimal(Math.abs(ValidateNumberField(formData.F69Tx2Reading1) - ValidateNumberField(formData.F803TestDeviceReading))) : '';
export const AsLeftTestTableDeviationEu2Formula = (formData) => FieldHasValue(formData.F70Tx2Reading2) && FieldHasValue(formData.F804TestDeviceReading)
    ? ExponentialToDecimal(Math.abs(ValidateNumberField(formData.F70Tx2Reading2) - ValidateNumberField(formData.F804TestDeviceReading))) : '';
export const AsLeftTestTableDeviationEu3Formula = (formData) => FieldHasValue(formData.F71Tx2Reading3) && FieldHasValue(formData.F805TestDeviceReading)
    ? ExponentialToDecimal(Math.abs(ValidateNumberField(formData.F71Tx2Reading3) - ValidateNumberField(formData.F805TestDeviceReading))) : '';

export const AsLeftResultFormulaP6022 = (formData, formName) => {
    if (FieldHasValue(formData.F69Tx2Reading1)
        && FieldHasValue(formData.F70Tx2Reading2)
        && FieldHasValue(formData.F71Tx2Reading3)
        && FieldHasValue(formData.F803TestDeviceReading)
        && FieldHasValue(formData.F804TestDeviceReading)
        && FieldHasValue(formData.F805TestDeviceReading)
        && FieldHasValue(formData.F72Hmi2Reading1)
        && FieldHasValue(formData.F73Hmi2Reading2)
        && FieldHasValue(formData.F74Hmi2Reading3)
        && FieldHasValue(formData.F101AftTolerance)) {
        return Math.max.apply(Math, [ValidateNumberField(formData.F78Deviation2Eu1),
        ValidateNumberField(formData.F79Deviation2Eu2),
        ValidateNumberField(formData.F80Deviation2Eu3)]) <= ValidateNumberField(formData.F101AftTolerance)
            ? commonConstants.PASS_Result : commonConstants.FAIL_Result;
    }
    return '';
};
