/* eslint-disable react/prop-types */
import React from 'react';
import { HeaderButtonMaker } from './HeaderButtonMaker';
/**
 * @param {function} buttonHandler custom handler for this button (Required)
 * @param {Number} rotate Number ex: to rotate 90degree give 90
 * @param {String} tooltip String, tooltip text to be displayed under button
 */
export const AddButton = ({ buttonHandler, id, rotate, tooltip }) => (
    <HeaderButtonMaker
        id={id}
        buttonHandler={buttonHandler}
        rotate={rotate}
        tooltip={tooltip}
        className="icon"
        fontSize="large"
        iconType="add"
    />
);

/**
 * @param {function} buttonHandler custom handler for this button (Required)
 * @param {String} tooltip String, tooltip text to be displayed under button
 */
export const InformationButton = ({ buttonHandler, id, tooltip }) => (
    <HeaderButtonMaker
        id={id || 'InformationButton'}
        buttonHandler={buttonHandler}
        tooltip={tooltip}
        className="icon"
        fontSize="large"
        iconType="info"
    />
);

export const AccountButton = ({ buttonHandler, id, tooltip }) => (
    <HeaderButtonMaker
        id={id || 'accountButton'}
        buttonHandler={buttonHandler}
        tooltip={tooltip}
        className="icon"
        fontSize="large"
        iconType="account"
    />
);

export const PrintButton = ({ buttonHandler, id, tooltip }) => (
        <HeaderButtonMaker
            id={id || 'PrintButton'}
            buttonHandler={buttonHandler}
            tooltip={tooltip}
            className="icon"
            fontSize="medium"
            iconType="print"
        />
);

export const PaletteButton = ({ buttonHandler, id, tooltip }) => (
    <HeaderButtonMaker
        id={id || 'PaletteButton'}
        buttonHandler={buttonHandler}
        tooltip={tooltip}
        className="icon"
        fontSize="large"
        iconType="palette"
    />
);
