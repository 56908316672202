import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SiteWrapper from 'components/SiteWrapper/SiteWrapper';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { SearchableDropdown } from 'elements/Inputs/FormInput/SearchableDropdown';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import IconButton from '@material-ui/core/IconButton';
import { Colors } from 'helpers/Colors';
import { setSelectedTRF } from '../../Forms/Forms.actions';
import { DropDownElement, TRFText, ArrowElement } from './TRFSelection.styled';
import { setCurrentView } from '../../Home/Home.actions';
import { selectForm } from 'journeys/common/footer/TrfDropdownSelect';

const TRFSelection = (props) => {
    const { settingSelectedTRF, trfMasterList, setCurrentAppView, selectedTag, workType } = props;
    const activeTRFId = trfMasterList.filter(x => x.Status === 'Active' && x.TrfShortDesc !== 'Not In MRAT'
        && x.WorkType.toLowerCase() === workType.toLowerCase());
    const [selectTrfId, setSelectedTrfId] = useState('');
    const actionButtonHandler = () => {
        settingSelectedTRF(selectTrfId);
        selectTrfId && setCurrentAppView(selectTrfId);
    };

    const formHandler = (obj) => {
        setSelectedTrfId(trfMasterList.find(x => x.TrfShortDescReplaceWhenActive === obj.trfMasterList)?.TrfId);
    };

    return (
        <SiteWrapper
            headerText={
                (
                    <div style={{ textAlign: 'center' }}>
                        {`${selectForm} for MO-`}
                        {selectedTag.HdWorkOrder}
                        <br />
                        {selectedTag.HdLocation}
                    </div>
                )
            }
            goBack="tagselectionmoscreen"
        >
            <div className="container">
                <Grid container>
                    <Grid container item xs={12}>
                        <Grid item xs={12}>
                            <TRFText>
                            {`${selectForm}`}
                            </TRFText>
                        </Grid>
                    </Grid>
                    <Grid container item xs={12}>
                        <Grid item xs={12} style={{ display: 'flex' }}>
                            <div style={{ display: 'flex', marginLeft: 'auto', marginRight: 'auto' }}>
                                <DropDownElement>
                                    <SearchableDropdown
                                        width="38rem"
                                        height="3rem"
                                        topPadding="6px"
                                        name="trfMasterList"
                                        value={activeTRFId.find(x => x.TrfId === selectTrfId)?.TrfShortDescReplaceWhenActive || ''}
                                        placeholder="Search Form"
                                        handleChange={formHandler}
                                        options={activeTRFId
                                            .map(x => ({ name: x.TrfShortDescReplaceWhenActive, value: x.TrfShortDescReplaceWhenActive }))}
                                        marginLeft="0"
                                        topPaddingNoOptions="18px"
                                    />
                                </DropDownElement>
                                <ArrowElement>
                                    <IconButton disabled={!selectTrfId} component="span" onClick={() => actionButtonHandler()}>
                                        <ArrowForwardIosIcon
                                            id="ActionButtonArrow"
                                            style={{ fontSize: '4.5rem', color: selectTrfId ? Colors.green : Colors.grey, width: '55px', height: '57px' }}
                                        />
                                    </IconButton>
                                </ArrowElement>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </SiteWrapper>
    );
};

const mapStateToProps = ({ Home, MOSelection, TagSelectionMOScreen, Forms }) => ({
    selectedRowTags: TagSelectionMOScreen.selectedRowTags,
    trfMasterList: Home.trfMasterList,
    workType: Home.selectedWorkType,
    totalCount: TagSelectionMOScreen.totalCount,
    userRole: Home.userRole,
    selectedTrfId: Forms.selectedTrfId,
    selectedTag: Forms.selectedTag
});

const mapDispatchToProps = dispatch => ({
    dispatch,
    settingSelectedTRF: (selectedTrfId) => dispatch(setSelectedTRF(selectedTrfId)),
    setCurrentAppView: (view) => dispatch(setCurrentView(view)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TRFSelection);

TRFSelection.propTypes = {
    selectedTag: PropTypes.object,
    setCurrentAppView: PropTypes.func,
    settingSelectedTRF: PropTypes.func,
    trfMasterList: PropTypes.array,
    workType: PropTypes.string
};
