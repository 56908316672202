import styled from 'styled-components';

export const StyledLabel = styled.div`
    margin-bottom: ${props => props.marginBottom ? props.marginBottom : '5px'};
    font-weight: ${props => props.fontWeight ? props.fontWeight : '600'};
    margin-left:  ${props => props.marginLeft ? props.marginLeft : '1rem'};
    font-size: ${props => props.fontSize ? props.fontSize : '10pt'};
    padding-left: ${props => props.paddingLeft};
    text-align: ${props => props.textAlign};
    color: ${props => props.fontColor ? props.fontColor : 'black'};
`;

export const SelectWrapper = styled.div`
    margin-bottom: ${props => props.marginBottom ? props.marginBottom : '5px'};
    margin-top: ${props => props.marginTop};
    .Dropdown-root {       
        width: ${props => props.width ? props.width : '100%'};
        height: ${props => props.height ? props.height : '2rem'};
    }
    .Dropdown-control {
        width: 100%;
        margin-left:  ${props => props.marginLeft ? props.marginLeft : '1rem'};
        height:  ${props => props.height ? props.height : '2rem'};
        padding: ${props => props.padding ? props.padding : '5px'};
        background-color: ${props => props.bgColor || 'rgb(255, 255, 255)'};
    }
    }
}
    .Dropdown-noresults{
        font-size: 0pt;
        min-height: 2rem;
        background-color: rgba(73, 130, 5, 1)
    }
    .Dropdown-menu {
        margin-left: ${props => props.menuMarginLeft ? props.menuMarginLeft : '1rem'};
    }
    .isLocked.isRequired .Dropdown-control {
        background-color: rgb(170, 170, 170);
        margin-left: 1rem;
    }
    .isRequired.readOnly .Dropdown-control:hover {
        background-color: rgb(255, 255, 0);
        margin-left: 1rem;
    }
      .Dropdown-arrow {
        padding: ${props => props.arrowPadding ? props.arrowPadding : '5px'};
    }
`;
