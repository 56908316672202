import styled from 'styled-components';

export const StyledLabelSpan = styled.span`
padding: ${props => props.padding ? props.padding : '5px 25px 5px 0'};
font-weight:  ${props => props.fontWeight};
color: ${props => props.color ? props.color : 'rgb(51, 51, 51)'};
font-size: ${props => props.fontSize ? props.fontSize : '10pt'};
font-style: ${props => props.fontStyle};
font-family: "Segoe UI";
color: ${props => props.color};
border: 0;
margin-left:${props => props.marginLeft};
margin-top:${props => props.marginTop};
top:${props => props.top};
position:${props => props.top ? 'relative' : null};
display: inherit;
text-align: ${props => props.textAlign ? props.textAlign : 'left'};
width: ${props => props.labelWidth ? props.labelWidth : '100%'};
`;
