import { conditionalValueSelector } from 'helpers/Validation';

export const createTableDefinitionObject = (ColumnName, columnTitle, width) => (
  {
    column: ColumnName,
    columnTitle,
    width
  }
);

export function searchFunction(searchVal = '', searchSpace = []) {
  return new Promise((resolve, reject) => {
    try {
      const filteredData = searchSpace.filter(row => Object.values(row).some((value) => String(value).toLowerCase().includes(searchVal?.toLowerCase())));
      resolve(filteredData);
    } catch (e) {
      reject(e);
    }
  }
  );
}

export function searchTEFunction(searchVal = '', searchSpace = []) {
  return new Promise((resolve, reject) => {
    try {
      const filteredData = searchSpace
        .filter(row => row.CalDueDate?.toLowerCase().includes(searchVal?.toLowerCase())
          || row.DeviceType?.toLowerCase().includes(searchVal?.toLowerCase())
          || row.FullEqDesc?.toLowerCase().includes(searchVal?.toLowerCase())
          || row.Notes?.toLowerCase().includes(searchVal?.toLowerCase())
          || row.Status?.toLowerCase().includes(searchVal?.toLowerCase())
          || row.TestEqManufacturer?.toLowerCase().includes(searchVal?.toLowerCase())
          || row.TestEqModel?.toLowerCase().includes(searchVal?.toLowerCase())
          || row.TestEqSn?.toLowerCase().includes(searchVal?.toLowerCase())
        );
      resolve(filteredData);
    } catch (e) {
      reject(e);
    }
  }
  );
}

export function searchDPSFunction(searchVal = '', searchSpace = []) {
  return new Promise((resolve, reject) => {
    try {
      const filteredData = searchSpace
        .filter(row => row.SapFloc?.toLowerCase().includes(searchVal?.toLowerCase())
          || row.Status?.toLowerCase().includes(searchVal?.toLowerCase())
          || row.IcssSoftTag?.toLowerCase().includes(searchVal?.toLowerCase())
          || row.LocationDescription?.toLowerCase().includes(searchVal?.toLowerCase())
          || row.LocationDescription?.toLowerCase().includes(searchVal?.toLowerCase())
          || row.Location2?.toLowerCase().includes(searchVal?.toLowerCase())
          || row.Room?.toLowerCase().includes(searchVal?.toLowerCase())
          || row.DeckHullBldg?.toLowerCase().includes(searchVal?.toLowerCase())
          || row.Quad?.toLowerCase().includes(searchVal?.toLowerCase())
          || row.Level?.toLowerCase().includes(searchVal?.toLowerCase()));
      resolve(filteredData);
    } catch (e) {
      reject(e);
    }
  }
  );
}

export function sortFunction(dataToSort = [], colForSorting = '', sortDscOrder = true) {
  return new Promise((resolve, reject) => {
    try {
      dataToSort.sort((a, b) => {
        if (a[colForSorting] === null) {
          return conditionalValueSelector(sortDscOrder, -1, 1);
        }
        if (b[colForSorting] === null) {
          return conditionalValueSelector(sortDscOrder, 1, -1);
        }
        if (a[colForSorting].toLowerCase() === b[colForSorting].toLowerCase()) {
          return 0;
        }
        if (sortDscOrder) {
          return conditionalValueSelector(a[colForSorting].toLowerCase().localeCompare(b[colForSorting].toLowerCase()) === 1, 1, -1);
        }
        return conditionalValueSelector(a[colForSorting].toLowerCase().localeCompare(b[colForSorting].toLowerCase()) === -1, 1, -1);
      });
      resolve(dataToSort);
    } catch (e) {
      reject(e);
    }
  }
  );
}

export const dpsLockedFieldDetails = (dpsFields) => dpsFields.reduce((prev, curr) => {
  const data = prev;
  data[curr.DPSFieldName] = curr.IsReadOnly;
  return data;
}, {});

// Function to get the description based on TrfId
export const getDescriptionByTrfId = (trfId, trfMasterList) => {
  const trfItem = trfMasterList.filter(x => x.ApplicableRegions && trfId === x.TrfId)[0];
  return trfItem ? trfItem.TrfShortDesc : null;
};
