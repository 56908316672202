import { ValidateField } from 'helpers/Validation';
import { TestResultDropdownOptions, TestAsFoundResultValue } from './TestResult.formulas';

export const TestResultComponentData = (selectedTag, formName, formData) => {
    const borderTopRquired = ((['P7-7', 'P8-5.1', 'M6-8', 'P7-25', 'P7-26', 'P6-11', 'P7-17', 'P7-33',
        'P6-20', 'P7-32', 'P8-14.1', 'P6-5', 'P6-5.2', 'P8-3.1', 'P7-4', 'P7-4.1', 'S7-2', 'S7-3', 'S7-4', 'P6-29'].includes(formName))
        || (formData.F774SensorType === 'Velometer' || formData.F774SensorType === 'Accelerometer')) ? '' : '0';
    const marginPaddingRequired = ['S7-2', 'S7-3', 'S7-4'];
    const topMargin = (marginPaddingRequired.includes(formName) ? '20px' : '4px');
    const labelPadding = (marginPaddingRequired.includes(formName) ? '25px 0 0 0' : '5px 0 0 0');
    return {
        rows: [
            {
                key: 1,
                fields: [{
                    key: 'noData',
                    isLabel: true,
                    xs: 6
                },
                {
                    key: 'TestResultLabel',
                    label: 'Test Result',
                    xs: 2,
                    labelWidth: '96%',
                    padding: labelPadding,
                    fontWeight: '600',
                    fontSize: '9pt',
                    textAlign: 'end',
                    isLabel: 'true',
                },
                {
                    key: 'F85TestResult',
                    value: TestAsFoundResultValue(formData) ? '' : ValidateField(formData.F85TestResult),
                    isRequired: true,
                    isDropDown: true,
                    placeholder: 'Select Test Result',
                    options: TestResultDropdownOptions(formData),
                    xs: 4,
                    labelWidth: '0',
                    width: '99%',
                    borderTop: borderTopRquired,
                    marginTop: topMargin,
                    marginRight: '4px'
                }]
            }]
    };
};

export const selectTestResultComponentData = (selectedTag, formName, formData) => {
    let filterViewData;
    if (['P7-1', 'P7-2', 'P7-3', 'P7-4', 'P7-4.1', 'P6-4', 'P6-1', 'P8-7.1', 'P6-2', 'P8-1.1', 'P8-2.1', 'P8-5.1',
        'P6-1.2', 'P6-2.2', 'P6-4.2', 'P7-5', 'P7-8', 'P7-12', 'P7-10', 'P6-8', 'P6-3',
        'P6-3.2', 'P6-17', 'P7-28', 'P7-24', 'P7-7', 'P6-5', 'P8-3.1', 'P6-5.2', 'P8-6.1', 'P6-9', 'P8-11.1', 'M6-8',
        'P7-25', 'P7-26', 'P6-11', 'P7-17', 'P7-33', 'P6-20', 'P7-32', 'P7-20', 'P7-21', 'P7-11', 'P6-22', 'P7-22',
        'P7-15', 'P7-14', 'P7-18', 'P6-12', 'P7-29', 'P8-8.1', 'P8-12.1', 'P8-14.1', 'P7-30', 'P8-13.1',
        'S7-2', 'S7-3', 'S7-4', 'P6-26', 'P6-29', 'P6-27'].includes(formName)) {
        filterViewData = TestResultComponentData(selectedTag, formName, formData);
    } else {
        filterViewData = { rows: [] };
    }
    return filterViewData;
};
