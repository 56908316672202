import styled from 'styled-components';

const textCentreAlign = ['P6-10', 'P7-6', 'M6-7', 'P7-6', 'M6-9', 'M6-6', 'M6-3',
    'M6-5', 'M6-4', 'M6-10', 'M6-8', 'M6-11', 'P7-23', 'M6-12', 'M3-1', 'M6-13',
    'M6-14', 'M6-15', 'P6-11', 'P6-15', 'P6-18', 'P6-14.2', 'P6-14', 'P7-26', 'P7-25', 'P6-23.2',
    'P7-27', 'P7-31', 'P7-17', 'P7-32', 'P7-33', 'P7-20', 'P6-26', 'P6-20', 'M6-16',
    'M7-1', 'P7-21', 'P8-14.1', 'P7-16', 'P6-13', 'P7-9', 'M6-1', 'P7-7', 'P6-5', 'P6-5.2', 'P8-3.1',
    'P6-7', 'P8-4.1', 'P8-10.1', 'P7-13', 'P6-16', 'P6-22', 'P7-22', 'P7-15', 'P7-14', 'P7-18', 'P6-12',
    'P7-29', 'P8-8.1', 'P8-12.1', 'P8-17.1', 'P7-30', 'P6-6', 'S7-1', 'S7-2', 'S7-3', 'S7-4', 'M4-2', 'P6-29', 'P6-27'];

export const StyleDropdown = styled.div`
.Dropdown-root{ 
    @media only screen and (max-width: 1000px) {
        width: ${props => {
        if (props.formName === 'M6-7') {
            return '49.2%';
        }
        if (props.formName === 'P7-26') {
            return '32.4%';
        }
        return props.width;
    }
    };
    }
@media only screen and (min-width: 1001px) {
    width: ${props => {
        if (props.formName === 'M6-7') {
            return '48.8%';
        }
        if (props.formName === 'P7-26') {
            return '33.1%';
        }
        return props.width;
    }
    };
    }
}

#F733AftValveActionUponTrip-wrapper > .Dropdown-root,
#F734AftValveMovementUponTrip-wrapper > .Dropdown-root,
#F737AltValveActionUponTrip-wrapper > .Dropdown-root,
#F738AltValveMovementUponTrip-wrapper > .Dropdown-root {
    @media only screen and (max-width: 960px) {
        width: ${props => (props.formName === 'P7-27') ? '41.5%' : props.width};
    }
    @media only screen and (min-width: 961px) {
        width: ${props => (props.formName === 'P7-27') ? '40.9%' : props.width};
    }
}

#F494Pstsetting-wrapper .Dropdown-placeholder {
    width: ${props => (props.formName === 'P7-16' || props.formName === 'P6-13' || props.formName === 'S7-1') ? '100%' : '93%'};
    }
#F494Pstsetting-wrapper .Dropdown-menu {
    text-align: ${props => (props.formName === 'P7-16' || props.formName === 'P6-13' || props.formName === 'S7-1') ? 'center' : 'left'};
    }
#F494Pstsetting-wrapper .Dropdown-root > .Dropdown-control {
    width: ${props => (props.formName === 'P7-16' || props.formName === 'P6-13' || props.formName === 'S7-1') ? 'center' : 'left'};
    }
#F743AftValveInitiatedPartialStrokeTest-wrapper > .Dropdown-root{ 
    @media only screen and (max-width: 960px) {
        width: ${props => (props.formName === 'P7-16' || props.formName === 'P6-13') ? '56.7%' : props.width};
    }
    @media only screen and (min-width: 961px) {
        width: ${props => (props.formName === 'P7-16' || props.formName === 'P6-13') ? '56.4%' : props.width};
    }
}
#F746AftValvePartialStrokeTestResult-wrapper > .Dropdown-root{ 
    @media only screen and (max-width: 960px) {
        width: ${props => (props.formName === 'P7-16' || props.formName === 'P6-13') ? '56.7%' : props.width};
    }
    @media only screen and (min-width: 961px) {
        width: ${props => (props.formName === 'P7-16' || props.formName === 'P6-13') ? '56.4%' : props.width};
    }
}
#F751AltValveInitiatedPartialStrokeTest-wrapper > .Dropdown-root{ 
    @media only screen and (max-width: 960px) {
        width: ${props => (props.formName === 'P7-16' || props.formName === 'P6-13') ? '56.7%' : props.width};
    }
    @media only screen and (min-width: 961px) {
        width: ${props => (props.formName === 'P7-16' || props.formName === 'P6-13') ? '56.4%' : props.width};
    }
}
#F754AltValvePartialStrokeTestResult-wrapper > .Dropdown-root{ 
    @media only screen and (max-width: 960px) {
        width: ${props => (props.formName === 'P7-16' || props.formName === 'P6-13') ? '56.7%' : props.width};
    }
    @media only screen and (min-width: 961px) {
        width: ${props => (props.formName === 'P7-16' || props.formName === 'P6-13') ? '56.4%' : props.width};
    }
}
#F755AltSmartInitiatedPartialStrokeTest-wrapper > .Dropdown-root{ 
    @media only screen and (max-width: 960px) {
        width: ${props => (props.formName === 'P7-16' || props.formName === 'P6-13') ? '52.4%' : props.width};
    }
    @media only screen and (min-width: 961px) {
        width: ${props => (props.formName === 'P7-16' || props.formName === 'P6-13') ? '53.6%' : props.width};
    }
}
#F758AltSmartPartialStrokeTestResult-wrapper > .Dropdown-root{ 
    @media only screen and (max-width: 960px) {
        width: ${props => (props.formName === 'P7-16' || props.formName === 'P6-13') ? '52.4%' : props.width};
    }
    @media only screen and (min-width: 961px) {
        width: ${props => (props.formName === 'P7-16' || props.formName === 'P6-13') ? '53.6%' : props.width};
    }
}
#F747AftSmartInitiatedPartialStrokeTest-wrapper > .Dropdown-root{ 
    @media only screen and (max-width: 960px) {
        width: ${props => (props.formName === 'P7-16' || props.formName === 'P6-13') ? '52.4%' : props.width};
    }
    @media only screen and (min-width: 961px) {
        width: ${props => (props.formName === 'P7-16' || props.formName === 'P6-13') ? '53.6%' : props.width};
    }
}
#F750AftSmartPartialStrokeTestResult-wrapper > .Dropdown-root{ 
    @media only screen and (max-width: 960px) {
        width: ${props => (props.formName === 'P7-16' || props.formName === 'P6-13') ? '52.4%' : props.width};
    }
    @media only screen and (min-width: 961px) {
        width: ${props => (props.formName === 'P7-16' || props.formName === 'P6-13') ? '53.6%' : props.width};
    }
}

#F341ValveFullStrokeLocalPosition-wrapper > .Dropdown-root{
    @media only screen and (max-width: 960px) {
        width: ${props => (props.formName === 'P6-10' || props.formName === 'P7-6') ? '41.7%' : props.width};
    }
    @media only screen and (min-width: 961px) {
        width: ${props => (props.formName === 'P6-10' || props.formName === 'P7-6') ? '41.2%' : props.width};
    }
}
#F350ValveFullStrokeLocalPosition2-wrapper > .Dropdown-root{ 
    @media only screen and (max-width: 960px) {
        width: ${props => (props.formName === 'P6-10' || props.formName === 'P7-6') ? '41.7%' : props.width};
    }
    @media only screen and (min-width: 961px) {
        width: ${props => (props.formName === 'P6-10' || props.formName === 'P7-6') ? '41.2%' : props.width};
    }
}
#F345ValveFullStrokeHmipositionIndication-wrapper > .Dropdown-root{ 
    @media only screen and (max-width: 960px) {
        width: ${props => (props.formName === 'P6-10' || props.formName === 'P7-6') ? '33.5%' : props.width};
    }
    @media only screen and (min-width: 961px) {
        width: ${props => (props.formName === 'P6-10' || props.formName === 'P7-6') ? '33.3%' : props.width};
    }
}
#F352ValveFullStrokeHmipositionIndication2-wrapper > .Dropdown-root{ 
@media only screen and (max-width: 960px) {
        width: ${props => (props.formName === 'P6-10' || props.formName === 'P7-6') ? '33.4%' : props.width};
}
@media only screen and (min-width: 961px) {
    width: ${props => (props.formName === 'P6-10' || props.formName === 'P7-6') ? '33.2%' : props.width};
}
}
#F353AftValveFullStrokeMovement-wrapper > .Dropdown-root{
    @media only screen and (max-width: 960px) {
        width: ${props => (props.formName === 'P6-10' || props.formName === 'P7-6') ? '41.7%' : props.width};
    }
    @media only screen and (min-width: 961px) {
        width: ${props => (props.formName === 'P6-10' || props.formName === 'P7-6') ? '41.2%' : props.width};
    }
}
#F354AltValveFullStrokeMovement-wrapper > .Dropdown-root{
    @media only screen and (max-width: 960px) {
        width: ${props => (props.formName === 'P6-10' || props.formName === 'P7-6') ? '41.7%' : props.width};
    }
    @media only screen and (min-width: 961px) {
        width: ${props => (props.formName === 'P6-10' || props.formName === 'P7-6') ? '41.2%' : props.width};
    }
}
.Dropdown-root > .Dropdown-control{ 
    @media only screen and (max-width: 960px) {
        width: ${props => (props.formName === 'M7-1') ? '98%' : props.width};
    }
    @media only screen and (min-width: 961px) {
        width: ${props => (props.formName === 'M7-1') ? '98.5%' : props.width};
    }
}

#F619AsLeftTestRequired-wrapper > .Dropdown-root > .Dropdown-control{ 
    @media only screen and (max-width: 960px) {
        width: ${props => (props.formName === 'M7-1') ? '100%' : props.width};
    }
    @media only screen and (min-width: 961px) {
        width: ${props => (props.formName === 'M7-1') ? '100%' : props.width};
    }
}
#F676TransducerOutputSignal-wrapper > .Dropdown-root{ 
    @media only screen and (max-width: 960px) {
        width: ${props => (props.formName === 'P7-21') ? '48.1%' : props.width};
    }
    @media only screen and (min-width: 961px) {
        width: ${props => (props.formName === 'P7-21') ? '49.1%' : props.width};'
    }
}


#F621AltUnitOfMeasurement-wrapper > .Dropdown-root{ 
    @media only screen and (max-width: 960px) {
        width: ${props => (props.formName === 'P7-6') ? '32%' : props.width};
    }
    @media only screen and (min-width: 961px) {
        width: ${props => (props.formName === 'P7-6') ? '32.8%' : props.width};
    }
}

#F722TransducerOutputSignal-wrapper > .Dropdown-root > .Dropdown-control{ 
    @media only screen and (max-width: 960px) {
        width: ${props => (props.formName === 'P7-21') ? '48%' : props.width};
    }
    @media only screen and (min-width: 961px) {
        width: ${props => (props.formName === 'P7-21') ? '49.1%' : props.width};'
    }
}

#F352AltValveFullStrokeLocalPosition-wrapper > .Dropdown-root{ 
    @media only screen and (max-width: 960px) {
        width: ${props => (props.formName === 'P6-10' || props.formName === 'P7-6') ? '41.7%' : props.width};
    }
    @media only screen and (min-width: 961px) {
        width: ${props => (props.formName === 'P6-10' || props.formName === 'P7-6') ? '41.2%' : props.width};
    }
}

#F779AftSensorOutput2-wrapper .Dropdown-placeholder {
    width: ${props => ['P7-20', 'P6-26'].includes(props.formName) ? '79%' : '100%'};
   }
#F691AftSystemReading1-wrapper .Dropdown-placeholder {
    width: ${props => ['P7-20', 'P6-26'].includes(props.formName) ? '81%' : '100%'};
   }
#F780AftSystemReading-wrapper .Dropdown-placeholder {
    width: ${props => ['P7-20', 'P6-26'].includes(props.formName) ? '79%' : '100%'};
   }
#F784AltSensorOutput2-wrapper .Dropdown-placeholder {
    width: ${props => ['P7-20', 'P6-26'].includes(props.formName) ? '79%' : '100%'};
   }
#F733AltSystemReading1-wrapper .Dropdown-placeholder {
    width: ${props => ['P7-20', 'P6-26'].includes(props.formName) ? '81%' : '100%'};
   }
#F785AltSystemReading1-wrapper .Dropdown-placeholder {
    width: ${props => ['P7-20', 'P6-26'].includes(props.formName) ? '79%' : '100%'};
   }

.Dropdown-menu {
    text-align: ${props => textCentreAlign.includes(props.formName) ? 'center' : 'left'};
}
.Dropdown-placeholder {
    width: ${props => textCentreAlign.includes(props.formName) ? '100%' : '93%'};
    text-align: ${props => textCentreAlign.includes(props.formName) ? 'center' : 'left'};
}
`;
export const CalibrationAsLeftDropdown = styled.div`
#F37AlarmsConfirmed-wrapper .Dropdown-placeholder {
    width: ${props => props.formName === 'M6-1' ? '100%' : '93%'};
   }
   #F37AlarmsConfirmed-wrapper .Dropdown-menu {
    text-align: ${props => (props.formName === 'M6-1') ? 'center' : 'left'};
   }

#F37AlarmsConfirmed-wrapper > .Dropdown-root{ 
    @media only screen and (max-width: 960px) {
        width: ${props => (props.formName === 'M6-1') ? '64.5%' : props.width};
    }
    @media only screen and (min-width: 961px) {
        width: ${props => (props.formName === 'M6-1') ? '64%' : props.width};'
    }
}
`;

const height = ['P6-5', 'P7-7', 'P8-3.1'];
export const StyleInput = styled.div`
#F240HighHighSetpoint, #F32HighSetpoint, #F33LowSetpoint, #F241LowLowSetpoint, #F51HmiReading1, #F53HmiReading3, #F54HmiReading4,
#F52HmiReading2, #F750SystemReading1, #F752SystemReading3, #F753SystemReading4, #F751SystemReading2{ 
    @media only screen and (max-width: 1039px) {
        height: ${props => ['P7-20', 'P7-21', 'P6-26'].includes(props.formName) ? '1.8rem' : props.height};
    }
    @media only screen and (min-width: 1040px) {
        height: ${props => ['P7-20', 'P7-21', 'P6-26'].includes(props.formName) ? '1.2rem' : props.height};
    }
}

#F756AltSmartMeasuredPartialTravelAmount{ 
    @media only screen and (max-width: 960px) {
        width: ${props => (props.formName === 'P7-16' || props.formName === 'P6-13') ? '53.4%' : props.width};
    }
    @media only screen and (min-width: 961px) {
         width: ${props => (props.formName === 'P7-16' || props.formName === 'P6-13') ? '55.5%' : props.width};
    }
}
#F757AltSmartTravelTimeToTargetPosition{ 
    @media only screen and (max-width: 960px) {
        width: ${props => (props.formName === 'P7-16' || props.formName === 'P6-13') ? '53.4%' : props.width};
    }
    @media only screen and (min-width: 961px) {
         width: ${props => (props.formName === 'P7-16' || props.formName === 'P6-13') ? '55.5%' : props.width};
    }
}
#F81AsLeftResult{ 
    @media only screen and (max-width: 960px) {
        width: ${props => (props.formName === 'P7-16' || props.formName === 'P6-13') ? '53.4%' : props.width};
    }
    @media only screen and (min-width: 961px) {
         width: ${props => (props.formName === 'P7-16' || props.formName === 'P6-13') ? '55.7%' : props.width};
    }
}
#F748AftSmartMeasuredPartialTravelAmount{ 
    @media only screen and (max-width: 960px) {
        width: ${props => (props.formName === 'P7-16' || props.formName === 'P6-13') ? '53.4%' : props.width};
    }
    @media only screen and (min-width: 961px) {
         width: ${props => (props.formName === 'P7-16' || props.formName === 'P6-13') ? '55.5%' : props.width};
    }
}
#F749AftSmartTravelTimeToTargetPosition{ 
    @media only screen and (max-width: 960px) {
        width: ${props => (props.formName === 'P7-16' || props.formName === 'P6-13') ? '53.4%' : props.width};
    }
    @media only screen and (min-width: 961px) {
         width: ${props => (props.formName === 'P7-16' || props.formName === 'P6-13') ? '55.5%' : props.width};
    }
}
#F61AsFoundResult{ 
    @media only screen and (max-width: 960px) {
        width: ${props => (props.formName === 'P7-16' || props.formName === 'P6-13') ? '53.4%' : props.width};
    }
    @media only screen and (min-width: 961px) {
         width: ${props => (props.formName === 'P7-16' || props.formName === 'P6-13') ? '55.7%' : props.width};
    }
}
#F747AftSmartInitiatedPartialStrokeTest{ 
    @media only screen and (max-width: 960px) {
        width: ${props => (props.formName === 'P7-16' || props.formName === 'P6-13') ? '51.1%' : props.width};
    }
    @media only screen and (min-width: 961px) {
        width: ${props => (props.formName === 'P7-16' || props.formName === 'P6-13') ? '52.7%' : props.width};
    }
}
#F750AftSmartPartialStrokeTestResult{ 
    @media only screen and (max-width: 960px) {
        width: ${props => (props.formName === 'P7-16' || props.formName === 'P6-13') ? '51.1%' : props.width};
    }
    @media only screen and (min-width: 961px) {
        width: ${props => (props.formName === 'P7-16' || props.formName === 'P6-13') ? '52.7%' : props.width};
    }
}
#F755AltSmartInitiatedPartialStrokeTest{ 
    @media only screen and (max-width: 960px) {
        width: ${props => (props.formName === 'P7-16' || props.formName === 'P6-13') ? '51.1%' : props.width};
    }
    @media only screen and (min-width: 961px) {
        width: ${props => (props.formName === 'P7-16' || props.formName === 'P6-13') ? '52.5%' : props.width};
    }
}
#F758AltSmartPartialStrokeTestResult{ 
    @media only screen and (max-width: 960px) {
        width: ${props => (props.formName === 'P7-16' || props.formName === 'P6-13') ? '51.1%' : props.width};
    }
    @media only screen and (min-width: 961px) {
        width: ${props => (props.formName === 'P7-16' || props.formName === 'P6-13') ? '52.5%' : props.width};
    }
}
#F242IndependentLevelReading1, #F244IndependentLevelReading3, #F245IndependentLevelReading4, #F240HighHighSetpoint, #F32HighSetpoint, #F53HmiReading3{ 
    @media only screen and (max-width: 960px) {
        height: ${props => height.includes(props.formName) ? '1.75rem' : props.height};
    }
    @media only screen and (min-width: 961px) {
        height: ${props => height.includes(props.formName) ? '1.25rem' : props.height};
    }
}
#F243IndependentLevelReading2, #F241LowLowSetpoint, #F52HmiReading2{
    @media only screen and (max-width: 960px) {
        height: ${props => height.includes(props.formName) ? '1.88rem' : props.height};
    }
    @media only screen and (min-width: 961px) {
        height: ${props => height.includes(props.formName) ? '1.25rem' : props.height};
    }
}
#F33LowSetpoint, #F51HmiReading1, #F54HmiReading4{
    @media only screen and (max-width: 960px) {
        height: ${props => height.includes(props.formName) ? '1.8rem' : props.height};
    }
    @media only screen and (min-width: 961px) {
        height: ${props => height.includes(props.formName) ? '1.25rem' : props.height};
    }
}
`;

export const DeleteButton = styled.div`
text-align: center;
`;

export const StyleLabelText = styled.div`
#AltHmiStatus,
#AltTransitTime,
#AftHmiStatus,
#AftTransitTime{
    @media only screen and (max-width: 1150px) {
        margin-top: ${props => (['M6-10'].includes(props.formName)) ? '3rem' : 0};
    }
    @media only screen and (min-width: 1150px) {
        margin-top: ${props => (['M6-10'].includes(props.formName)) ? '2rem' : 0};
    }
}
`;
