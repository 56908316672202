export const formsInitialState = {
    formData: {},
    updatedFormDataObj: {},
    totalCount: 0,
    selectedTag: {},
    deleteExistingImageList: [],
    deleteNewImageList: [],
    newImageList: [],
    trfFormImage: [],
    formDataP81: {},
    formDataSRA: {},
    P8TRFImage: {},
    iseditimage: false,
    editImage: {},
    editedImageList: [],
    aftTestPoint: 0,
    altTestPoint: 0,
    flowAlarmTestPoint: 0,
    spinnerVisible: true,
    multiTRFDetails: []
};

export const formDataCalculatedFieldsName = [
    'F27InstrumentCalibratedRange',
    'F105HmicalculatedRange',
    'F27InstrumentCalibratedRange',
    'F203AftHmiInst1',
    'F204AftHmiInst2',
    'F205AftHmiInst3',
    'F225AltHmiInst1',
    'F226AltHmiInst2',
    'F227AltHmiInst3',
    'F675NumberOfTestPoints',
    'F721NumberOfTestPoints',
    'F816NumberOfTestPoints'
];

export const formDataInitialState = {
    F2SapFloc: null,
    F3Manufacture: null,
    F4CompletedBy: null,
    F4ModifiedBy: null,
    F5DateTested: null,
    F6Model: null,
    F7FullEqDesc1: null,
    F9FullEqDesc3: null,
    F8FullEqDesc2: null,
    F14HmiAsLeftVp: null,
    F7LocalAsFoundVp: null,
    F8HmiAsFoundVp: null,
    F11ValveFullStrokePi: null,
    F12ValveFullStrokeTt: null,
    F13LocalAsLeftVp: null,
    F9VisuallyInspectInstrumentTaggingNote: null,
    F10ValveTravelTimeRequiremen: null,
    F15Note: null,
    F17Mi: null,
    F18MiDescription: null,
    F19Mp: null,
    F20Mo: null,
    F21ScheduleFinishDate: null,
    F22TrfId: null,
    F23RegionDocId: null,
    F24ApplicableRegions: null,
    F25RegionalSpa: null,
    F26Status: null,
    F16EqType1: null,
    F17EqType2: null,
    F18EqType3: null,
    F183ReadingPt: null,
    F19HMIReadingPt: null,
    F20ProcessGaugeReadingPt: null,
    F20Span: null,
    F20ProcessTransmitterReading: null,
    F21HmiLrv: null,
    F22HmiUrv: null,
    F23HmiCalculatdRange: null,
    F24HmiEngineeringUnit: null,
    F25InstrumentLrv: null,
    F26InstrumentUrv: null,
    F27InstrumentCalibratedRange: null,
    F27InstrumentCalculatedRange: null,
    F28InstrumentEngineeringUnit: null,
    F29VisuallyInspectInstrumentTaggingNote: null,
    F30FloatPolarityStrengthCheck: null,
    F30ReCalibrationTolerance: null,
    F31FormulaCalculatedFailPass: null,
    F31FailTolerance: null,
    F32RetrieveDiagnosticMsg: null,
    F33FailureModeHc: null,
    F34ActualOutput1: null,
    F34CalibrationGasAsFound: null,
    F35FormulaCalculatedPf1: null,
    F35CalibrationGasAsLeft: null,
    F36FailureModeLc: null,
    F36ReCalRequired: null,
    F37ActualOutput2: null,
    F37AlarmsConfirmed: null,
    F38FormulaCalculatedPf2: null,
    F38ReadingAfterTest: null,
    F39FormulaInst1: null,
    F39HmiInst1: null,
    F39Htp11: null,
    F39CorrectiveNotification: null,
    F40FormulaInst2: null,
    F40HmiInst2: null,
    F41FormulaInst3: null,
    F41HmiInst3: null,
    F42GaugeReading1: null,
    F42RtdSR1: null,
    F43RtdSR2: null,
    F42TestModuleReading1: null,
    F24RetrieveDiagnosticMessage: null,
    F42RtdSimulatorReading1: null,
    F43GaugeReading2: null,
    F43TestModuleReading2: null,
    F43RtdSimulatorReading2: null,
    F44GaugeReading3: null,
    F44TestModuleReading3: null,
    F44RtdSimulatorReading3: null,
    F45TxReading1: null,
    F46TxReading2: null,
    F47TxReading3: null,
    F48HmiReading1: null,
    F49HmiReading2: null,
    F50HmiReading3: null,
    F51DateLastModified: null,
    F51MAReading1: null,
    F52MAReading2: null,
    F53MAReading3: null,
    F54DeviationEu1: null,
    F55DeviationEu2: null,
    F56DeviationEu3: null,
    F57ReturnSignal: null,
    F58ThresholdAsFound: null,
    F59ThresholdAsLeft: null,
    F60ToleranceEu: null,
    F61AsFoundResult: null,
    F61DeviceType: null,
    F62SensorTrimNeed: null,
    F62Deck: null,
    F63Hmi2Inst1: null,
    F63Formula2Inst1: null,
    F63Quad: null,
    F64Hmi2Inst2: null,
    F64Formula2Inst2: null,
    F64Level: null,
    F65Hmi2Inst3: null,
    F65Formula2Inst3: null,
    F65Room: null,
    F66Gauge2Reading1: null,
    F66TestModule2Reading1: null,
    F66Test2ModuleReading1: null,
    F66Rtd2SimulatorReading1: null,
    F66Loaction2: null,
    F67Gauge2Reading2: null,
    F67TestModule2Reading2: null,
    F67Test2ModuleReading2: null,
    F67Rtd2SimulatorReading2: null,
    F68Gauge2Reading3: null,
    F68TestModule2Reading3: null,
    F68Test2ModuleReading3: null,
    F68Rtd2SimulatorReading3: null,
    F69Tx2Reading1: null,
    F70Tx2Reading2: null,
    F71Tx2Reading3: null,
    F72Hmi2Reading1: null,
    F73Hmi2Reading2: null,
    F74Hmi2Reading3: null,
    F75MA2Reading1: null,
    F76MA2Reading2: null,
    F77MA2Reading3: null,
    F78Deviation2Eu1: null,
    F79Deviation2Eu2: null,
    F80Deviation2Eu3: null,
    F81AsLeftResult: null,
    F813TestModuleReading1: null,
    F814TestModuleReading2: null,
    F815HmiReading1: null,
    F816HmiReading2: null,
    F82HmiReadingAt: null,
    F83ProcessGaugeReadingAt: null,
    F83ProcessTxReadingAt: null,
    F84Note: null,
    F85TestResult: null,
    F86Mi: null,
    F87MiDescription: null,
    F88Mp: null,
    F89Mo: null,
    F90ScheduleFinishDate: null,
    F91TrfId: null,
    F91fullEqDesc4: null,
    F92FullEqDesc5: null,
    F92RegionDocId: null,
    F93ApplicableRegions: null,
    F94RegionalSpa: null,
    F95Status: null,
    F181EqType4: null,
    F182EqType5: null,
    RowId: null,
    F100HmireadingPriorToTest: null,
    F144ProcessTxreadingPriorToTest: null,
    F105HmicalculatedRange: null,
    F115HmiengineeringUnits: null,
    F116InstrumentCalibratedLrv: null,
    F117InstrumentCalibratedUrv: null,
    F118InstrumentEngineeringUnits: null,
    F119VisualInspection: null,
    F247MfcHighestPositiveGaussReading: null,
    F248MfcFloatOrientedCorrectly: null,
    F249MfcHighestNegativeGaussReading: null,
    F250MfcRecordedStrengthLessThan48Gauss: null,
    F120Diagnostics: null,
    F315TxshowBadPvorFaultOnHmiLc: null,
    F316TxshowBadPvorFaultOnHmiHc: null,
    F100HmireadingPriortoTest: null,
    F268PhysicalPositionIndicationPriortoTest: null,
    F304IsDampingValueTheSameAsfactorydefaultvalue: null,
    F121AftCalibrationPoint1: null,
    F122AftCalibrationPoint2: null,
    F123AftCalibrationPoint3: null,
    F124AftGaugeReading1: null,
    F125AftGaugeReading2: null,
    F126AftGaugeReading3: null,
    F147AftTransmitterReading1: null,
    F148AftTransmitterReading2: null,
    F149AftTransmitterReading3: null,
    F106AftHmireading1: null,
    F107AftHmireading2: null,
    F108AftHmireading3: null,
    F253AftMeasuredReading1: null,
    F254AftMeasuredReading2: null,
    F255AftMeasuredReading3: null,
    F256AftAsFoundThresholdValue100: null,
    F257AftAsLeftThresholdValue100: null,
    F258AftAsFoundThresholdValue0: null,
    F259AftAsLeftThresholdValue0: null,
    F269AftTransmitterTxreading1: null,
    F270AftTransmitterTxreading2: null,
    F271AftTransmitterTxreading3: null,
    F187AftMeasuredmAreading1: null,
    F188AftMeasuredmAreading2: null,
    F189AftMeasuredmAreading3: null,
    F127AftCdCalibrationPoint1: null,
    F128AftCdCalibrationPoint2: null,
    F129AftCdCalibrationPoint3: null,
    F150AftCdTxandGauge1: null,
    F151AftCdTxandGauge2: null,
    F152AftCdTxandGauge3: null,
    F187AftMeasuredmAReading1: null,
    F188AftMeasuredmAReading2: null,
    F189AftMeasuredmAReading3: null,
    F305AftCdTxandGuage1: null,
    F306AftCdTxandGuage2: null,
    F307AftCdTxandGuage3: null,
    F130AftCdTxandHmi1: null,
    F131AftCdTxandHmi2: null,
    F153AftCdTxandHmi3: null,
    F132AftCdHmiandGauge1: null,
    F133AftCdHmiandGauge2: null,
    F134AftCdHmiandGauge3: null,
    F260AftTpfrTxandGauge1: null,
    F261AftTpfrTxandGauge2: null,
    F262AftTpfrTxandGauge3: null,
    F308AftTpfrTxandGuage1: null,
    F309AftTpfrTxandGuage2: null,
    F310AftTpfrTxandGuage3: null,
    F154AftTpfrTxandHmi1: null,
    F155AftTpfrTxandHmi2: null,
    F156AftTpfrTxandHmi3: null,
    F157AftTpfrHmiandGauge1: null,
    F158AftTpfrHmiandGauge2: null,
    F159AftTpfrHmiandGauge3: null,
    F160AftSensorTrimNeeded: null,
    F135AftFailureCount: null,
    F101AftTolerance: null,
    F272AftTrTxandGauge1: null,
    F273AftTrTxandGauge2: null,
    F274AftTrTxandGauge3: null,
    F275AftTrTxandHmi1: null,
    F276AftTrTxandHmi2: null,
    F277AftTrTxandHmi3: null,
    F278AftTrHmiandGauge1: null,
    F279AftTrHmiandGauge2: null,
    F280AftTrHmiandGauge3: null,
    F281AftCalibrationNeeded: null,
    F102AftResult: null,
    F136AltCalibrationPoint1: null,
    F137AltCalibrationPoint2: null,
    F138AltCalibrationPoint3: null,
    F139AltGaugeReading1: null,
    F140AltGaugeReading2: null,
    F141AltGaugeReading3: null,
    F161AltTransmitterReading1: null,
    F162AltTransmitterReading2: null,
    F163AltTransmitterReading3: null,
    F109AltHmireading1: null,
    F113AltHmireading2: null,
    F114AltHmireading3: null,
    F164AltMeasuredReading1: null,
    F165AltMeasuredReading2: null,
    F166AltMeasuredReading3: null,
    F167AltTpfrCalibrationPoint1: null,
    F168AltTpfrCalibrationPoint2: null,
    F169AltTpfrCalibrationPoint3: null,
    F263AltTpfrTransmitterandGauge1: null,
    F264AltTpfrTransmitterandGauge2: null,
    F265AltTpfrTransmitterandGauge3: null,
    F311AltTpfrTransmitterandGuage1: null,
    F312AltTpfrTransmitterandGuage2: null,
    F313AltTpfrTransmitterandGuage3: null,
    F170AltTpfrTransmitterandHmi1: null,
    F171AltTpfrTransmitterandHmi2: null,
    F172AltTpfrTransmitterandHmi3: null,
    F173AltTpfrHmiandGauge1: null,
    F174AltTpfrHmiandGauge2: null,
    F175AltTpfrHmiandGauge3: null,
    F142AltFailureCount: null,
    F103AltResult: null,
    F177TransmitterReading1: null,
    F178TransmitterReading2: null,
    F143HmireadingAfterTest: null,
    F267ProcessTxreadingAfterTest: null,
    F179VisuallyInspection: null,
    F180AftCalibratedTripSetPoint: null,
    F181AftTestEquipmentIndication: null,
    F182AftHmireading: null,
    F183AftDeviation: null,
    F184AltTestEquipmentIndication: null,
    F185AltHmireading: null,
    F186AltDeviation: null,
    F110HmireadingAftertheTest: null,
    F282AltTransmitterTxreading1: null,
    F283AltTransmitterTxreading2: null,
    F284AltTransmitterTxreading3: null,
    F285AltMeasuredmAreading1: null,
    F286AltMeasuredmAreading2: null,
    F287AltMeasuredmAreading3: null,
    F288AltTrCalibrationPoint1: null,
    F289AltTrCalibrationPoint2: null,
    F290AltTrCalibrationPoint3: null,
    F291AltTrTransmitterandGauge1: null,
    F292AltTrTransmitterandGauge2: null,
    F293AltTrTransmitterandGauge3: null,
    F294AltTrTransmitterandHmi1: null,
    F295AltTrTransmitterandHmi2: null,
    F296AltTrTransmitterandHmi3: null,
    F297AltTrHmiandGauge1: null,
    F298AltTrHmiandGauge2: null,
    F299AltTrHmiandGauge3: null,
    F300SwitchSetpoint: null,
    F301TransmitterReading: null,
    F302Hmireading: null,
    F190ProcessTxreadingAftertheTest: null,
    F240HighSetpoint: null,
    F241LowSetpoint: null,
    F317CalibrationResult: null,
    F320AftRtdSimulatorReading1: null,
    F321AftRtdSimulatorReading2: null,
    F322AftRtdSimulatorReading3: null,
    F323AltRtdSimulatorReading1: null,
    F324AltRtdSimulatorReading2: null,
    F325AltRtdSimulatorReading3: null,
    F326istheSraontheIcssalarmAndEventLogChronicle: null,
    F327DoesTheIcssAlarmTagOrDescriptioncontainTheTextSra: null,
    F328IsTheIcssAlarmPriorityAsPerAlarmResponseManual: null,
    F329IsTheSraClearlyIdentifiedonTheHmi: null,
    F330DoesTheOperatorHaveKnowledgeOfTheResponseRequired: null,
    F331IsTheArmReadilyAvialableToTheOperator: null,
    F332IsTheResponseInTheArmAdequate: null,
    F333IsTimeToRespondLessThan10minutes: null,
    F334SraPassFail: null,
    F335StandAlone: null,
    F320ManufacturerValve: null,
    F321ManufacturerActuator: null,
    F322ManufacturerSolenoid: null,
    F323ManufacturerIppositioner: null,
    F324ManufacturerPositionTransmitter: null,
    F325ManufacturerLimitSwitches: null,
    F329ModelValve: null,
    F330ModelActuator: null,
    F331ModelSolenoid: null,
    F332ModelIppositioner: null,
    F333ModelPositionTransmitter: null,
    F334ModelLimitSwitches: null,
    F338PhysicalValvePositionPriortotest: null,
    F339HmivalvePositionPriortoTest: null,
    F340ValveSize: null,
    F341ValveFullStrokeLocalPosition: null,
    F342ValveFullStrokeTravelTime: null,
    F343PhysicalValvePositionAfterTest: null,
    F344ValveTravelTimeRequirement: null,
    F345ValveFullStrokeHmipositionIndication: null,
    F346StrokeTestResult: null,
    F347HmivalvePositionAfterTest: null,
    F348FailedComponents: null,
    F349FailedComponents1: null,
    F191IndependentLevelMeasurement: null,
    F192IndependentLevelReadingPriorToTest: null,
    F193VisuallyInspect: null,
    F194DiagnosticMessages: null,
    F197AftWaterDensityUsed: null,
    F198AftOilDensityUsed: null,
    F199AftGasDensityUsed: null,
    F200AftBackgroundCount: null,
    F201AftVesselEmptyCount: null,
    F202AftVesselFullCount: null,
    F203AftHmiInst1: null,
    F204AftHmiInst2: null,
    F205AftHmiInst3: null,
    F206AftIndependentReading1: null,
    F207AftIndependentReading2: null,
    F208AftIndependentReading3: null,
    F209AftTxreading1: null,
    F210AftTxreading2: null,
    F211AftTxreading3: null,
    F212AftMAreading1: null,
    F213AftMAreading2: null,
    F214AftMAreading3: null,
    F215AftDeviation1: null,
    F216AftDeviation2: null,
    F217AftDeviation3: null,
    F218AftIsRecalibrationNeeded: null,
    F219AltWaterDensityUsed: null,
    F220AltOilDensityUsed: null,
    F221AltGasDensityUsed: null,
    F222AltBackgroundCount: null,
    F223AltVesselEmptyCount: null,
    F224AltVesselFullCount: null,
    F225AltHmiInst1: null,
    F226AltHmiInst2: null,
    F227AltHmiInst3: null,
    F228AltIndependentReading1: null,
    F229AltIndependentReading2: null,
    F230AltIndependentReading3: null,
    F231AltTxreading1: null,
    F232AltTxreading2: null,
    F233AltTxreading3: null,
    F234AltMAreading1: null,
    F235AltMAreading2: null,
    F236AltMAreading3: null,
    F237AltDeviation1: null,
    F238AltDeviation2: null,
    F239AltDeviation3: null,
    F242IndependentLevelReading1: null,
    F243IndependentLevelReading2: null,
    F244IndependentLevelReading3: null,
    F245IndependentLevelReading4: null,
    F244RadioactiveSourceShutterClosureTest: null,
    F245SteelPlateInsertionTest: null,
    F246IndependentLevelReadingAfterTest: null,
    F195ActualFailHigh: null,
    F196ActualFailLow: null,
    F144ProcessTxreadingPriortoTest: null,
    F119Visualinspection: null,
    F335ResponseTime: null,
    F336AftCurveSpikeResponseTime: null,
    F337AltCurveSpikeResponseTime: null,
    F338SandLimitAlarm: null,
    F350ValveFullStrokeLocalPosition2: null,
    F351ValveFullStrokeTravelTime2: null,
    F352ValveFullStrokeHmipositionIndication2: null,
    F354AnalyzerReadingPriorToTest: null,
    F356AftAnalyzerReading: null,
    F357AnalyzerReading: null,
    F358AnalyzerReadingAt: null,
    F353PhysicalPositionIndicationPriortoTest1: null,
    F359ManufacturerDamper: null,
    F360ModelDamper: null,
    F361PhysicalDamperPositionPriortoTest: null,
    F362HmidamperPositionPriortoTest: null,
    F363DamperTravelTimeRequirement: null,
    F364DamperFullStrokePositionIndicationAft: null,
    F365DamperFullStrokeTravelTimeAft: null,
    F366DamperFullStrokePositionIndicationAlt: null,
    F367DamperFullStrokeTravelTimeAlt: null,
    F368PhysicalDamperPositionAfterTest: null,
    F369HmidamperAfterTest: null,
    F370HmistatusPriorToTest: null,
    F371DetectorLocalStatusPriorToTest: null,
    F372AftHmistatus1: null,
    F373AftHmistatus2: null,
    F374AftAppliedLowAlarmTestFilter1: null,
    F375AftAppliedHighAlarmTestFilter2: null,
    F376CleanDetectorLensesIfVisiblyDirty: null,
    F377AlignmentRequired: null,
    F378AltHmistatus1: null,
    F379AltHmistatus2: null,
    F380AltAppliedLowAlarmTestFilter1: null,
    F381AltAppliedHighAlarmTestFilter2: null,
    F382HmistatusAfterTest: null,
    F383IsThereHazardousAreaRestriction: null,
    F384AftConfirmThatExternalHeatAppliedField: null,
    F385AftConfirmThatExternalHeatAppliedWorkshop: null,
    F386AftMeasureDetectorMaVOutputWithMultimeter: null,
    F387AftRecordConfirmedFireMaVOutputLevel: null,
    F388AltConfirmThatExternalHeatAppliedField: null,
    F389AltConfirmThatExternalHeatAppliedWorkshop: null,
    F390AltMeasureDetectorMaVOutputWithMultimeter: null,
    F391AltRecordConfirmedFireMaVOutputLevel: null,
    F392ConfirmThatExternalHeatHasBeenRemoved: null,
    F393Hmistatus: null,
    F394DetectorMaVOutputReturnedBackToNormal: null,
    F395ConfirmThatDetectorHasBeenReinstatedBackToField: null,
    F396AftResult2: null,
    F397AltResult2: null,
    F398AftSelectSamplingHole: null,
    F399AltSelectSamplingHole: null,
    F400AftRecordTransitTime: null,
    F401AltRecordTransitTime: null,
    F402AftJumperWireAppliedAndShorted: null,
    F403AltJumperWireAppliedandShorted: null,
    F404JumperWireRemovedandShortCircuitEliminated: null,
    F424AftAppliedTestGasConcentration: null,
    F425ReCalibrationRequired: null,
    F426AltAppliedTestGasConcentration: null,
    F410DetectorMounting: null,
    F411AftConfirmTestTorch: null,
    F412AftConfirmCctvvideoOnHmi: null,
    F413AltConfirmTestTorch: null,
    F414AltConfirmCctvvideoOnHmi: null,
    F407AftTestMethodApplied: null,
    F408AltTestMethodApplied: null,
    F409LocalIndication: null,
    F427AftSmokeDetectorHousing: null,
    F428AltSmokeDetectorHousing: null,
    F429DetectorCleanedFromDust: null,
    F415AlarmTimeDelay: null,
    F416AftSelectTestGain: null,
    F417AftSoundPressureLevel1: null,
    F418AftSoundPressureLevel2: null,
    F419AftTimeDelay: null,
    F420AltSelectTestGain: null,
    F421AltSoundPressureLevel1: null,
    F422AltSoundPressureLevel2: null,
    F423AltTimeDelay: null,
    F405AftMacWasActivatedInField: null,
    F406AltMacWasActivatedInField: null,
    F430TestMethodTypeUsed: null,
    F431AftZeroreadingvalue: null,
    F432AltZeroreadingvalue: null,
    F301ReCalibrationToleranceEU: null,
    F311FailToleranceEU: null,
    F433DetectorvaluewithTestGasAppliedAFT: null,
    F434DetectorvaluewithTestGasAppliedALT: null,
    F435DcsControlTag: null,
    F436SetPointHighHighComment: null,
    F3ManufacturerContactor: null,
    F6ModelContactor: null,
    F437PhysicalPositionofContactirPt: null,
    F438HmipositionofConactorPt: null,
    F439ContactorFailState: null,
    F440AftForcedOutputsignal: null,
    F441AftConfirmContactorOperates: null,
    F442AftConfirmcorrectHmiindication: null,
    F443AltForcedOutputsignal: null,
    F444AltConfirmContactorOperates: null,
    F445AltConfirmcorrectHmiindication: null,
    F446PhysicalPositionofContactirAt: null,
    F447HmipositionofConactorPt: null,
    F448HighSetPointComment: null,
    F449LowSetPointComment: null,
    F458SetPointComment: null,
    F450FieldInstrumentReading: null,
    F451AftCalibratorFieldUnits1: null,
    F452AftCalibratorFieldUnits2: null,
    F453AftCalibratorFieldUnits3: null,
    F454AltCalibratorFieldUnits1: null,
    F455AltCalibratorFieldUnits2: null,
    F456AltCalibratorFieldUnits3: null,
    F457FieldInstrumentReadingAfterTest: null,
    F462AftZeroFlow: null,
    F463AftMAreading: null,
    F464AltZeroFlow: null,
    F465AltMAreading: null,
    F466FlowTxReading1: null,
    F467FlowTxReading2: null,
    F468Txreading: null,
    F469IndependentFlowTxReading: null,
    F470IndependentFlowReading1: null,
    F471IndependentFlowReading2: null,
    F492ManufacturerPositioner: null,
    F493ModelPositioner: null,
    F494Pstsetting: null,
    F495ValvePstpositionIndication: null,
    F496HmivalvePstindication: null,
    F497ValveTravelTime: null,
    F498ValvePstpositionIndication2: null,
    F499HmivalvePstindication2: null,
    F500ValveTravelTime2: null,
    F460AftPushbuttonWasActivatedInField: null,
    F461AltPushbuttonWasActivatedInField: null,
    F490IndependentFlowReading1: null,
    F491IndependentFlowReading2: null,
    F515IsSolenoidTestRequired: null,
    F516AftValveFailSafePosition: null,
    F517AftValveFullStrokeTestResult: null,
    F518AftHMIPositionCommand: null,
    F519AftHMIPositionCommand2: null,
    F520AftHMIPositionCommand3: null,
    F521AftHMIPositionCommand4: null,
    F522AftHMIPositionCommand5: null,
    F523AftHMIPositionCommand6: null,
    F524AftHMIPositionCommand7: null,
    F525AftHMIPositionCommand8: null,
    F526AftHMIPositionCommand9: null,
    F527AftPhysicalPosition: null,
    F528AftPhysicalPosition2: null,
    F529AftPhysicalPosition3: null,
    F530AftPhysicalPosition4: null,
    F531AftPhysicalPosition5: null,
    F532AftPhysicalPosition6: null,
    F533AftPhysicalPosition7: null,
    F534AftPhysicalPosition8: null,
    F535AftPhysicalPosition9: null,
    F536AftDeviation4: null,
    F537AftDeviation5: null,
    F538AftDeviation6: null,
    F539AftDeviation7: null,
    F540AftDeviation8: null,
    F541AltValveFailSafePosition: null,
    F542AltValveFullStrokeTestResult: null,
    F543AltHMIPositionCommand: null,
    F544AltHMIPositionCommand2: null,
    F545AltHMIPositionCommand3: null,
    F546AltHMIPositionCommand4: null,
    F547AltHMIPositionCommand5: null,
    F548AltHMIPositionCommand6: null,
    F549AltHMIPositionCommand7: null,
    F550AltHMIPositionCommand8: null,
    F551AltHMIPositionCommand9: null,
    F552AltPhysicalPosition: null,
    F553AltPhysicalPosition2: null,
    F554AltPhysicalPosition3: null,
    F555AltPhysicalPosition4: null,
    F556AltPhysicalPosition5: null,
    F557AltPhysicalPosition6: null,
    F558AltPhysicalPosition7: null,
    F559AltPhysicalPosition8: null,
    F560AltPhysicalPosition9: null,
    F561AltDeviation4: null,
    F562AltDeviation5: null,
    F563AltDeviation6: null,
    F564AltDeviation7: null,
    F565AltDeviation8: null,
    F492TransmitterToMeasureInterfaceLevel: null,
    F493MeasuredLiquid1: null,
    F494MeasuredLiquid2: null,
    F495ConfiguredDielectricConstant: null,
    F496DielectricConstantLiquid1: null,
    F497DielectricConstantLiquid2: null,
    F502AltReferenceCalibrationGas: null,
    F501AftReferenceCalibrationGas: null,
    F503SerialNumber: null,
    F504DescriptionofService: null,
    F342AftValveFullStrokeTravelTime: null,
    F341AftValveFullStrokeLocalPosition: null,
    F345AftValveFullStrokeHmipositionIndication: null,
    F352AltValveFullStrokeLocalPosition: null,
    F352AltValveFullStrokeHmipositionIndication: null,
    F351AltValveFullStrokeTravelTime: null,
    F566LocalValvePositionPriortoTest: null,
    F567LocalFullStrokePosition: null,
    F568HmifullStrokePosition: null,
    F569LocalFullStrokePositionAlt: null,
    F570HmifullStrokePositionAlt: null,
    F571LocalValvePositionAfterTest: null,
    F572SolenoidValvePositionPriorToTest: null,
    F573AftSolenoidValveActionUponTrip: null,
    F574AftSolenoidValvePositionAfterTrip: null,
    F575AltSolenoidValveActionUponTrip: null,
    F576AltSolenoidValvePositionAfterTrip: null,
    F577SolenoidValvePositionAfterTest: null,
    F578AftConfirmLampsSwitchCorrectly: null,
    F579AltConfirmLampsSwitchCorrectly: null,
    F572ProcessTemperatureReadingPriorToTest: null,
    F27TemperatureMeasurementRange: null,
    F573TemperatureSensorType: null,
    F574AftTestPoint1: null,
    F575AftTestPoint2: null,
    F576AftTestPoint3: null,
    F577AftBathBlockTemperature1: null,
    F578AftBathBlockTemperature2: null,
    F579AftBathBlockTemperature3: null,
    F580AftThermometerReadout1: null,
    F581AftThermometerReadout2: null,
    F582AftThermometerReadout3: null,
    F583Aftreading1: null,
    F584Aftreading2: null,
    F585Aftreading3: null,
    F586AftMVreading1: null,
    F587AftMVreading2: null,
    F588AftMVreading3: null,
    F267ProcessTemperatureReadingAfterTest: null,
    F589RelayCoilStatusPriorToTest: null,
    F590RelayContactPositionPriorToTest: null,
    F591AftForcedTripSignal: null,
    F592AftConfirmRelayOperatesCorrectly: null,
    F593AftConfirmCorrectHmiIndicationOfSignal: null,
    F594AltForcedTripSignal: null,
    F595AltConfirmRelayOperatesCorrectly: null,
    F596AltConfirmCorrectHmiIndicationOfSignal: null,
    F597RelayCoilStatusAfterTest: null,
    F598RelayContactPositionAfterTest: null,
    F599TripExecutionVia: null,
    F600ProcessFluid: null,
    F601MaxTolerableLeakageRate: null,
    F602ValveType: null,
    F603LeakageRateEngUnit: null,
    F604AftDifferentialPressureAcrossValve: null,
    F605AftUnitOfMeasurement: null,
    F606AftValveCenterNo1: null,
    F607AftValveCenterNo2: null,
    F608AftValveCenterNo3: null,
    F609AftValveCenterNo4: null,
    F610AftValveInletNo1: null,
    F611AftValveInletNo2: null,
    F612AftValveInletNo3: null,
    F613AftValveInletNo4: null,
    F614AftValveOutletNo1: null,
    F615AftValveOutletNo2: null,
    F616AftValveOutletNo3: null,
    F617AftValveOutletNo4: null,
    F618AftCalculatedValveLeakageRate: null,
    F619AsLeftTestRequired: null,
    F620AltDifferentialPressureAcrossValve: null,
    F621AltUnitOfMeasurement: null,
    F622AltValveCenterNo1: null,
    F623AltValveCenterNo2: null,
    F624AltValveCenterNo3: null,
    F625AltValveCenterNo4: null,
    F626AltValveInletNo1: null,
    F627AltValveInletNo2: null,
    F628AltValveInletNo3: null,
    F629AltValveInletNo4: null,
    F630AltValveOutletNo1: null,
    F631AltValveOutletNo2: null,
    F632AltValveOutletNo3: null,
    F633AltValveOutletNo4: null,
    F634AltCalculatedValveLeakageRate: null,
    F635AftPbuVolume: null,
    F636AltPbuVolume: null,
    F637AftTestDuration: null,
    F638AltTestDuration: null,
    F639AftInitial1: null,
    F640AftInitial2: null,
    F641AltInitial1: null,
    F642AltInitial2: null,
    F643AftEnd1: null,
    F644AftEnd2: null,
    F645AltEnd1: null,
    F646AltEnd2: null,
    F647AftUnitOfMeasurement1: null,
    F648AltUnitOfMeasurement1: null,
    F649AftTemperatureTripSetpoint: null,
    F650AsFoundTripPoint: null,
    F651AltTemperatureTripSetpoint: null,
    F652AsLeftTripPoint: null,
    F653StatusNotificationAppliancePt: null,
    F654AftActivatedNotificationAppliances: null,
    F655AftConfirmAudibleAlarmsFunctionCorrectly: null,
    F656AftConfirmVisualAlarmsFunctionCorrectly: null,
    F657AltActivatedNotificationAppliances: null,
    F658AltConfirmAudibleAlarmsFunctionCorrectly: null,
    F659AltConfirmVisualAlarmsFunctionCorrectly: null,
    F660StatusNotificationApplianceAt: null,
    F653AftReferenceTestPoint: null,
    F654AltReferenceTestPoint: null,
    F655AftPressureValue: null,
    F656AltPressureValue: null,
    F657AnalyzerReading1: null,
    F658AnalyzerReading2: null,
    F659AnalyzerReading3: null,
    F660InternalSensorFlow: null,
    F661DischargeValvePositionPt: null,
    F662FireSuppressionSystemType: null,
    F663FunctionTestType: null,
    F664AftTst1AutomaticActivationFireSuppression: null,
    F665AftTst2ManualActivationFireSuppression: null,
    F666AftTst1ActivatedFireSuppressionSystem: null,
    F667AftTst2ActivatedFireSuppressionSystem: null,
    F668AftTst1ConfirmDischargeValveOperatesCorrectly: null,
    F669AftTst2ConfirmDischargeValveOperatesCorrectly: null,
    F670AftTst1ConfirmExtinguishantReleasedProtectedArea: null,
    F671AftTst2ConfirmExtinguishantReleasedProtectedArea: null,
    F672AftTst1ConfirmCorrectHmiIndication: null,
    F673AftTst2ConfirmCorrectHmiIndication: null,
    F674AftTst1ConfirmLampsFGMatrixPanel: null,
    F675AftTst2ConfirmLampsFGMatrixPanel: null,
    F676AftTst1Result: null,
    F677AftTst2Result: null,
    F678AftTst1TagNumber: null,
    F679AftTst1Desc: null,
    F680AftTst1Val: null,
    F681AftTst1TagNumber: null,
    F682AftTst1Desc: null,
    F683AftTst1Val: null,
    F684AftTst1TagNumber: null,
    F685AftTst1Desc: null,
    F686AftTst1Val: null,
    F687AftTst2TagNumber: null,
    F688AftTst2Desc: null,
    F689AftTst2Val: null,
    F690AftTst2TagNumber: null,
    F691AftTst2Desc: null,
    F692AftTst2Val: null,
    F693AftTst2TagNumber: null,
    F694AftTst2Desc: null,
    F695AftTst2Val: null,
    F696AltTst1AutomaticActivationFireSuppression: null,
    F697AltTst2ManualActivationFireSuppression: null,
    F698AltTst1ActivatedFireSuppressionSystem: null,
    F699AltTst2ActivatedFireSuppressionSystem: null,
    F700AltTst1ConfirmDischargeValveOperatesCorrectly: null,
    F701AltTst2ConfirmDischargeValveOperatesCorrectly: null,
    F702AltTst1ConfirmExtinguishantReleasedProtectedArea: null,
    F703AltTst2ConfirmExtinguishantReleasedProtectedArea: null,
    F704AltTst1ConfirmCorrectHmiIndication: null,
    F705AltTst2ConfirmCorrectHmiIndication: null,
    F706AltTst1ConfirmLampsFGMatrixPanel: null,
    F707AltTst2ConfirmLampsFGMatrixPanel: null,
    F708AltTst1Result: null,
    F709AltTst2Result: null,
    F710AltTst1TagNumber: null,
    F711AltTst1Desc: null,
    F712AltTst1Val: null,
    F713AltTst1TagNumber: null,
    F714AltTst1Desc: null,
    F715AltTst1Val: null,
    F716AltTst1TagNumber: null,
    F717AltTst1Desc: null,
    F718AltTst1Val: null,
    F719AltTst2TagNumber: null,
    F720AltTst2Desc: null,
    F721AltTst2Val: null,
    F722AltTst2TagNumber: null,
    F723AltTst2Desc: null,
    F724AltTst2Val: null,
    F725AltTst2TagNumber: null,
    F726AltTst2Desc: null,
    F727AltTst2Val: null,
    F728DischargeValvePositionAt: null,
    F661ProximityProbeLength: null,
    F662ExtensionCableLength: null,
    F663HmiReadingPriorToTest: null,
    F664GapVoltagePriorToTest: null,
    F764UnitMm: null,
    F765UnitMil: null,
    F766UnitMmValue: null,
    F767UnitMilValue: null,
    F768UnitMmtoMil: null,
    F769UnitMiltoMm: null,
    F770UnitMmtoMilValue: null,
    F771UnitMiltoMmValue: null,
    F772MmtoMilConverter: null,
    F773MiltoMmConverter: null,
    F675NumberOfTestPoints: 1,
    F676TransducerOutputSignal: null,
    F665AftSimulatedGap1: null,
    AftSimulatedGap1: null,
    AftSimulatedGap2: null,
    AftSimulatedGap3: null,
    AftSimulatedGap4: null,
    AftSimulatedGap5: null,
    AftSimulatedGap6: null,
    AftSimulatedGap7: null,
    AftSimulatedGap8: null,
    AftSimulatedGap9: null,
    AftSimulatedGap10: null,
    AftSimulatedGap11: null,
    F677AftExpectedOutput1: null,
    AftExpectedOutput1: null,
    AftExpectedOutput2: null,
    AftExpectedOutput3: null,
    AftExpectedOutput4: null,
    AftExpectedOutput5: null,
    AftExpectedOutput6: null,
    AftExpectedOutput7: null,
    AftExpectedOutput8: null,
    AftExpectedOutput9: null,
    AftExpectedOutput10: null,
    AftMeasuredReading1: null,
    AftMeasuredReading2: null,
    AftMeasuredReading3: null,
    AftMeasuredReading4: null,
    AftMeasuredReading5: null,
    AftMeasuredReading6: null,
    AftMeasuredReading7: null,
    AftMeasuredReading8: null,
    AftMeasuredReading9: null,
    AftMeasuredReading10: null,
    AftMeasuredReading11: null,
    AftHmiReading1: null,
    AftHmiReading2: null,
    AftHmiReading3: null,
    AftHmiReading4: null,
    AftHmiReading5: null,
    AftHmiReading6: null,
    AftHmiReading7: null,
    AftHmiReading8: null,
    AftHmiReading9: null,
    AftHmiReading10: null,
    AftHmiReading11: null,
    F691AftSystemReading1: null,
    AftSystemReading1: null,
    AftSystemReading2: null,
    AftSystemReading3: null,
    AftSystemReading4: null,
    AftSystemReading5: null,
    AftSystemReading6: null,
    AftSystemReading7: null,
    AftSystemReading8: null,
    AftSystemReading9: null,
    AftSystemReading10: null,
    AftSystemReading11: null,
    AftDeviation1: null,
    AftDeviation2: null,
    AftDeviation3: null,
    AftDeviation4: null,
    AftDeviation5: null,
    AftDeviation6: null,
    AftDeviation7: null,
    AftDeviation8: null,
    AftDeviation9: null,
    AftDeviation10: null,
    AftDeviation11: null,
    F721NumberOfTestPoints: 1,
    F722TransducerOutputSignal: null,
    F701AltSimulatedGap1: null,
    AltSimulatedGap1: null,
    AltSimulatedGap2: null,
    AltSimulatedGap3: null,
    AltSimulatedGap4: null,
    AltSimulatedGap5: null,
    AltSimulatedGap6: null,
    AltSimulatedGap7: null,
    AltSimulatedGap8: null,
    AltSimulatedGap9: null,
    AltSimulatedGap10: null,
    AltSimulatedGap11: null,
    F711AltExpectedOutput1: null,
    AltExpectedOutput1: null,
    AltExpectedOutput2: null,
    AltExpectedOutput3: null,
    AltExpectedOutput4: null,
    AltExpectedOutput5: null,
    AltExpectedOutput6: null,
    AltExpectedOutput7: null,
    AltExpectedOutput8: null,
    AltExpectedOutput9: null,
    AltExpectedOutput10: null,
    AltExpectedOutput11: null,
    AltMeasuredReading1: null,
    AltMeasuredReading2: null,
    AltMeasuredReading3: null,
    AltMeasuredReading4: null,
    AltMeasuredReading5: null,
    AltMeasuredReading6: null,
    AltMeasuredReading7: null,
    AltMeasuredReading8: null,
    AltMeasuredReading9: null,
    AltMeasuredReading10: null,
    AltMeasuredReading11: null,
    AltHmiReading1: null,
    AltHmiReading2: null,
    AltHmiReading3: null,
    AltHmiReading4: null,
    AltHmiReading5: null,
    AltHmiReading6: null,
    AltHmiReading7: null,
    AltHmiReading8: null,
    AltHmiReading9: null,
    AltHmiReading10: null,
    AltHmiReading11: null,
    F733AltSystemReading1: null,
    AltSystemReading1: null,
    AltSystemReading2: null,
    AltSystemReading3: null,
    AltSystemReading4: null,
    AltSystemReading5: null,
    AltSystemReading6: null,
    AltSystemReading7: null,
    AltSystemReading8: null,
    AltSystemReading9: null,
    AltSystemReading10: null,
    AltSystemReading11: null,
    AltDeviation1: null,
    AltDeviation2: null,
    AltDeviation3: null,
    AltDeviation4: null,
    AltDeviation5: null,
    AltDeviation6: null,
    AltDeviation7: null,
    AltDeviation8: null,
    AltDeviation9: null,
    AltDeviation10: null,
    AltDeviation11: null,
    F743SpecifiedSf: null,
    F744Unit: null,
    F745SfTolerance: null,
    F746MinSf: null,
    F747MaxSf: null,
    F748CalculatedSf: null,
    F749SfResult: null,
    F750SystemReading1: null,
    F751SystemReading2: null,
    F752GapVoltageAfterTest: null,
    F774SensorType: null,
    F775ScaleFactorSensitivity: null,
    F774AftOutputOffset: null,
    F775AltOutputOffset: null,
    F776AftVibrationAmplitude: null,
    AftVibrationAmplitude1: null,
    AftVibrationAmplitude2: null,
    AftVibrationAmplitude3: null,
    AftVibrationAmplitude4: null,
    AftVibrationAmplitude5: null,
    AftVibrationAmplitude6: null,
    AftVibrationAmplitude7: null,
    AftVibrationAmplitude8: null,
    AftVibrationAmplitude9: null,
    AftVibrationAmplitude10: null,
    AftVibrationAmplitude11: null,
    F777AftVibrationFrequency: null,
    AftVibrationFrequency1: null,
    AftVibrationFrequency2: null,
    AftVibrationFrequency3: null,
    AftVibrationFrequency4: null,
    AftVibrationFrequency5: null,
    AftVibrationFrequency6: null,
    AftVibrationFrequency7: null,
    AftVibrationFrequency8: null,
    AftVibrationFrequency9: null,
    AftVibrationFrequency10: null,
    AftVibrationFrequency11: null,
    F778AftSensorOutput1: null,
    F779AftSensorOutput2: null,
    AftSensorOutput1: null,
    AftSensorOutput2: null,
    AftSensorOutput3: null,
    AftSensorOutput4: null,
    AftSensorOutput5: null,
    AftSensorOutput6: null,
    AftSensorOutput7: null,
    AftSensorOutput8: null,
    AftSensorOutput9: null,
    AftSensorOutput10: null,
    AftSensorOutput11: null,
    F780AftSystemReading: null,
    F781AltVibrationAmplitude: null,
    AltVibrationAmplitude1: null,
    AltVibrationAmplitude2: null,
    AltVibrationAmplitude3: null,
    AltVibrationAmplitude4: null,
    AltVibrationAmplitude5: null,
    AltVibrationAmplitude6: null,
    AltVibrationAmplitude7: null,
    AltVibrationAmplitude8: null,
    AltVibrationAmplitude9: null,
    AltVibrationAmplitude10: null,
    AltVibrationAmplitude11: null,
    F782AltVibrationFrequency: null,
    AltVibrationFrequency1: null,
    AltVibrationFrequency2: null,
    AltVibrationFrequency3: null,
    AltVibrationFrequency4: null,
    AltVibrationFrequency5: null,
    AltVibrationFrequency6: null,
    AltVibrationFrequency7: null,
    AltVibrationFrequency8: null,
    AltVibrationFrequency9: null,
    AltVibrationFrequency10: null,
    AltVibrationFrequency11: null,
    F783AltSensorOutput1: null,
    F784AltSensorOutput2: null,
    AltSensorOutput1: null,
    AltSensorOutput2: null,
    AltSensorOutput3: null,
    AltSensorOutput4: null,
    AltSensorOutput5: null,
    AltSensorOutput6: null,
    AltSensorOutput7: null,
    AltSensorOutput8: null,
    AltSensorOutput9: null,
    AltSensorOutput10: null,
    AltSensorOutput11: null,
    F785AltSystemReading1: null,
    F450TransmitterReadingPriorToTest: null,
    F457TransmitterReadingAfterTest: null,
    F786TransmitterReadingAfterTest: null,
    F787MeasuredSpecificGravity: null,
    F788ConfiguredSpecificGravity: null,
    F100HmiswitchStatusPriorToTest: null,
    F450PhysicalSwitchStatusPriorToTest: null,
    F777TypeOfPositionSwitch: null,
    F778PurposeOfPositionSwitch: null,
    F779AftMovedTargetPositionActivateSwitch: null,
    F780AftMovedTargetPositionDeactivateSwitch: null,
    F781AftConfirmSwitchActivatesCorrectly: null,
    F782AftConfirmSwitchDeactivatesCorrectly: null,
    F783AltMovedTargetPositionActivateSwitch: null,
    F784AltMovedTargetPositionDeactivateSwitch: null,
    F785AltConfirmSwitchActivatesCorrectly: null,
    F786AltConfirmSwitchDeactivatesCorrectly: null,
    F143HmiswitchStatusAfterTest: null,
    F457PhysicalSwitchReadingAfterTest: null,
    F729Doc1Number: null,
    F730Doc2Number: null,
    F731Doc1Url: null,
    F732Doc2Url: null,
    F743AftValveInitiatedPartialStrokeTest: null,
    F744AftValveMeasuredPartialTravelAmount: null,
    F745AftValveTravelTimeToTargetPosition: null,
    F746AftValvePartialStrokeTestResult: null,
    F747AftSmartInitiatedPartialStrokeTest: null,
    F748AftSmartMeasuredPartialTravelAmount: null,
    F749AftSmartTravelTimeToTargetPosition: null,
    F750AftSmartPartialStrokeTestResult: null,
    F751AltValveInitiatedPartialStrokeTest: null,
    F752AltValveMeasuredPartialTravelAmount: null,
    F753AltValveTravelTimeToTargetPosition: null,
    F754AltValvePartialStrokeTestResult: null,
    F755AltSmartInitiatedPartialStrokeTest: null,
    F756AltSmartMeasuredPartialTravelAmount: null,
    F757AltSmartTravelTimeToTargetPosition: null,
    F758AltSmartPartialStrokeTestResult: null,
    F759AsLeftTestPstMethod: null,
    F353AftValveFullStrokeMovement: null,
    F354AltValveFullStrokeMovement: null,
    F733AftValveActionUponTrip: null,
    F734AftValveMovementUponTrip: null,
    F735AftMeasuredValveTravelTime: null,
    F736AftValveHmiPositionIndication: null,
    F737AltValveActionUponTrip: null,
    F738AltValveMovementUponTrip: null,
    F739AltMeasuredValveTravelTime: null,
    F740AltValveHmiPositionIndication: null,
    F742ToleranceInInstrumentEuComment: null,
    F743AftSwitchOutput: null,
    F744AltSwitchOutput: null,
    F789AftLevelConditions1: null,
    F790AftLevelConditions2: null,
    F791AftLevelConditions3: null,
    F792AltLevelConditions1: null,
    F793AltLevelConditions2: null,
    F794AltLevelConditions3: null,
    F795LeakageClass: null,
    F33LowSetpoint: null,
    F241LowLowSetpoint: null,
    F449LowLowSetpointComment: null,
    F797LowSetpointComment: null,
    F240HighHighSetpoint: null,
    F32HighSetpoint: null,
    F448HighHighSetpointComment: null,
    F796HighSetpointComment: null,
    F51HmiReading1: null,
    F52HmiReading2: null,
    F53HmiReading3: null,
    F54HmiReading4: null,
    F69Gauge2Reading3: null,
    F70Gauge2Reading4: null,
    F815TestModuleReading3: null,
    F816TestModuleReading4: null,
    F752SystemReading3: null,
    F753SystemReading4: null,
    F179TransmitterReading3: null,
    F180TransmitterReading4: null,
    F71Gauge2Reading1: null,
    F72Gauge2Reading2: null,
    F73Gauge2Reading3: null,
    F798SwitchSetpointComment: null,
    F799AutoDiagnosticsTestResult: null,
    F800TestDeviceReading: null,
    F801TestDeviceReading: null,
    F802TestDeviceReading: null,
    F803TestDeviceReading: null,
    F804TestDeviceReading: null,
    F805TestDeviceReading: null,
    F806TestDeviceReading: null,
    F807TestDeviceReading: null,
    F808TestDeviceReading: null,
    F809TestDeviceReading: null,
    F810ZeroFlow: null,
    F811MAreading: null,
    F812Deviation: null,
    F813TransmitterTxreading1: null,
    F814FaultAlarmHmi1: null,
    F815FaultAlarmHmi2: null,
    F816NumberOfTestPoints: 1,
    AftSmokeConfirmation2: null,
    AftSmokeConfirmation3: null,
    AftSmokeConfirmation4: null,
    AftSmokeConfirmation5: null,
    AftSmokeConfirmation6: null,
    AftSmokeConfirmation7: null,
    AftSmokeConfirmation8: null,
    AftSmokeConfirmation9: null,
    AftHmiStatus2: null,
    AftHmiStatus3: null,
    AftHmiStatus4: null,
    AftHmiStatus5: null,
    AftHmiStatus6: null,
    AftHmiStatus7: null,
    AftHmiStatus8: null,
    AftHmiStatus9: null,
    AftRecordTransitTime2: null,
    AftRecordTransitTime3: null,
    AftRecordTransitTime4: null,
    AftRecordTransitTime5: null,
    AftRecordTransitTime6: null,
    AftRecordTransitTime7: null,
    AftRecordTransitTime8: null,
    AftRecordTransitTime9: null,
    AftResultReading2: null,
    AftResultReading3: null,
    AftResultReading4: null,
    AftResultReading5: null,
    AftResultReading6: null,
    AftResultReading7: null,
    AftResultReading8: null,
    AftResultReading9: null,
    AltSmokeConfirmation2: null,
    AltSmokeConfirmation3: null,
    AltSmokeConfirmation4: null,
    AltSmokeConfirmation5: null,
    AltSmokeConfirmation6: null,
    AltSmokeConfirmation7: null,
    AltSmokeConfirmation8: null,
    AltSmokeConfirmation9: null,
    AltHmiStatus2: null,
    AltHmiStatus3: null,
    AltHmiStatus4: null,
    AltHmiStatus5: null,
    AltHmiStatus6: null,
    AltHmiStatus7: null,
    AltHmiStatus8: null,
    AltHmiStatus9: null,
    AltRecordTransitTime2: null,
    AltRecordTransitTime3: null,
    AltRecordTransitTime4: null,
    AltRecordTransitTime5: null,
    AltRecordTransitTime6: null,
    AltRecordTransitTime7: null,
    AltRecordTransitTime8: null,
    AltRecordTransitTime9: null,
    AltResultReading2: null,
    AltResultReading3: null,
    AltResultReading4: null,
    AltResultReading5: null,
    AltResultReading6: null,
    AltResultReading7: null,
    AltResultReading8: null,
    AltResultReading9: null,
    FlowObstruction2: null,
    FlowObstruction3: null,
    FlowObstruction4: null,
    FlowObstruction5: null,
    FlowObstruction6: null,
    FlowObstruction7: null,
    FlowObstruction8: null,
    FlowObstruction9: null,
    HmiStatusLow2: null,
    HmiStatusLow3: null,
    HmiStatusLow4: null,
    HmiStatusLow5: null,
    HmiStatusLow6: null,
    HmiStatusLow7: null,
    HmiStatusLow8: null,
    HmiStatusLow9: null,
    FlowIncrease2: null,
    FlowIncrease3: null,
    FlowIncrease4: null,
    FlowIncrease5: null,
    FlowIncrease6: null,
    FlowIncrease7: null,
    FlowIncrease8: null,
    FlowIncrease9: null,
    HmiStatusHigh2: null,
    HmiStatusHigh3: null,
    HmiStatusHigh4: null,
    HmiStatusHigh5: null,
    HmiStatusHigh6: null,
    HmiStatusHigh7: null,
    HmiStatusHigh8: null,
    HmiStatusHigh9: null,
    F820TestToolActivationAft: null,
    F821TestToolActivationAlt: null,
    F822AftLowAlarmTest: null,
    F823AftLowAlarmTestResult: null,
    F824AftHighAlarmTest: null,
    F825AftHighAlarmTestResult: null,
    F826AftBeamBlockTest: null,
    F827AftHmiStatus: null,
    F828AftBeamBlockTestResult: null,
    F829AltLowAlarmTest: null,
    F830AltLowAlarmTestResult: null,
    F831AltHighAlarmTest: null,
    F832AltHighAlarmTestResult: null,
    F833AltBeamBlockTest: null,
    F834AltHmiStatus: null,
    F835AltBeamBlockTestResult: null,
    F836IplelementSolenoid: null,
    F837IplelementIppositioner: null,
    F838IplelementPositionTransmitter: null,
    F839IplelementLimitSwitch: null,
    F836IplelementSolenoidDescription: null,
    F837IplelementIppositionerDescription: null,
    F838IplelementPositionTransmitterDescription: null,
    F839IplelementLimitSwitchDescription: null,
    F840AftTest1Solenoid: null,
    F841AftControlValveFailSafePosition: null,
    F842AftSolenoidMovementUponTrip: null,
    F843AftControlValvePositionAfterTrip: null,
    F844AftTest1Result: null,
    F845AftTest4LimitSwitch: null,
    F846AftLimitSwitchSetting: null,
    F847AftControlValveMovedToLimit: null,
    F848AftLimitSwitchActivated: null,
    F849AftTest4Result: null,
    F850AftTest2IpPositioner: null,
    F851AftValveLocalPosition1: null,
    F852AftValveLocalPosition2: null,
    F853AftValveLocalPosition3: null,
    F854AftValveLocalPosition4: null,
    F855AftValveLocalPosition5: null,
    F856AftValveLocalPosition6: null,
    F857AftValveLocalPosition7: null,
    F858AftValveLocalPosition8: null,
    F859AftValveLocalPosition9: null,
    F860AftTest2Deviation1: null,
    F861AftTest2Deviation2: null,
    F862AftTest2Deviation3: null,
    F863AftTest2Deviation4: null,
    F864AftTest2Deviation5: null,
    F865AftTest2Deviation6: null,
    F866AftTest2Deviation7: null,
    F867AftTest2Deviation8: null,
    F868AftTest2Deviation9: null,
    F869AftTest2Result: null,
    F870AftTest3PositionTransmitter: null,
    F871AftHmiPositionIndication1: null,
    F872AftHmiPositionIndication2: null,
    F873AftHmiPositionIndication3: null,
    F874AftHmiPositionIndication4: null,
    F875AftHmiPositionIndication5: null,
    F876AftHmiPositionIndication6: null,
    F877AftHmiPositionIndication7: null,
    F878AftHmiPositionIndication8: null,
    F879AftHmiPositionIndication9: null,
    F880AftTest3Deviation1: null,
    F881AftTest3Deviation2: null,
    F882AftTest3Deviation3: null,
    F883AftTest3Deviation4: null,
    F884AftTest3Deviation5: null,
    F885AftTest3Deviation6: null,
    F886AftTest3Deviation7: null,
    F887AftTest3Deviation8: null,
    F888AftTest3Deviation9: null,
    F889AftTest3Result: null,
    F890AltTest1Solenoid: null,
    F891AltControlValveFailSafePosition: null,
    F892AltSolenoidMovementUponTrip: null,
    F893AltControlValvePositionAfterTrip: null,
    F894AltTest1Result: null,
    F895AltTest4LimitSwitch: null,
    F896AltLimitSwitchSetting: null,
    F897AltControlValveMovedToLimit: null,
    F898AltLimitSwitchActivated: null,
    F899AltTest4Result: null,
    F900AltTest2IpPositioner: null,
    F901AltValveLocalPosition1: null,
    F902AltValveLocalPosition2: null,
    F903AltValveLocalPosition3: null,
    F904AltValveLocalPosition4: null,
    F905AltValveLocalPosition5: null,
    F906AltValveLocalPosition6: null,
    F907AltValveLocalPosition7: null,
    F908AltValveLocalPosition8: null,
    F909AltValveLocalPosition9: null,
    F910AltTest2Deviation1: null,
    F911AltTest2Deviation2: null,
    F912AltTest2Deviation3: null,
    F913AltTest2Deviation4: null,
    F914AltTest2Deviation5: null,
    F915AltTest2Deviation6: null,
    F916AltTest2Deviation7: null,
    F917AltTest2Deviation8: null,
    F918AltTest2Deviation9: null,
    F919AltTest2Result: null,
    F920AltTest3PositionTransmitter: null,
    F921AltHmiPositionIndication1: null,
    F922AltHmiPositionIndication2: null,
    F923AltHmiPositionIndication3: null,
    F924AltHmiPositionIndication4: null,
    F925AltHmiPositionIndication5: null,
    F926AltHmiPositionIndication6: null,
    F927AltHmiPositionIndication7: null,
    F928AltHmiPositionIndication8: null,
    F929AltHmiPositionIndication9: null,
    F930AltTest3Deviation1: null,
    F931AltTest3Deviation2: null,
    F932AltTest3Deviation3: null,
    F933AltTest3Deviation4: null,
    F934AltTest3Deviation5: null,
    F935AltTest3Deviation6: null,
    F936AltTest3Deviation7: null,
    F937AltTest3Deviation8: null,
    F938AltTest3Deviation9: null,
    F939AltTest3Result: null,
    F956TrfTypeSelection: null,
    InDraftState: false
};
