function base64toBlob(dataUrl) {
    const block = dataUrl.split(';');
    // Get the content type of the image
    const contentType = block[0].split(':')[1];
    // get the real base64 content of the file
    const realData = block[1].split(',')[1];
    const sliceSize = 512;

    const byteCharacters = atob(realData);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i += 1) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
    }
    return new Blob(byteArrays, { type: contentType });
}

export default base64toBlob;
