import { createTableDefinitionObject } from './DPS.helpers';

export const TYPE = {
    NAME: 'NAME'
};

const FuntionalLocationName = 'SapFloc';
const tableDefinition = [
    createTableDefinitionObject(FuntionalLocationName, 'Functional Location'),
    createTableDefinitionObject('FormLocationDescription', 'Functional Location Description'),
    createTableDefinitionObject('FormIcssSoftTag', 'ICSS Soft Tag'),
    createTableDefinitionObject('Status', 'Device Status')
];

export const updatedTabledefination = tableDefinition.map((row) => {
    if (row.columnTitle === 'Functional Location' || row.columnTitle === 'Device Status') {
        return {
            ...row,
            displaySort: true
        };
    }
    return row;
}
);

const addEditDPSConstants = {
    ADDDPS_SET_INITIALDATA: 'ADDDPS_SET_INITIALDATA',
    ADDDPS_RESET_DATA: 'ADDDPS_RESET_DATA',
    ADDDPS_UPDATE_DATA: 'ADDDPS_UPDATE_DATA',
    EDITDPS_SET_EXISTINGDATA: 'EDITDPS_SET_EXISTINGDATA',
    EDITDPS_RESET_DATA: 'EDITDPS_RESET_DATA',
    EDITDPS_UPDATE_DATA: 'EDITDPS_UPDATE_DATA',
    UPDATE_DPS_UNSAVED_CHANGES_LABEL: 'UPDATE_DPS_UNSAVED_CHANGES_LABEL'
};

export const DPSACTIONS = {
    ADD_DPSRECORD_LOCAL_STORAGE: 'ADD_DPSRECORD_LOCAL_STORAGE',
    ADD_DPSRECORD_PENDING: 'ADD_DPSRECORD_PENDING',
    ADD_DPSRECORD_SUCCESS: 'ADD_DPSRECORD_SUCCESS',
    ADD_DPSRECORD_FAILURE: 'ADD_DPSRECORD_FAILURE',
    EDIT_DPSRECORD_LOCAL_STORAGE: 'EDIT_DPSRECORD_LOCAL_STORAGE',
    EDIT_DPSRECORD_PENDING: 'EDIT_DPSRECORD_PENDING',
    EDIT_DPSRECORD_SUCCESS: 'EDIT_DPSRECORD_SUCCESS',
    EDIT_DPSRECORD_FAILURE: 'EDIT_DPSRECORD_FAILURE',
    GET_DPSRECORD_BY_SAPFLOC_PENDING: 'GET_DPSRECORD_BY_SAPFLOC_PENDING',
    GET_DPSRECORD_BY_SAPFLOC_SUCCESS: 'GET_DPSRECORD_BY_SAPFLOC_SUCCESS',
    GET_DPSRECORDS_PENDING: 'GET_DPSRECORDS_PENDING',
    GET_DPSRECORDS_FAILURE: 'GET_DPSRECORDS_FAILURE',
    GET_DPSRECORDS_SUCCESS: 'GET_DPSRECORDS_SUCCESS',
    GET_SEARCHDPSRECORDS_PENDING: 'GET_SEARCHDPSRECORDS_PENDING',
    GET_SEARCHDPSRECORDS_FAILURE: 'GET_SEARCHDPSRECORDS_FAILURE',
    GET_SEARCHDPSRECORDS_SUCCESS: 'GET_SEARCHDPSRECORDS_SUCCESS',
    GET_SORTDPSRECORDS_PENDING: 'GET_SORTDPSRECORDS_PENDING',
    GET_SORTDPSRECORDS_SUCCESS: 'GET_SORTDPSRECORDS_SUCCESS',
    GET_SORTDPSRECORDS_FAILURE: 'GET_SORTDPSRECORDS_FAILURE',
    REMOVE_DPSRECORD_LOCAL_STORAGE: 'REMOVE_DPSRECORD_LOCAL_STORAGE',
    RESET_DPS_TOTAL_COUNT: 'RESET_DPS_TOTAL_COUNT',
    DISPLAY_STATUS_SORTORDER: 'DISPLAY_STATUS_SORTORDER',
    FUNCTIONAL_LOCATION_SORTORDER: 'FUNCTIONAL_LOCATION_SORTORDER',
    GET_DPSRECORDS_TOTALCOUNT_SUCCESS: 'GET_DPSRECORDS_TOTALCOUNT_SUCCESS',
    DPSRECORD_FLOC_EXIST: 'DPSRECORD_FLOC_EXIST',
    DPS_LOCKED_FIELDS: 'DPS_LOCKED_FIELDS',
    CORRECTIVE_DPS_MAPPING: 'CORRECTIVE_DPS_MAPPING',
    GET_DPSFIELD_MAXLENGTH: 'GET_DPSFIELD_MAXLENGTH',
    ...addEditDPSConstants
};
