import React, { useEffect, useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, InputLabel } from '@material-ui/core';
import PropTypes from 'prop-types';
import _ from 'lodash';
import axios from 'axios';
import { MESSAGE_TYPE } from '../../../../common/common.constants';
import { APIEndpoints } from '../../../../../helpers/APILists';
import { trackErrors } from '../helper';
import { useMsal } from '@azure/msal-react';
import AlertDialog from 'components/DialogComponent/AlertDialog';
import { formatDate } from 'helpers/DateFormatter';
import TextInput from '../../elements/TextInput';
import { useStyles } from '../AdminForm.styled';
import DropdownInput from '../../elements/DropdownInput';
import { AdminConstants } from 'journeys/portal/Admin/AdminScreen.constants';
import ModalFooterActions from '../../elements/ModalFooterActions';

const DpsSourceMappingForm = ({ isOpen, title, onClose, rowData, onRecordSubmit, dropdownsData }) => {
    const classes = useStyles();
    const [createRowData, setCreateRowData] = useState(rowData);
    const [cancelAlert, setCancelAlert] = useState(false);
    const [dpsSourceMappingList, setDPSsourecMappingList] = useState([]);
    const [uniqueRecordError, setUniqueRecordError] = useState('');
    const [disabled, setDisabled] = useState(false);
    const { instance } = useMsal();
    const userName = instance.getActiveAccount();
    const submitButtonVisible = !_.isEqual(rowData, createRowData) && uniqueRecordError === ''
        && createRowData.RegionId
        && createRowData.DpsfieldId
        && createRowData.DpssourceId
        && createRowData.IsReadOnly
        && createRowData.IsCalculated
        && createRowData.Status;

    useEffect(() => {
        axios.get(APIEndpoints.ADMIN_GetDPSsourceMapping)
            .then((res) => {
                setDPSsourecMappingList(res.data.map(
                    x => ({ DpsfieldId: x.DpsfieldId, DpssourceMappingId: x.DpssourceMappingId, RegionId: x.RegionId, DpssourceId: x.DpssourceId })));
            })
            .catch((err) => {
                trackErrors({
                    messageType: MESSAGE_TYPE.ERROR,
                    message: err.message,
                    exception: err
                });
            });
    }, []);

    useEffect(() => {
        axios.get(APIEndpoints.ADMIN_GetBpRegionDetails)
            .then((res) => {
                const regionAlias = res.data.find(x => x?.RegionId === createRowData?.RegionId)?.BpRegionAlias;
                setCreateRowData(prev => ({
                    ...prev, BpRegionAlias: regionAlias
                }));
            })
            .catch((err) => {
                trackErrors({
                    messageType: MESSAGE_TYPE.ERROR,
                    message: err.message,
                    exception: err
                });
            });
    }, [createRowData?.RegionId, dropdownsData.bpRegionAliasOptions]);

    useEffect(() => {
        if (!_.isEqual(rowData, createRowData) && createRowData.DpssourceId === 1) {
            setCreateRowData(prev => ({
                ...prev,
                IsReadOnly: 'No'
            }));
            setDisabled(true);
        } else {
            setDisabled(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [createRowData.DpssourceId]);

    const handleInputChange = ((e) => {
        if (e.target.name === 'DpsfieldMaxlength' && (e.target.value < 1 || e.target.value > 255) && e.target.value !== '') {
            return;
        }
        setCreateRowData((prev) => (
            {
                ...prev,
                [e.target.name]: e.target.value
            }
        ));
    });

    const handleBlur = () => {
        setUniqueRecordError('');
        if (dpsSourceMappingList.filter(x => x.RegionId === createRowData.RegionId
            && x.DpsfieldId === createRowData.DpsfieldId
            && x.DpssourceId === createRowData.DpssourceId
            && x.DpssourceMappingId !== createRowData.DpssourceMappingId).length >= 1) {
            setUniqueRecordError('(*An active record with the same combination of Region, DPS Field & DPS Source already exists.)');
        }
    };

    const cancelButtonHandler = () => {
        if (_.isEqual(rowData, createRowData)) {
            onClose();
        } else {
            setCancelAlert(true);
        }
    };

    const handleUnsaveChanges = () => {
        setCancelAlert(false);
    };

    const resetButtonHandler = () => {
        setUniqueRecordError('');
        setCreateRowData(rowData);
    };

    const submitButtonHandler = () => {
        createRowData.Region = null;
        createRowData.Dpsfield = null;
        createRowData.Dpssource = null;
        createRowData.Status = createRowData.Status === 'Active';
        createRowData.IsCalculated = createRowData.IsCalculated === 'Yes';
        createRowData.IsReadOnly = createRowData.IsReadOnly === 'Yes';
        createRowData.ModifiedBy = userName?.name;
        createRowData.LastModifiedDate = formatDate(new Date());
        if (createRowData.DpssourceMappingId) {
            updateRecord(createRowData);
        } else {
            createRowData.DpssourceMappingId = 0;
            createRowData.CreatedBy = userName?.name;
            createRowData.CreatedDate = formatDate(new Date());
            submitRecord(createRowData);
        }
    };

    const submitRecord = (dataRow) => {
        axios.post(APIEndpoints.ADMIN_AddDpsSourceMapping, dataRow)
            .then((res) => {
                onRecordSubmit(res.data.DpssourceMappingId);
                onClose();
            })
            .catch((err) => {
                trackErrors({
                    messageType: MESSAGE_TYPE.ERROR,
                    message: err.message,
                    exception: err
                });
            });
    };

    const updateRecord = (dataRow) => {
        axios.put(APIEndpoints.ADMIN_UpdateDpsSourceMapping, dataRow)
            .then((res) => {
                onRecordSubmit(res.data.DpssourceMappingId);
                onClose();
            })
            .catch((err) => {
                trackErrors({
                    messageType: MESSAGE_TYPE.ERROR,
                    message: err.message,
                    exception: err
                });
            });
    };
    return (
        <Dialog
            open={isOpen}
            scroll="paper"
            maxWidth="lg"
        >
            <DialogTitle id="scroll-dialog-title" classes={{ root: classes.headerRoot }} className={classes.header}>{title}</DialogTitle>
            {cancelAlert && (
                <AlertDialog
                    dialogMessage={AdminConstants.DIALOG_MESSAGE}
                    dialogOpenState={cancelAlert}
                    handleClose={() => handleUnsaveChanges()}
                    handleAlertFunction={onClose}
                />
            )}
            <DialogContent>
                <div style={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', gap: '0 10px' }}>
                    <DropdownInput
                        name="RegionId"
                        title="BP Region (SAP PM)"
                        value={createRowData.RegionId}
                        required
                        handleInputChange={handleInputChange}
                        options={dropdownsData.bpRegionOptions}
                        handleBlur={handleBlur}
                    />
                    <DropdownInput
                        name="DpsfieldId"
                        title="DPS Field"
                        value={createRowData.DpsfieldId}
                        required
                        handleInputChange={handleInputChange}
                        handleBlur={handleBlur}
                        options={dropdownsData.dpsFieldOptions}
                    />
                    <DropdownInput
                        name="DpssourceId"
                        title="DPS Source"
                        value={createRowData.DpssourceId}
                        required
                        handleBlur={handleBlur}
                        handleInputChange={handleInputChange}
                        options={dropdownsData.dpsSourceOptions}
                    />
                    <div />
                    <TextInput
                        name="BpRegionAlias"
                        title="BP Region Alias"
                        value={createRowData?.BpRegionAlias}
                        disabled
                    />
                    <DropdownInput
                        name="IsCalculated"
                        title="IsCalculated"
                        value={createRowData.IsCalculated}
                        required
                        handleInputChange={handleInputChange}
                        options={[{ label: 'Yes', value: 'Yes' }, { label: 'No', value: 'No' }]}
                    />
                    <DropdownInput
                        name="IsReadOnly"
                        title="IsReadOnly"
                        value={createRowData.IsReadOnly}
                        required
                        disabled={disabled}
                        handleInputChange={handleInputChange}
                        options={[{ label: 'Yes', value: 'Yes' }, { label: 'No', value: 'No' }]}
                    />
                    <DropdownInput
                        name="Status"
                        title="Status"
                        value={createRowData.Status}
                        options={[{ label: 'Active', value: 'Active' }, { label: 'Inactive', value: 'Inactive' }]}
                        handleInputChange={handleInputChange}
                        required
                    />
                </div>
                <div style={{ display: 'grid', marginTop: '1px', marginBottom: '10px' }}>
                    <TextInput
                        name="CalculationDetails"
                        title="Calculation Details"
                        value={createRowData.CalculationDetails}
                        handleInputChange={handleInputChange}
                        isMultiline
                        width="1080px"
                    />
                </div>
                <div style={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', gap: '0 10px' }}>
                    <TextInput
                        name="CreatedBy"
                        title="Created By"
                        value={createRowData?.DpssourceMappingId ? createRowData.CreatedBy : userName?.name}
                        disabled
                        handleInputChange={handleInputChange}
                    />
                    <TextInput
                        name="CreatedDate"
                        title="Created Date"
                        value={createRowData?.DpssourceMappingId ? formatDate(new Date(createRowData.CreatedDate)) : formatDate(new Date())}
                        disabled
                        handleInputChange={handleInputChange}
                    />
                    <TextInput
                        name="ModifiedBy"
                        title="Last Modified By"
                        value={createRowData.ModifiedBy || userName?.name}
                        disabled
                        handleInputChange={handleInputChange}
                    />
                    <TextInput
                        name="LastModifiedDate"
                        title="Last Modified Date"
                        value={createRowData?.DpssourceMappingId ? formatDate(new Date(createRowData.LastModifiedDate)) : formatDate(new Date())}
                        disabled
                        handleInputChange={handleInputChange}
                    />
                </div>
                <div style={{ display: 'grid', marginTop: '12px' }}>
                    <TextInput
                        name="Comment"
                        title="Comments"
                        value={createRowData.Comment}
                        handleInputChange={handleInputChange}
                        isMultiline
                        width="1080px"
                    />
                </div>
                {uniqueRecordError && <span style={{ fontSize: '11px', color: 'rgb(255, 0, 0)', fontWeight: 'bold', right: '10%' }}>{uniqueRecordError}</span>}
            </DialogContent>
            <DialogActions style={{ marginRight: '15px', paddingBottom: '18px', paddingTop: '15px', justifyContent: 'space-between' }}>
                <div>
                    <InputLabel className={classes.inputLabel}>
                        <i> Note: If Region Name is not available in the list please add a new record in BP Region LKUP table.</i>
                    </InputLabel>
                    <InputLabel className={classes.inputLabelWithPadding}>
                        <i>
                            If DPS Field Name is not available in the list please add a new record in DPS Field LKUP table.
                        </i>
                    </InputLabel>
                    <InputLabel className={classes.inputLabelWithPadding}>
                        <i> If DPS Source Name is not available in the list please add a new record in DPS Data Source LKUP table.</i>
                    </InputLabel>
                </div>
                <ModalFooterActions
                    submitButtonVisible={submitButtonVisible}
                    submitButtonHandler={submitButtonHandler}
                    resetButtonHandler={resetButtonHandler}
                    cancelButtonHandler={cancelButtonHandler}
                />
            </DialogActions>
        </Dialog>
    );
};

export default DpsSourceMappingForm;

DpsSourceMappingForm.propTypes = {
    dropdownsData: PropTypes.object,
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    onRecordSubmit: PropTypes.func,
    rowData: PropTypes.object,
    title: PropTypes.string
};
