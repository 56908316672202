import { FieldHasValue, ValidateNumberField, IsANumber } from 'helpers/Validation';
import { commonConstants } from 'journeys/portal/Forms/Sections.constants';

export const FoundResultF61AsFoundResultFormula = (formData) => {
    if (FieldHasValue(formData.F42TestModuleReading1)
        && FieldHasValue(formData.F43TestModuleReading2)
        && FieldHasValue(formData.F44TestModuleReading3)
        && FieldHasValue(formData.F45TxReading1)
        && FieldHasValue(formData.F46TxReading2)
        && FieldHasValue(formData.F47TxReading3)
        && FieldHasValue(formData.F48HmiReading1)
        && FieldHasValue(formData.F49HmiReading2)
        && FieldHasValue(formData.F50HmiReading3)
        && FieldHasValue(formData.F25InstrumentLrv) && IsANumber(formData.F25InstrumentLrv) && FieldHasValue(formData.F60ToleranceEu)) {
        return (Math.max.apply(Math, [ValidateNumberField(formData.F54DeviationEu1), ValidateNumberField(formData.F55DeviationEu2), ValidateNumberField(formData.F56DeviationEu3)])
            <= ValidateNumberField(formData.F60ToleranceEu)
            ? commonConstants.PASS_Result : commonConstants.FAIL_Result);
    }
    return '';
};

export const FoundResultF62SensorTrimNeedFormula = (formData) => {
    if (formData.F61AsFoundResult) {
        if (ValidateNumberField(formData.F26InstrumentUrv) !== ValidateNumberField(formData.F25InstrumentLrv)) {
            return Math.max.apply(Math, [ValidateNumberField(formData.F54DeviationEu1),
            ValidateNumberField(formData.F55DeviationEu2), ValidateNumberField(formData.F56DeviationEu3)])
                / (ValidateNumberField(formData.F26InstrumentUrv) - ValidateNumberField(formData.F25InstrumentLrv)) <= 0.01 ? 'NO' : 'YES';
        }
        return 'NO';
    }
    return '';
};

export const ReCalibrationNeededP831 = (formData) => {
    if (FieldHasValue(formData.F102AftResult)) {
        if (Math.max.apply(Math, [ValidateNumberField(formData.F215AftDeviation1),
        ValidateNumberField(formData.F216AftDeviation2), ValidateNumberField(formData.F217AftDeviation3)]) <= 2) {
            return 'NO';
        }
        return 'YES';
    }
    return '';
};
