import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import _ from 'lodash';
import SiteWrapper from 'components/SiteWrapper/SiteWrapper';
import { SecondaryHeaderButton } from 'elements/Button/HeaderButton';
import HomeImages from './HomeImages/HomeImages';
import { HeaderRegionName, AssetName, RadionButtons, RadionAndImageWrapper, DisclaimarText } from './Home.styled';
import { getAllMOData, reSetDateFilter } from '../MOSelection/MOSelection.actions';
import { getAllDPSData, searchDPS, sortDPS } from '../SubSections/DPS/DPS.actions';
import RadioButtonsGroup from '../../../components/RadionButton/RadioButtonsGroup';
import { sortTestEquipment, getTestMakeModelData, searchTestEquipment } from '../TestEquipment/TestEquipment.actions';
import {
  getAllRegionData, getAllTagSelectionMOScreenData, getAllTagBiSelectionMOScreenData,
  saveAssetName, saveWorkType,
  saveBarrierType, getAllBarrierType, getAllWorkType, setDataLoaded,
  getTrfMasterLists, getAllInfoDocuments, setCurrentView,
  saveGroupName, checkOfflineDataPresent, setPreviousScreen
} from './Home.actions';
import { getAllSavedTRFData } from '../SavedTRFPrintScreen/SavedTRFPrintScreen.actions';
import { MRAT_USER_ROLE } from './Home.constants';
import { APIEndpoints } from 'helpers/APILists';
import { useMsal } from '@azure/msal-react';
import axios from 'axios';
import { trackErrors } from '../Admin/AdminComponents/helper';
import { MESSAGE_TYPE } from 'journeys/common/common.constants';
import { getMultiTRFDetails } from '../Forms/Forms.actions';

const HomePage = (props) => {
  const {
    dispatch,
    fetchAllRegionData,
    bpRegionNameAlias,
    assetNameList,
    workTypeList,
    barrierTypeList,
    loadAllTagSelectionMOScreenData,
    loadAllTagBiSelectionMOScreenData,
    loadDPSData,
    isDataLoaded,
    isMRATOnline,
    isOfflineDataPresent,
    loadAllSavedTRFData,
    loadTestMakeModelData,
    saveAsset,
    selectedAssetName,
    saveWorkTypeName,
    selectedWorkType,
    saveBarrierTypeName,
    selectedBarrierType,
    setCurrentAppView,
    setPreviousAppScreen,
    getInfoDocuments,
    checkOfflineFormDataPresent,
    sortColumn,
    dpsSearchString,
    sortDisplayStatusAscOrder,
    sortFunctionalLocationAscOrder,
    sortDPSRecord,
    searchDPSRecord,
    selectedGroupName,
    saveGroup,
    regionDetails,
    currentView,
    resetDateFilter,
    selectedAssetAliasName,
    disclaimer,
    searchStringForTestEq,
    searchTestEquipmentRecords,
    sortColumnTestEquiment,
    setSortedTestEquipmentView,
    sortStatusAscOrder,
    sortDeviceTypeAscOrder,
    userRole,
    draftStatus,
    draftNotice
  } = props;

  const [draftNoticeVisible, setDraftNoticeVisible] = useState(false);
  const groupNameList = _.uniq(regionDetails.map(x => x.MratGroup));
  const { instance } = useMsal();
  const userName = instance.getActiveAccount();
  const selectedAssetImage = localStorage.getItem(`${selectedAssetName?.toUpperCase()}-image`);

  const showAssetImage = regionDetails.find(x => x.BpSiteName.toUpperCase() === selectedAssetName.toUpperCase())?.ImageStatus !== 'Inactive';

  const testEquipmentButtonHandler = async () => {
    setPreviousAppScreen('home');
    setCurrentAppView('testequipment');
    await loadTestMakeModelData();
    searchStringForTestEq && searchTestEquipmentRecords(searchStringForTestEq);
    sortColumnTestEquiment !== '' && (sortColumnTestEquiment === 'DeviceType'
      ? setSortedTestEquipmentView(sortColumnTestEquiment, sortDeviceTypeAscOrder)
      : setSortedTestEquipmentView(sortColumnTestEquiment, sortStatusAscOrder));
  };
  const dpsButtonHandler = async () => {
    setCurrentAppView('dps');
    await loadDPSData();
    !dpsSearchString && sortColumn === 'SapFloc' ? sortDPSRecord(sortColumn, sortFunctionalLocationAscOrder) : sortDPSRecord(sortColumn, sortDisplayStatusAscOrder);
    dpsSearchString && searchDPSRecord(dpsSearchString);
  };
  const printButtonHandler = async () => {
    setCurrentAppView('savedTRF');
    await loadAllSavedTRFData();
  };

  const submitButtonHandler = async () => {
    props.loadMOData();
    props.loadDPSData();
    setCurrentAppView('moselection');
    loadTestMakeModelData();
    loadAllTagSelectionMOScreenData();
    loadAllTagBiSelectionMOScreenData();
  };

  const loadData = () => {
    dispatch(getAllRegionData());
    dispatch(getMultiTRFDetails());
    fetchAllRegionData();
    props.getTrfMasterLists();
    getInfoDocuments();
  };

  // when application comes online then reload data
  const onlineOfflineCallback = (e) => {
    if (e.type === 'online') {
      loadData();
    }
  };

  const widerFooter = '4rem';
  const encodedRegionName = encodeURIComponent(bpRegionNameAlias);
  useEffect(() => {
    axios.get(`${APIEndpoints.GetDraftTrackingDetails}?region=${encodedRegionName}&email=${userName?.username}`)
    .then((res) => res?.data?.length > 0 && setDraftNoticeVisible(true))
    .catch((err) => {
      trackErrors({
          messageType: MESSAGE_TYPE.ERROR,
          message: err.message,
          exception: err
      });
  });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (bpRegionNameAlias && !isDataLoaded) {
      loadData();
      dispatch(setDataLoaded());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.addEventListener('online', onlineOfflineCallback);
    return () => {
      window.removeEventListener('online', onlineOfflineCallback);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => () => {
    if (currentView === 'home') {
      return resetDateFilter();
    }
    return '';
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    checkOfflineFormDataPresent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOfflineDataPresent, isMRATOnline]);

  return (
    <SiteWrapper
      headerText="Asset and Barrier Type"
      headerButtonHandler={dpsButtonHandler}
      headerButtonText="Device Performance Standard"
      ButtonSection={(
        (MRAT_USER_ROLE.Technician === userRole || MRAT_USER_ROLE.Engineer === userRole)
        && (
          <SecondaryHeaderButton
            buttonText="Test Equipment Inventory"
            handleButton={testEquipmentButtonHandler}
            isDisabled={selectedAssetName === ''}
          />
        )
      )}
      isDPSDisabled={selectedAssetName === ''}
      footerButtonHandler={submitButtonHandler}
      FooterButtonVisible={selectedAssetName !== '' && !(isMRATOnline && isOfflineDataPresent)}
      footerButtonText="Submit"
      showInfoIcon
      headerPrintButtonHandler={printButtonHandler}
      widerFooter={widerFooter}
    >
      <div className="row">
        <div className="col-xs-12">
          <HeaderRegionName>{bpRegionNameAlias}</HeaderRegionName>
          <RadioButtonsGroup
            isOfflineBehaviour={isMRATOnline}
            value={selectedGroupName}
            setValue={saveGroup}
            list={groupNameList}
            label="Group"
            maxWidth="960px"
            margin="0 3rem 0 1rem"
          />
          <RadionAndImageWrapper>
            <RadionButtons>
              <AssetName>
                <RadioButtonsGroup
                  isOfflineBehaviour={isMRATOnline}
                  value={selectedAssetAliasName}
                  setValue={saveAsset}
                  list={assetNameList}
                  label="Asset"
                  margin="0 3.3rem 0 1rem"
                />
              </AssetName>
              <RadioButtonsGroup
                isOfflineBehaviour={isMRATOnline}
                value={selectedWorkType}
                setValue={saveWorkTypeName}
                list={workTypeList}
                label="Work Type"
                margin="0 3rem 0 1rem"
              />
              {
                barrierTypeList && (
                  <RadioButtonsGroup
                    isOfflineBehaviour={isMRATOnline}
                    value={selectedBarrierType}
                    setValue={saveBarrierTypeName}
                    list={barrierTypeList}
                    label="Barrier Type"
                    margin="0 2rem 0 1rem"
                  />
                )
              }
            </RadionButtons>
            {(selectedAssetImage !== 'null' && showAssetImage) && (
              <HomeImages
                image={selectedAssetImage}
              />
            )}
            <div />
          </RadionAndImageWrapper>
        </div>
      </div>
      <div style={{ position: 'fixed', marginBottom: '65px', bottom: '0' }}>
        {disclaimer && (
          <DisclaimarText>
            {parse(disclaimer ?? '')}
          </DisclaimarText>
        )}
        {draftStatus && draftNoticeVisible && (
          <DisclaimarText>
            {parse(draftNotice ?? 'Test')}
          </DisclaimarText>
        )}
      </div>
    </SiteWrapper>
  );
};

const mapStateToProps = ({ Home, MOSelection, DPS, AppData, TestEquipment }) => ({
  regionDetails: Home.regionDetails,
  bpRegionNameAlias: Home.bpRegionNameAlias,
  assetNameList: Home.assetNameList,
  currentView: Home.currentView,
  previousScreen: Home.previousScreen,
  workTypeList: Home.workTypeList,
  isMRATOnline: AppData.isMRATOnline,
  barrierTypeList: Home.barrierTypeList,
  selectedAssetName: Home.selectedAssetName,
  selectedGroupName: Home.selectedGroupName,
  selectedWorkType: Home.selectedWorkType,
  selectedBarrierType: Home.selectedBarrierType,
  moDataAll: MOSelection.moDataAll,
  dpsDataAll: DPS.dpsDataAll,
  sortColumn: DPS.sortColumn,
  sortColumnTestEquiment: TestEquipment.sortColumn,
  sortStatusAscOrder: TestEquipment.sortStatusAscOrder,
  sortDeviceTypeAscOrder: TestEquipment.sortDeviceTypeAscOrder,
  dpsSearchString: DPS.dpsSearchString,
  sortFunctionalLocationAscOrder: DPS.sortFunctionalLocationAscOrder,
  sortDisplayStatusAscOrder: DPS.sortDisplayStatusAscOrder,
  isDataLoaded: Home.isDataLoaded,
  isOfflineDataPresent: Home.isOfflineDataPresent,
  selectedAssetAliasName: Home.selectedAssetAliasName,
  disclaimer: Home.disclaimer,
  searchStringForTestEq: TestEquipment.searchStringForTestEq,
  userRole: Home.userRole,
  draftStatus: Home.draftStatus,
  draftNotice: Home.draftNotice,
});

const mapDispatchToProps = dispatch => ({
  dispatch,
  setCurrentAppView: (view) => dispatch(setCurrentView(view)),
  setPreviousAppScreen: (screen) => dispatch(setPreviousScreen(screen)),
  saveGroup: (value) => {
    dispatch(saveGroupName(value));
  },
  saveAsset: (value) => dispatch(saveAssetName(value)),
  saveWorkTypeName: (value) => dispatch(saveWorkType(value)),
  saveBarrierTypeName: (value) => dispatch(saveBarrierType(value)),
  fetchAllRegionData: () => {
    dispatch(getAllBarrierType());
    dispatch(getAllWorkType());
  },
  getTrfMasterLists: () => dispatch(getTrfMasterLists()),
  loadDPSData: () => dispatch(getAllDPSData()),
  loadMOData: () => dispatch(getAllMOData()),
  loadAllSavedTRFData: () => dispatch(getAllSavedTRFData()),
  loadTestMakeModelData: () => dispatch(getTestMakeModelData()),
  loadAllTagSelectionMOScreenData: () => dispatch(getAllTagSelectionMOScreenData()),
  loadAllTagBiSelectionMOScreenData: () => dispatch(getAllTagBiSelectionMOScreenData()),
  getInfoDocuments: () => dispatch(getAllInfoDocuments()),
  checkOfflineFormDataPresent: () => dispatch(checkOfflineDataPresent()),
  sortDPSRecord: (sortColumn, colAscOrder) => dispatch(sortDPS(sortColumn, colAscOrder)),
  searchDPSRecord: (searchString) => dispatch(searchDPS(searchString)),
  resetDateFilter: () => { dispatch(reSetDateFilter()); },
  searchTestEquipmentRecords: (searchVal) => dispatch(searchTestEquipment(searchVal)),
  setSortedTestEquipmentView: (columnName, sortOrder) => dispatch(sortTestEquipment(columnName, sortOrder)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HomePage);

HomePage.propTypes = {
  assetNameList: PropTypes.array,
  barrierTypeList: PropTypes.array,
  bpRegionNameAlias: PropTypes.string,
  checkOfflineFormDataPresent: PropTypes.func,
  currentView: PropTypes.string,
  disclaimer: PropTypes.string,
  dispatch: PropTypes.func,
  dpsSearchString: PropTypes.string,
  draftNotice: PropTypes.string,
  draftStatus: PropTypes.bool,
  fetchAllRegionData: PropTypes.func,
  getInfoDocuments: PropTypes.func,
  getTrfMasterLists: PropTypes.func,
  isDataLoaded: PropTypes.bool,
  isMRATOnline: PropTypes.bool,
  isOfflineDataPresent: PropTypes.bool,
  loadAllSavedTRFData: PropTypes.func,
  loadAllTagBiSelectionMOScreenData: PropTypes.func,
  loadAllTagSelectionMOScreenData: PropTypes.func,
  loadDPSData: PropTypes.func,
  loadMOData: PropTypes.func,
  loadTestMakeModelData: PropTypes.func,
  regionDetails: PropTypes.array,
  resetDateFilter: PropTypes.func,
  saveAsset: PropTypes.func,
  saveBarrierTypeName: PropTypes.func,
  saveGroup: PropTypes.func,
  saveWorkTypeName: PropTypes.func,
  searchDPSRecord: PropTypes.func,
  searchStringForTestEq: PropTypes.string,
  searchTestEquipmentRecords: PropTypes.func,
  selectedAssetAliasName: PropTypes.string,
  selectedAssetName: PropTypes.string,
  selectedBarrierType: PropTypes.string,
  selectedGroupName: PropTypes.string,
  selectedWorkType: PropTypes.string,
  setCurrentAppView: PropTypes.func,
  setPreviousAppScreen: PropTypes.func,
  setSortedTestEquipmentView: PropTypes.func,
  sortColumn: PropTypes.string,
  sortColumnTestEquiment: PropTypes.string,
  sortDeviceTypeAscOrder: PropTypes.bool,
  sortDisplayStatusAscOrder: PropTypes.bool,
  sortDPSRecord: PropTypes.func,
  sortFunctionalLocationAscOrder: PropTypes.bool,
  sortStatusAscOrder: PropTypes.bool,
  userRole: PropTypes.string,
  workTypeList: PropTypes.array,
};
