import React from 'react';
import PropTypes from 'prop-types';
import { StyledLabelSpan } from './Label.styled';

export const StyledLabel = (props) => {
    const { color, id, value, fontSize, fontStyle, fontWeight, marginLeft, marginTop, textAlign, top, labelWidth, padding, label } = props;
    return (
        <StyledLabelSpan
            id={id}
            label={label}
            color={color}
            value={value}
            fontWeight={fontWeight}
            fontSize={fontSize}
            marginTop={marginTop}
            marginLeft={marginLeft}
            textAlign={textAlign}
            top={top}
            labelWidth={labelWidth}
            padding={padding}
            fontStyle={fontStyle}
        >
            {props.children}
        </StyledLabelSpan>
    );
};

StyledLabel.propTypes = {
    children: PropTypes.any,
    color: PropTypes.string,
    fontSize: PropTypes.string,
    fontStyle: PropTypes.string,
    fontWeight: PropTypes.any,
    id: PropTypes.string,
    label: PropTypes.string,
    labelWidth: PropTypes.string,
    marginLeft: PropTypes.string,
    marginTop: PropTypes.string,
    padding: PropTypes.string,
    textAlign: PropTypes.string,
    top: PropTypes.string,
    value: PropTypes.any,
};
