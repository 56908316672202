import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { MatchSRA } from 'helpers/Validation';
import OnlineOfflineIndicator from './OnlineOflineSection';
import { FooterContainer, GradientLine, FooterContent, TrfDropdown, SubmitButton, Version, OpenCameraButton, CloseCameraButton } from './Footer.styled';
import TrfDropdownSelection from './TrfDropdownSelect';
import TimerIcon from '@material-ui/icons/Timer';

const LOGGED_IN_AS_TEXT = 'User Logged in as ';

const Footer = (props) => {
    const { handleButton, headerText, isDisabled, userRole, adGroupType, displayFormStatus,
        isMRATOnline, trfSelectionVisible, buttonVisible, buttonText, widerFooter, draftButtonVisible,
        openCameraButtonVisible, openCameraHandler, closeCameraButtonVisible, closeCameraHandler,
        currentAppView, printButtonVisible, printButtonHandler, draftButtonHandler, draftStatus,
        selectedRowTags, footerNoteMoVisible, selectedTag, draftDuration } = props;
    const isPageHomeScreen = (headerText === 'Asset and Barrier Type' || headerText === 'MRAT Admin');
    const isPageAdmin = (headerText === 'MRAT Admin') || currentAppView === 'infoAdmin';
    const isDraftNoticeVisible = selectedRowTags.some(obj => obj?.InDraftState) && draftStatus && currentAppView === 'tagselectionmoscreen';
    const formOffline = displayFormStatus && !isMRATOnline;
    const EnvironmentVariable = () => {
        if (process.env.REACT_APP_RELEASE_INFO) {
            return `${process.env.REACT_APP_ENV} | ${process.env.REACT_APP_RELEASE_INFO}`;
        }
        return `${process.env.REACT_APP_ENV}`;
    };
    const draftCreationTime = selectedTag?.CreatedDateUtc;
    const draftTime = draftCreationTime ? Math.max(0, Math.round(selectedTag?.TimeInDraftAllowed - ((new Date() - new Date(draftCreationTime)) / (1000 * 60) + new Date().getTimezoneOffset()))) : draftDuration;
    const getAdminVersion = () => <div>{`V${process.env.REACT_APP_ADMIN_VERSION}`}</div>;

    const getRoleDisplayText = () => adGroupType?.AdGroupTypeId === 2 /* Support */ ? `${userRole ?? ''} ${adGroupType.Code}` : userRole ?? '';

    const getLoggedAsText = () => <div>{LOGGED_IN_AS_TEXT + getRoleDisplayText()}</div>;

    const getDraftNoticeText = () => (
        <div style={{ marginBottom: '20px' }}>
            <span style={{ color: '#ea7c34', fontSize: '12px', fontStyle: 'italic', fontWeight: '550' }}>*Records in red color are saved as draft</span>
        </div>
    );
    const getDateNoticeText = () => (
        <div style={{ marginBottom: '20px' }}>
            <span style={{ color: '#ea7c34', fontSize: '12px', fontStyle: 'italic', fontWeight: '550' }}>*Date in red color indicates that the MO has passed its Scheduled Start Date</span>
        </div>
    );
    const showDraftClock = () => displayFormStatus && (draftButtonVisible || selectedTag?.InDraftState);
    return (
        <div style={{ position: 'relative' }}>
            <FooterContainer
                className="footer"
                widerFooter={widerFooter}
            >
                <GradientLine />
                <Version>
                    <OnlineOfflineIndicator />
                </Version>
                <TrfDropdown>
                    {!closeCameraButtonVisible && trfSelectionVisible && (
                        <TrfDropdownSelection />
                    )}
                </TrfDropdown>
                <OpenCameraButton openCameraButtonVisible={openCameraButtonVisible}>
                    {openCameraButtonVisible && !closeCameraButtonVisible && (
                        <button type="button" onClick={openCameraHandler}>
                            Open Camera
                        </button>
                    )}
                </OpenCameraButton>
                <CloseCameraButton openCameraButtonVisible={openCameraButtonVisible}>
                    {closeCameraButtonVisible && (
                        <button type="button" onClick={closeCameraHandler}>
                            Close Camera
                        </button>
                    )}
                </CloseCameraButton>
                {showDraftClock() && (
                    <div style={{ position: 'absolute', right: '48%', bottom: '22%' }}>
                        <TimerIcon style={{ color: 'rgb(73, 130, 5)', fontSize: '25px' }} />
                        <span style={{ color: 'rgb(73, 130, 5)', fontSize: '11px', fontStyle: 'italic', fontWeight: '550' }}>{`${draftTime} min`}</span>
                    </div>
                )}
                <SubmitButton openCameraButtonVisible={openCameraButtonVisible} formOffline={formOffline && !MatchSRA(currentAppView)}>
                    {!closeCameraButtonVisible && buttonVisible && (
                        <button type="button" onClick={handleButton} disabled={isDisabled}>
                            {buttonText}
                        </button>
                    )}
                </SubmitButton>
                <SubmitButton openCameraButtonVisible={openCameraButtonVisible} formOffline={formOffline && !MatchSRA(currentAppView)}>
                    {!closeCameraButtonVisible && draftButtonVisible && (
                        <button type="button" onClick={draftButtonHandler} disabled={isDisabled}>
                            Save As Draft
                        </button>
                    )}
                </SubmitButton>
                <SubmitButton>
                    {printButtonVisible && (
                        <button type="button" onClick={printButtonHandler}>
                            Print
                        </button>
                    )}
                </SubmitButton>
                <FooterContent>
                    <div>
                        {isDraftNoticeVisible && getDraftNoticeText()}
                        {footerNoteMoVisible && getDateNoticeText()}
                        {isPageAdmin && getAdminVersion()}
                        {isPageHomeScreen && getLoggedAsText()}
                        <div>
                            Instrumentation and Control Maintenance Recording |
                            {' '}
                            {EnvironmentVariable()}
                        </div>
                    </div>
                </FooterContent>
            </FooterContainer>
        </div>
    );
};

Footer.propTypes = {
    adGroupType: PropTypes.object,
    buttonText: PropTypes.string,
    buttonVisible: PropTypes.any,
    closeCameraButtonVisible: PropTypes.bool,
    closeCameraHandler: PropTypes.func,
    currentAppView: PropTypes.string,
    displayFormStatus: PropTypes.bool,
    draftButtonHandler: PropTypes.func,
    draftButtonVisible: PropTypes.bool,
    draftDuration: PropTypes.number,
    draftStatus: PropTypes.bool,
    footerNoteMoVisible: PropTypes.bool,
    handleButton: PropTypes.func,
    headerText: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ]),
    isDisabled: PropTypes.bool,
    isMRATOnline: PropTypes.bool,
    openCameraButtonVisible: PropTypes.bool,
    openCameraHandler: PropTypes.func,
    printButtonHandler: PropTypes.func,
    printButtonVisible: PropTypes.any,
    selectedRowTags: PropTypes.array,
    selectedTag: PropTypes.object,
    trfSelectionVisible: PropTypes.bool,
    userRole: PropTypes.string,
    widerFooter: PropTypes.string
};

const mapStateToProps = ({ Home, AppData, TagSelectionMOScreen, Forms }) => ({
    userRole: Home.userRole,
    adGroupType: Home.adGroupType,
    isMRATOnline: AppData.isMRATOnline,
    currentAppView: Home.currentView,
    draftStatus: Home.draftStatus,
    selectedRowTags: TagSelectionMOScreen.selectedRowTags,
    selectedTag: Forms.selectedTag,
    draftDuration: Home.draftDuration
});

const mapDispatchToProps = dispatch => ({
    dispatch
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Footer);
