import { commonConstants, Placeholder } from '../../Sections.constants';
import { paddingValAFWS } from '../AsLeftTestWorkshop/AsLeftTestWorkshop.data';
import { labelWidth } from '../HMISection/Data/P710/P710.data';
import { paddingValue, paddingLabels, maxLength, marginLeft } from '../AreaClassification/AreaClassification.data';

export const motorNameplate = (formData) => ({
    rows: [
        {
            key: 1,
            fields: [{
                key: 'motorNameplate',
                label: 'Motor Nameplate (Where Applicable)',
                isLabel: true,
                xs: 12,
                fontWeight: '700',
                padding: paddingValAFWS
            }]
        },
        {
            key: 2,
            fields: [
                {
                    key: 'F1011MotorRatingLabel',
                    label: 'Rating (HP)',
                    xs: 2,
                    isLabel: true,
                    labelWidth,
                    fontWeight: '600',
                    fontSize: '9pt',
                    padding: paddingLabels
                },
                {
                    key: 'F1011MotorRating',
                    value: formData.F1011MotorRating,
                    xs: 4,
                    maxLength,
                    labelWidth: '0%',
                    width: '100%',
                    textAlign: 'center',
                    borderBottom: '0',
                    height: '1.2rem',
                    marginLeft
                },
                {
                    key: 'F1014MotorFrameLabel',
                    label: 'Frame',
                    xs: 2,
                    isLabel: true,
                    labelWidth,
                    fontWeight: '600',
                    fontSize: '9pt',
                    padding: paddingValue
                },
                {
                    key: 'F1014MotorFrame',
                    value: formData.F1014MotorFrame,
                    xs: 4,
                    maxLength,
                    labelWidth: '0%',
                    width: '100%',
                    textAlign: 'center',
                    borderRight: '0',
                    inputMarginRight: '4px',
                    borderBottom: '0',
                    height: '1.2rem',
                    marginLeft: '13px'
                }
            ]
        },
        {
            key: 3,
            fields: [
                {
                    key: 'F1012MotorVoltsLabel',
                    label: 'Volts',
                    xs: 2,
                    isLabel: true,
                    labelWidth,
                    fontWeight: '600',
                    fontSize: '9pt',
                    padding: paddingLabels
                },
                {
                    key: 'F1012MotorVolts',
                    value: formData.F1012MotorVolts,
                    xs: 4,
                    maxLength,
                    labelWidth: '0%',
                    width: '100%',
                    textAlign: 'center',
                    borderBottom: '0',
                    height: '1.2rem',
                    marginLeft
                },
                {
                    key: 'F1015MotorRpmLabel',
                    label: 'RPM',
                    xs: 2,
                    isLabel: true,
                    labelWidth,
                    fontWeight: '600',
                    fontSize: '9pt',
                    padding: paddingValue
                },
                {
                    key: 'F1015MotorRpm',
                    value: formData.F1015MotorRpm,
                    xs: 4,
                    maxLength,
                    labelWidth: '0%',
                    width: '100%',
                    textAlign: 'center',
                    borderRight: '0',
                    borderBottom: '0',
                    inputMarginRight: '4px',
                    height: '1.2rem',
                    marginLeft: '13px'
                }
            ]
        },
        {
            key: 4,
            fields: [
                {
                    key: 'F1013MotorFlcLabel',
                    label: 'FLC (A)',
                    xs: 2,
                    isLabel: true,
                    labelWidth,
                    fontWeight: '600',
                    fontSize: '9pt',
                    padding: paddingLabels
                },
                {
                    key: 'F1013MotorFlc',
                    value: formData.F1013MotorFlc,
                    xs: 4,
                    maxLength,
                    labelWidth: '0%',
                    width: '100%',
                    textAlign: 'center',
                    borderBottom: '0',
                    marginBottom: '4px',
                    height: '1.24rem',
                    marginLeft
                },
                {
                    key: 'F1016MotorStartingLabel',
                    label: 'Starting',
                    xs: 2,
                    isLabel: true,
                    labelWidth,
                    fontWeight: '600',
                    fontSize: '9pt',
                    padding: paddingValue
                },
                {
                    key: 'F1016MotorStarting',
                    value: formData.F1016MotorStarting,
                    xs: 4,
                    labelWidth: '0%',
                    width: '100%',
                    textAlign: 'center',
                    borderRight: '0',
                    borderBottom: '0',
                    marginBottom: '4px',
                    inputMarginRight: '4px',
                    isDropdown: true,
                    placeholder: Placeholder.SELECT,
                    options: [{ name: Placeholder.SELECT, value: '' },
                    { name: 'DOL/Soft', value: 'DOL/Soft' },
                    { name: 'Start/VSD', value: 'Start/VSD' },
                    { name: commonConstants.Not_Known_or_Visible, value: commonConstants.Not_Known_or_Visible }],
                    rootMarginRight: '4px',
                    marginLeft: '13px'
                }
            ]
        }
    ]
});

export const selectMotorNameplateData = (selectedTag, formName, formData) => {
    let filterViewData;
    if (formName === 'M4-2') {
        filterViewData = motorNameplate(formData);
    } else {
        filterViewData = { rows: [] };
    }
    return filterViewData;
};
