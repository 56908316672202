import { FieldHasValue, ValidateNumberField } from 'helpers/Validation';
import { commonConstants } from 'journeys/portal/Forms/Sections.constants';

export const ZeroFlowF812DeviationFormula = (formData) => FieldHasValue(formData.F810ZeroFlow)
    ? Math.abs(ValidateNumberField(formData.F810ZeroFlow)) : '';

export const ZeroFlowF477ZeroFlowCheckPassFailResultFormula = (formData) => {
    if (FieldHasValue(formData.F812Deviation)
        && FieldHasValue(formData.F101AftTolerance)) {
        return Math.max.apply(Math, [ValidateNumberField(formData.F812Deviation)])
            <= ValidateNumberField(formData.F101AftTolerance)
            ? commonConstants.PASS_Result : commonConstants.FAIL_Result;
    }
    return '';
};
