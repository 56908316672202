import XLSX from 'sheetjs-style';
import FileSaver from 'file-saver';

const fileName = 'MRAT-Admin';
const fileNameDps = 'DpsSnapshot';
const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const fileExtension = '.xlsx';

export const handleExport = async (tableData) => {
    if (Array.isArray(tableData) && tableData.length > 0) {
        const ws = XLSX.utils.json_to_sheet(tableData);
        const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
    }
};

export const handleExportDps = async (tableData1, tableData2) => {
    if (Array.isArray(tableData1) && tableData1.length > 0 && Array.isArray(tableData2) && tableData2.length > 0) {
        const ws1 = XLSX.utils.json_to_sheet(tableData1);
        const ws2 = XLSX.utils.json_to_sheet(tableData2);
        const wb = { Sheets: { Before: ws1, After: ws2 }, SheetNames: ['Before', 'After'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileNameDps + fileExtension);
    }
};
