/* eslint-disable no-console */
import React from 'react';
import PropTypes from 'prop-types';
import { InteractionType, PublicClientApplication, EventType } from '@azure/msal-browser';
import { MsalAuthenticationTemplate, MsalProvider } from '@azure/msal-react';
import { useHistory } from 'react-router-dom';
import { msalConfig } from '../../authConfig';
import { CustomNavigationClient } from '../NavigationClient';
import { SplashScreen } from 'elements/SplashScreen';

export const msalInstance = new PublicClientApplication(msalConfig);

// Account selection logic is app dependent. Adjust as needed for different use cases.
const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
    const currAccount = accounts[0];
    if (currAccount.username.toLowerCase().includes('bp')) {
        msalInstance.setActiveAccount(currAccount);
        sessionStorage.setItem('userName', currAccount.username);
    }
}

msalInstance.addEventCallback((event) => {
    // set user's account as active account on successful login event
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
        const { account } = event.payload;
        msalInstance.setActiveAccount(account);
        sessionStorage.setItem('userName', account.username);
    }
}, error => {
});

msalInstance.handleRedirectPromise().then(authResult => {
    // Check if user signed in
    const account = msalInstance.getActiveAccount();
    if (!account) {
        // redirect anonymous user to login page
        msalInstance.loginRedirect();
    } else {
        sessionStorage.setItem('userName', account.username);
    }
}).catch(err => {
    console.error(err);
});

export const MsalAuthWrapper = (props) => {
    const { children } = props;
    const history = useHistory();
    const navigationClient = new CustomNavigationClient(history);
    msalInstance.setNavigationClient(navigationClient);

    return (
        <MsalProvider instance={msalInstance}>
            <MsalAuthenticationTemplate
                interactionType={InteractionType.Redirect}
                loadingComponent={SplashScreen}
            >
                {children}
            </MsalAuthenticationTemplate>
        </MsalProvider>
    );
};

MsalAuthWrapper.propTypes = {
    children: PropTypes.any,
};
