import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import { DelButton, DialogMessage } from './DraftAlertDialog.styled';
import { FormInputMultiline } from 'elements/Inputs';
import { useSelector } from 'react-redux';

const DraftAlertDialog = (props) => {
  const { handleClose, handleAlertFunction, dialogOpenState, dialogMessage,
          displayNotice = false, cancelButtonText, confirmButtonText } = props;
  const [notice, setNotice] = useState('');
  const [confirmButtonVisible, setConfirmButtonVisible] = useState(!displayNotice);
  const initialDraftTime = useSelector((state) => state.Home.draftDuration);
  const draftTimeAllowed = useSelector((state) => state.Forms.selectedTag?.TimeInDraftAllowed);
  const draftCreationTime = useSelector((state) => state.Forms.selectedTag?.CreatedDateUtc);
  const draftTime = draftCreationTime ? Math.max(0, Math.round(draftTimeAllowed - ((new Date() - new Date(draftCreationTime)) / (1000 * 60) + new Date().getTimezoneOffset()))) : initialDraftTime;
  const noticeHandler = (obj) => {
    setNotice(obj.draftNotice);
  };
  useEffect(() => {
    if (displayNotice) {
      if (notice.length >= 30) {
        setConfirmButtonVisible(true);
      } else {
        setConfirmButtonVisible(false);
      }
    }
    // eslint-disable-next-line
  }, [notice]);
  return (
    <Dialog
      open={dialogOpenState}
      onClose={handleClose}
      PaperProps={{ style: { minWidth: '50%' } }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title"> </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description" style={{ color: '#498205', fontSize: '16px', fontWeight: 'bold', display: 'flex' }}>
          <ReportProblemOutlinedIcon style={{ color: 'gold', fontSize: '3rem' }} />
          <DialogMessage>
            {dialogMessage}
          </DialogMessage>
        </DialogContentText>
        {!displayNotice && (
          <>
            <DialogContentText id="alert-dialog-description" style={{ color: 'black', fontSize: '14px', fontWeight: '500', display: 'flex', marginLeft: '50px' }}>
              <DialogMessage>
                - Any pictures taken will be lost
                <br />
                - Selecting a differnet TRF will be disabled
                <br />
                  -
                {
                  ` Once Time In Draft allowed (${draftTime} minutes) expires, the Draft will be deleted`
                  }
                <br />
                - Normal TRF features will be restored once Time In Draft allowed expires
              </DialogMessage>
            </DialogContentText>
            <DialogContentText id="alert-dialog-description" style={{ color: '#498205', fontSize: '16px', fontWeight: 'bold', display: 'flex', justifyContent: 'center' }}>
              <DialogMessage>
                <br />
                Do you want to continue to save the TRF as a Draft?
              </DialogMessage>
            </DialogContentText>
          </>
        )}
        {displayNotice && (
          <FormInputMultiline
            rows="3"
            id="draftNotice"
            name="draftNotice"
            label={`Minimum 30 characters (${notice.length}/255)`}
            marginTop="5px"
            value={notice}
            handleChange={(obj) => noticeHandler(obj)}
            type="text"
            fontSize="11pt"
          />
        )}
      </DialogContent>
      <DialogActions style={{ justifyContent: 'center' }}>
        <Button onClick={() => handleClose()}>
          <DelButton>
            {cancelButtonText}
          </DelButton>
        </Button>
        {confirmButtonVisible && (
          <Button onClick={() => handleAlertFunction(notice)}>
            <DelButton>
              {confirmButtonText}
            </DelButton>
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default DraftAlertDialog;

DraftAlertDialog.propTypes = {
  cancelButtonText: PropTypes.string,
  confirmButtonText: PropTypes.string,
  dialogMessage: PropTypes.string,
  dialogOpenState: PropTypes.bool,
  displayNotice: PropTypes.bool,
  handleAlertFunction: PropTypes.func,
  handleClose: PropTypes.func,
};
