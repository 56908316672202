import { Placeholder, commonConstants } from 'journeys/portal/Forms/Sections.constants';
import { strEquals } from '../../../../../Admin/AdminScreen.helper';

const dropdownOptions = [
    { name: Placeholder.SELECT, value: '' },
    { name: commonConstants.DEFECT_FOUND_EQUIPMENT_ISOLATED, value: commonConstants.DEFECT_FOUND_EQUIPMENT_ISOLATED },
    { name: commonConstants.DEFECT_FOUND_EQUIPMENT_ONLINE, value: commonConstants.DEFECT_FOUND_EQUIPMENT_ONLINE },
    { name: 'No Defects Found (DOK)', value: 'No Defects Found (DOK)' },
    { name: 'No Inspection Done (NEX)', value: 'No Inspection Done (NEX)' }
];

const dropdownFailOptions = [
    { name: Placeholder.SELECT, value: '' },
    { name: commonConstants.DEFECT_FOUND_EQUIPMENT_ISOLATED, value: commonConstants.DEFECT_FOUND_EQUIPMENT_ISOLATED },
    { name: commonConstants.DEFECT_FOUND_EQUIPMENT_ONLINE, value: commonConstants.DEFECT_FOUND_EQUIPMENT_ONLINE }
];
const totalGeneralLabelsCount = 26;
export const checkResult = (formData) => {
    for (let i = 0; i < totalGeneralLabelsCount; i += 1) {
        const dropDownKeys = [
            `InspectionChecklistG${i + 1}`, `FlameproofD${i + 1}`, `IncreasedSafetyE${i + 1}`,
            `IntrinsicallySafeI${i + 1}`, `EncapsulationM${i + 1}`, `NonSparkingN${i + 1}`,
            `PurgePressurizedP${i + 1}`
        ];
        if (dropDownKeys.some(key => strEquals(formData[key], 'FAIL'))) {
            return true;
        }
    }
    return false;
};

export const InspectionResultDropdownOptions = (formData) => {
    if (checkResult(formData)) {
        return dropdownFailOptions;
    }
    return dropdownOptions;
};

export const InspectionResultValue = (formData) => {
    const valueArr = dropdownFailOptions.map(opt => opt.value);
    return checkResult(formData) && !valueArr.includes(formData.F85TestResult);
};
