import { Placeholder } from 'journeys/portal/Forms/Sections.constants';
import { labelWidth } from '../../../HMISection/Data/P710/P710.data';

export const FailureModeSectionDataM42 = (formData) => ({
    rows: [{
        key: 1,
        fields: [{
            key: 'Label',
            label: 'Select Ex Protection Type',
            xs: 2,
            labelWidth,
            padding: '0',
            isLabel: true,
            fontWeight: '600',
            fontSize: '9pt',
            marginTop: '15px'
        },
        {
            key: 'F957SelectExProtectionType',
            value: formData.F957SelectExProtectionType,
            isDropdown: true,
            placeholder: Placeholder.SELECT,
            options: [{ name: Placeholder.SELECT, value: '' },
                { name: 'D - Ex d (Flameproof)', value: 'D - Ex d (Flameproof)' },
                { name: 'E - Ex e (Increased Safety)', value: 'E - Ex e (Increased Safety)' },
                { name: 'I - Ex I (Intrinsically Safe)', value: 'I - Ex I (Intrinsically Safe)' },
                { name: 'M - Ex M (Encapsulation)', value: 'M - Ex M (Encapsulation)' },
                { name: 'N - Ex n (Non-Sparking)', value: 'N - Ex n (Non-Sparking)' },
                { name: 'P - Ex p (Purge Pressurized)', value: 'P - Ex p (Purge Pressurized)' }],
            labelWidth: '0%',
            width: '100%',
            inputTextAlign: 'center',
            xs: 4,
            marginTop: '15px',
            marginLeft: '16px'

        }]
    }]
});
