import { createTableDefinitionObject } from '../SubSections/DPS/DPS.helpers';

export const TYPE = {
    NAME: 'NAME'
};

export const tableDefinition = [
    createTableDefinitionObject('HdWorkOrder', 'MO Number', '9%'),
    createTableDefinitionObject('HdLocationMO', 'MO Functional Location', '12%'),
    createTableDefinitionObject('ScheduleStartDate', 'MO Schedule Start Date', '9%'),
    createTableDefinitionObject('ScheduleFinishDate', 'MO Schedule Finish Date', '9%'),
    createTableDefinitionObject('HdLocation', 'Tag Functional Location', '18%'),
    createTableDefinitionObject('DateTested', 'Tag First Date Tested', '9%'),
    createTableDefinitionObject('CompletedBy', 'Tag First Test Done By', '8%'),
    createTableDefinitionObject('TrfShortDesc', 'Tag Test Record ID', '9%'),
    createTableDefinitionObject('AsFoundResult', 'As-Found Result', '6%'),
    createTableDefinitionObject('TestResult', 'Test Result', '4%'),
];

export const updatedTableDefinition = tableDefinition.map((row) => {
    if (row.columnTitle === 'MO Number' || row.columnTitle === 'Tag Functional Location' || row.columnTitle === 'Tag First Date Tested') {
        return {
            ...row,
            displaySort: true
        };
    }
    return row;
}
);

export const TRF_ACTIONS = {
    GET_TRFRECORDS_PENDING: 'GET_TRFRECORDS_PENDING',
    GET_TRFRECORDS_SUCCESS: 'GET_TRFRECORDS_SUCCESS',
    GET_TRFRECORDS_FAILURE: 'GET_TRFRECORDS_FAILURE',
    GET_SEARCHTRFRECORDS_PENDING: 'GET_SEARCHTRFRECORDS_PENDING',
    GET_SEARCHTRFRECORDS_SUCCESS: 'GET_SEARCHTRFRECORDS_SUCCESS',
    GET_SEARCHTRFRECORDS_FAILURE: 'GET_SEARCHTRFRECORDS_FAILURE',
    SET_FILTER_VALUE: 'SET_FILTER_VALUE',
    CLEAR_FILTERS: 'CLEAR_FILTERS',
    MO_NUMBER_SORTORDER: 'MO_NUMBER_SORTORDER',
    TAG_FUNCTIONAL_LOCATION_SORTORDER: 'TAG_FUNCTIONAL_LOCATION_SORTORDER',
    TAG_DATE_TESTED_SORTORDER: 'TAG_DATE_TESTED_SORTORDER',
    GET_SORTTAGRECORDS_PENDING: 'GET_SORTTAGRECORDS_PENDING',
    GET_SORTTAGRECORDS_SUCCESS: 'GET_SORTTAGRECORDS_SUCCESS',
    GET_SORTTAGRECORDS_FAILURE: 'GET_SORTTAGRECORDS_FAILURE'
};
