export const adfPipelineParameterHeaderData = [
    {
        field: 'AdfPipelineParameterId',
        headerName: 'ADF Pipeline Parameter Id',
        width: '80',
        hideInTable: false,
        isDisabled: false,
        sortable: true
    },
    {
        field: 'ParamName',
        headerName: 'Param Name',
        width: '240',
        hideInTable: false,
        isDisabled: false,
        sortable: false
    },
    {
        field: 'ParamDataType',
        headerName: 'Param Data Type',
        width: '150',
        hideInTable: false,
        isDisabled: false,
        sortable: false
    },
    {
        field: 'ParamValue',
        headerName: 'Param Value',
        width: '270',
        hideInTable: false,
        isDisabled: false,
        sortable: false
    },
    {
        field: 'Status',
        headerName: 'Status',
        width: '85',
        hideInTable: false,
        isDisabled: false,
    },
    {
        field: 'CreatedBy',
        headerName: 'Created By',
        width: '140',
        hideInTable: false,
        isDisabled: false,
    },
    {
        field: 'CreatedDate',
        headerName: 'Created Date',
        width: '150',
        hideInTable: false,
        isDisabled: false
    },
    {
        field: 'ModifiedBy',
        headerName: 'Last Modified By',
        width: '140',
        hideInTable: false,
        isDisabled: false
    },
    {
        field: 'LastModifiedDate',
        headerName: 'Last Modified Date',
        width: '150',
        hideInTable: false,
        isDisabled: false
    },
    {
        field: 'Comment',
        headerName: 'Comments',
        width: '350',
        hideInTable: false,
        isDisabled: false,
    }
];

export const adfPipelineParameterInitialState = {
    AdfPipelineParameterId: '',
    ParamName: '',
    ParamDataType: '',
    ParamValue: '',
    Status: 'Inactive',
    CreatedBy: '',
    CreatedDate: '',
    ModifiedBy: '',
    LastModifiedDate: '',
    Comment: '',
};

export const adfPipelineParameterSearchState = {
    AdfPipelineParameterId: '',
    ParamName: '',
    ParamDataType: '',
    ParamValue: '',
    Status: '',
    CreatedBy: '',
    CreatedDate: '',
    ModifiedBy: '',
    LastModifiedDate: '',
    Comment: '',
};

export const adfPipelineParameterApiEndpoint = {
    read: 'ADMIN_GetAdfPipelineParameter'
};
