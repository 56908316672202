import { dropdownPlaceholder } from '../../../AFT_FireTestSection/Data/M71/M71.data';
import { paddingLabel } from '../../../HMISection/Data/C11/C11.data';
import { labelWidth } from '../../../HMISection/Data/P710/P710.data';
import { conditionalValueSelector } from 'helpers/Validation';
import { AsLeftResultFormulaS71 } from '../../../AFT_SolenoidLimitSection/Data/S71/S71.formulas';

export const disableAsLeftTestFields = (formData) => formData.F102AftResult === 'PASS' || formData.F976AsLeftTestCompleted === 'NO';

export const asLeftSolenoid = (formData) => ({
    rows: [
        {
            key: 1,
            fields: [
                {
                    key: 'AsLeftTestLabel',
                    label: 'As-Left Test (1st solenoid/positioner)',
                    isLabel: true,
                    xs: 12,
                    fontWeight: '700',
                    padding: '5px 0 5px 4px'
                },
                {
                    key: 'F969AltValveFullClosureTravelTimeLabel',
                    label: 'Valve Full Closure Travel Time (sec)',
                    xs: 4,
                    isLabel: true,
                    fontWeight: '600',
                    fontSize: '9pt',
                    padding: '5px 0 0 4px',
                },
                {
                    key: 'F969AltValveFullClosureTravelTime',
                    value: conditionalValueSelector(disableAsLeftTestFields(formData), '', formData.F969AltValveFullClosureTravelTime),
                    isRequired: !disableAsLeftTestFields(formData),
                    isDisabled: disableAsLeftTestFields(formData),
                    readOnly: disableAsLeftTestFields(formData),
                    xs: 2,
                    type: 'number',
                    maxLength: '10',
                    width: '100%',
                    labelWidth: '0%',
                    height: '1.2rem',
                    textAlign: 'center',
                },
                {
                    key: 'F970AltValveFullOpeningTravelTimeLabel',
                    label: 'Valve Full Opening Travel Time (sec)',
                    xs: 4,
                    isLabel: true,
                    labelWidth,
                    textAlign: 'right',
                    fontWeight: '600',
                    fontSize: '9pt',
                    padding: paddingLabel
                },
                {
                    key: 'F970AltValveFullOpeningTravelTime',
                    value: conditionalValueSelector(disableAsLeftTestFields(formData), '', formData.F970AltValveFullOpeningTravelTime),
                    xs: 2,
                    isRequired: !disableAsLeftTestFields(formData),
                    isDisabled: disableAsLeftTestFields(formData),
                    readOnly: disableAsLeftTestFields(formData),
                    type: 'number',
                    maxLength: '10',
                    width: '100%',
                    labelWidth: '0%',
                    height: '1.2rem',
                    textAlign: 'center',
                    borderRight: '0',
                }
            ]
        },
        {
            key: 2,
            fields: [
                {
                    key: 'F971AltValveClosureMovementLabel',
                    label: 'Valve Closure Movement',
                    xs: 4,
                    isLabel: true,
                    fontWeight: '600',
                    fontSize: '9pt',
                    padding: '5px 0 0 4px',
                },
                {
                    key: 'F971AltValveClosureMovement',
                    value: conditionalValueSelector(disableAsLeftTestFields(formData), '', formData.F971AltValveClosureMovement),
                    isDisabled: disableAsLeftTestFields(formData),
                    readOnly: disableAsLeftTestFields(formData),
                    isDropdown: !disableAsLeftTestFields(formData),
                    placeholder: 'Select Movement',
                    options: [{ name: 'Select Movement', value: '' },
                    { name: 'Smooth', value: 'Smooth' },
                    { name: 'Abnormal', value: 'Abnormal' },
                    { name: 'Fail', value: 'Fail' }],
                    xs: 2,
                    borderTop: '0',
                    inputTextAlign: 'center',
                    width: '100%',
                    labelWidth: '0%',
                    textAlign: 'center',
                },
                {
                    key: 'F972AltConfirmLimitSwitchesWorkLabel',
                    label: 'Confirm Limit Switches Work Correctly',
                    xs: 4,
                    isLabel: true,
                    labelWidth,
                    textAlign: 'right',
                    fontWeight: '600',
                    fontSize: '9pt',
                    padding: paddingLabel
                },
                {
                    key: 'F972AltConfirmLimitSwitchesWork',
                    value: conditionalValueSelector(disableAsLeftTestFields(formData), '', formData.F972AltConfirmLimitSwitchesWork),
                    isDisabled: disableAsLeftTestFields(formData),
                    readOnly: disableAsLeftTestFields(formData),
                    isDropdown: !disableAsLeftTestFields(formData),
                    placeholder: dropdownPlaceholder,
                    options: [{ name: dropdownPlaceholder, value: '' },
                    { name: 'YES', value: 'YES' },
                    { name: 'NO', value: 'NO' }],
                    xs: 2,
                    borderTop: '0',
                    inputTextAlign: 'center',
                    width: '100%',
                    labelWidth: '0%',
                    textAlign: 'center',
                    borderRight: '0',
                    height: disableAsLeftTestFields(formData) ? '1.2rem' : '1.5rem'
                }
            ]
        },
        {
            key: 3,
            fields: [
                {
                    key: 'Nodata',
                    isLabel: 'true',
                    xs: 6,
                },
                {
                    key: 'F81AsLeftResultLabel1',
                    label: 'As-Left Result',
                    xs: 4,
                    isLabel: true,
                    labelWidth,
                    textAlign: 'right',
                    fontWeight: '600',
                    fontSize: '9pt',
                    padding: paddingLabel
                },
                {
                    key: 'F81AsLeftResult',
                    value: conditionalValueSelector(disableAsLeftTestFields(formData), '', AsLeftResultFormulaS71(formData)),
                    isDisabled: disableAsLeftTestFields(formData),
                    isCalculatedNew: true,
                    readOnly: true,
                    xs: 2,
                    width: '100%',
                    labelWidth: '0%',
                    height: '1.2rem',
                    borderTop: '0',
                    textAlign: 'center',
                    borderRight: '0',
                    marginBottom: '4px',
                    borderBottom: '0'
                }
            ]
        }
    ]
});
