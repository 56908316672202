import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { isMobile as isTablet } from 'react-device-detect';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import SyncAltIcon from '@material-ui/icons/SyncAlt';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import CheckBox from 'elements/CheckBox/CheckBox';
import { MRAT_USER_ROLE } from 'journeys/portal/Home/Home.constants';
import { useStyles, StyledTableCell, StyledTableRow, LoadingCell, tableCellStyle, StyledTableCell2, redTableCellStyle } from './DataTable.styled';

const RenderRows = ({ tableDefinition, userRole, actionButtonHandler, isMRATOnline, showArrowButton, setRenderRowsHandler, checkedList,
  hasActions, tableData: tableDataRaw, sortFunction, showCheckBox, historicalPrintScreen }) => {
  const { noPaddingTopBottom } = tableCellStyle({ hasActions });
  const dataAvailable = tableDataRaw.length > 0;
  const emptyCellLength = tableDefinition.length;
  const { redTableCell } = redTableCellStyle();
  let tableData = tableDataRaw;
  if (dataAvailable) {
    if (sortFunction) {
      tableData = sortFunction(tableData);
    }

    const checkBoxHandler = (obj) => {
      const name = Object.keys(obj)[0];
      const checked = Object.values(obj)[0];
      const newObj = {
        name,
        checked
      };
      const dataName = _.filter(checkedList, (ele) => ele.name !== name);
      setRenderRowsHandler([...dataName, newObj]);
    };

    const isCheckedHandler = (name) => {
      const dataName = _.filter(checkedList, (ele) => ele.name === name);
      if (!_.isEmpty(dataName)) {
        return dataName[0].checked;
      }
      return false;
    };

    return (
      tableData.map((dataRow, keyVal) => (
        <StyledTableRow key={`dataRow-${keyVal}`}>
          {showCheckBox && isMRATOnline && !isTablet && (MRAT_USER_ROLE.View !== userRole) && (hasActions && !dataRow.HideArrow && dataRow.CompletedBy ? (
            <StyledTableCell align="center">
              <CheckBox
                name={`${dataRow.Mi}-${dataRow.HdWorkOrder}-${dataRow.HdLocation}`}
                labelTop
                isCheckedHandler={isCheckedHandler}
                isCheckedTypeFunc
                handleChange={(obj) => checkBoxHandler(obj)}
              />
            </StyledTableCell>
          ) : (
            <StyledTableCell align="center">
              <CheckBox visibility="hidden" />
            </StyledTableCell>
          ))}
          {historicalPrintScreen ? (tableDefinition.map((definitionRow) => (
            <StyledTableCell2 key={definitionRow.column}>
              {dataRow[definitionRow.column]}
            </StyledTableCell2>
          ))) : (tableDefinition.map((definitionRow) => (
            <StyledTableCell
              className={`${noPaddingTopBottom} ${(dataRow?.InDraftState && redTableCell)
                || ((definitionRow.column === 'ScheduleStartDate'
                || definitionRow.column === 'ScheduleFinishDate') && dataRow.IsCellRedClassFinishDate)
                || (definitionRow.column === 'CalDueDate' && dataRow.IsCellRedClass)}`}
              key={definitionRow.column}
            >
              {dataRow[definitionRow.column]}
            </StyledTableCell>
          )))}
          <StyledTableCell className={noPaddingTopBottom} width={showArrowButton ? '10%' : '1%'} align="center">
            {showArrowButton && hasActions && !dataRow.HideArrow && (
              <IconButton component="span" onClick={() => actionButtonHandler(dataRow)}>
                <ArrowForwardIosIcon style={{ color: '#498205' }} />
              </IconButton>
            )}
          </StyledTableCell>

        </StyledTableRow>
      ))
    );
  }

  return (
    <StyledTableRow>
      <StyledTableCell colSpan={hasActions ? emptyCellLength + 1 : emptyCellLength} align="center">
        Data not available
      </StyledTableCell>
    </StyledTableRow>
  );
};

const RenderLoadingRows = ({ tableDefinition }) => (
  [1, 2, 3, 4, 5].map(id => (
    <StyledTableRow key={`LoadRow-${id}`}>
      {tableDefinition.map((defination) => (
        <StyledTableCell key={`LoadCell-${defination.column}`}>
          <LoadingCell animation="wave" />
        </StyledTableCell>
      ))}
      <StyledTableCell>
        <LoadingCell animation="wave" />
      </StyledTableCell>
    </StyledTableRow>
  ))
);

const DataTable = (props) => {
  const classes = useStyles(props);
  const { tableDefinition, id, isLoading, userRole, tableData, sortFunction, sortButtonHandler, actionButtonHandler,
    isMRATOnline, height, showCheckBox, printButtonVisible, showArrowButton, count, historicalPrintScreen } = props;
  const hasActions = true;
  const [checkedList, setCheckedList] = useState([]);
  const [isDisabled, setIsDisabled] = useState(true);

  const onClickSortHandler = (type) => {
    sortButtonHandler(type);
  };

  const selectAllHandler = (obj) => {
    if (!_.isEmpty(checkedList)) {
      const newCheckedList = [...checkedList];
      newCheckedList.forEach(element => {
        const el = element;
        el.checked = obj.selectAllPrint;
      });
      setCheckedList(newCheckedList);
    }
  };

  const isCheckedHandler = (name) => {
    if (!_.isEmpty(checkedList)) {
      const newTableData = _.filter(tableData, (ele) => !ele.HideArrow && ele.CompletedBy);
      return _.every(newTableData, (ele) => {
        const tempName = `${ele.Mi}-${ele.HdWorkOrder}-${ele.HdLocation}`;
        const tempFilter = _.find(checkedList, (obj) => obj.name === tempName);
        return tempFilter?.checked;
      });
    }
    return false;
  };

  const setRenderRowsHandler = (list) => {
    setCheckedList(list);
  };

  // to disable selectall when no submitted form is present
  useEffect(() => {
    if (!_.isEmpty(tableData)) {
      const newTableData = _.filter(tableData, (ele) => !ele.HideArrow && ele.CompletedBy);
      setIsDisabled(_.isEmpty(newTableData));
    } else {
      setIsDisabled(true);
    }
  }, [tableData]);

  // to set initial name and check status false for all the data rows
  useEffect(() => {
    if (!_.isEmpty(tableData)) {
      const newTableData = _.filter(tableData, (ele) => !ele.HideArrow && ele.CompletedBy);
      const updatedCheckedList = _.map(newTableData, (ele) => ({
        name: `${ele.Mi}-${ele.HdWorkOrder}-${ele.HdLocation}`,
        checked: false
      }));
      setCheckedList(updatedCheckedList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count, isMRATOnline]);

  // to make the print button visible on footer
  useEffect(() => {
    if (!_.isEmpty(checkedList)) {
      const updatedCheckedList = [...checkedList];
      const newTableData = _.filter(tableData, (ele) => !ele.HideArrow && ele.CompletedBy);
      const checkedForms = _.filter(newTableData, (ele) => {
        const tempName = `${ele.Mi}-${ele.HdWorkOrder}-${ele.HdLocation}`;
        const tempFilter = _.find(updatedCheckedList, (obj) => obj.name === tempName);
        return tempFilter?.checked;
      });
      // to extract and send only the Mo, MI and FLOC as array of objects to the print button handler
      const responseObj = [];
      _.forEach(checkedForms, (ele) => {
        const tempObj = {
          Mi: ele.Mi,
          HdWorkOrder: ele.HdWorkOrder,
          HdLocation: ele.HdLocation
        };
        responseObj.push(tempObj);
      });
      checkedForms.length > 0 ? printButtonVisible(true, responseObj) : printButtonVisible(false, responseObj);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedList, tableData]);
  const translateStyle = 'translate(5px, 5px) rotate(90deg) rotateX(180deg)';

  return (
    <>
      <TableContainer component={Paper} style={{ borderRadius: '0', overflow: 'auto', height: height || 'calc(100vh - 200px)' }}>
        <Table id={id} className={classes.table} aria-label="customized table">
          <TableHead style={{ position: 'sticky', top: '0', zIndex: '2' }}>
            <TableRow className={classes.iconContainer} style={{ verticalAlign: 'sub' }}>
              {showCheckBox && isMRATOnline && !isTablet && (MRAT_USER_ROLE.View !== userRole) && (
                <StyledTableCell width="8%" align="center">
                  <CheckBox
                    name="selectAllPrint"
                    label="Print Select All"
                    labelTop
                    isDisabled={isDisabled}
                    color="white"
                    isCheckedHandler={isCheckedHandler}
                    isCheckedTypeFunc
                    handleChange={(obj) => selectAllHandler(obj)}
                    historicalPrintScreen={historicalPrintScreen}
                  />
                </StyledTableCell>
              )}
              {historicalPrintScreen ? (tableDefinition.map((row) => (
                <StyledTableCell2 key={row.columnTitle} width={row.width}>
                  {row.columnTitle}
                  {row.displaySort
                    && (
                      <SyncAltIcon
                        id={`${row.columnTitle}-id`}
                        className={classes.icon}
                        style={{ transform: translateStyle }}
                        onClick={() => onClickSortHandler(row.column)}
                      />
                    )}
                </StyledTableCell2>
              ))) : (tableDefinition.map((row) => (
                <StyledTableCell key={row.columnTitle} width={row.width}>
                  {row.columnTitle}
                  {row.displaySort
                    && (
                      <SyncAltIcon
                        id={`${row.columnTitle}-id`}
                        className={classes.icon}
                        style={{ transform: translateStyle }}
                        onClick={() => onClickSortHandler(row.column)}
                      />
                    )}
                </StyledTableCell>
              )
              ))}
              {hasActions && <StyledTableCell2 width={showArrowButton ? '10%' : '1%'} align="center" />}
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading
              ? (
                <RenderLoadingRows
                  tableDefinition={tableDefinition}
                />
              )
              : (
                <RenderRows
                  backgroundColor="purple"
                  tableDefinition={tableDefinition}
                  actionButtonHandler={actionButtonHandler}
                  tableData={tableData || []}
                  showArrowButton={showArrowButton}
                  hasActions={hasActions}
                  sortFunction={sortFunction}
                  showCheckBox={showCheckBox}
                  isMRATOnline={isMRATOnline}
                  checkedList={checkedList}
                  historicalPrintScreen={historicalPrintScreen}
                  setRenderRowsHandler={setRenderRowsHandler}
                  userRole={userRole}
                />
              )}
          </TableBody>
        </Table>
      </TableContainer>

    </>
  );
};

DataTable.defaultProps = {
  isLoading: false
};

RenderRows.propTypes = {
  actionButtonHandler: PropTypes.func,
  checkedList: PropTypes.array,
  hasActions: PropTypes.bool.isRequired,
  historicalPrintScreen: PropTypes.bool,
  isMRATOnline: PropTypes.bool,
  setRenderRowsHandler: PropTypes.func,
  showArrowButton: PropTypes.bool,
  showCheckBox: PropTypes.bool,
  sortFunction: PropTypes.func,
  tableData: PropTypes.array.isRequired,
  tableDefinition: PropTypes.arrayOf(PropTypes.object).isRequired,
  userRole: PropTypes.string,

};

DataTable.propTypes = {
  actionButtonHandler: PropTypes.func,
  count: PropTypes.number,
  height: PropTypes.string,
  historicalPrintScreen: PropTypes.bool,
  id: PropTypes.string,
  isLoading: PropTypes.bool,
  isMRATOnline: PropTypes.bool,
  printButtonVisible: PropTypes.func,
  showArrowButton: PropTypes.bool,
  showCheckBox: PropTypes.bool,
  sortButtonHandler: PropTypes.func,
  sortFunction: PropTypes.func,
  tableData: PropTypes.array.isRequired,
  tableDefinition: PropTypes.arrayOf(PropTypes.object).isRequired,
  userRole: PropTypes.string,
};

export default DataTable;
