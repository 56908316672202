import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
    header: {
        backgroundColor: '#498205',
        color: 'white',
        paddingTop: '8px',
        paddingBottom: '8px',
        marginBottom: '8px'
    },
    button: {
        backgroundColor: '#498205',
        padding: '3px',
        color: 'white',
        fontSize: '11px',
        '&:hover': {
            backgroundColor: '#497205',
        },
    }
}));
