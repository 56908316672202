const getValueForDeviceType = (distinctDeviceDescTestMakeModelData, DeviceType, FullEqDesc) => DeviceType && FullEqDesc
    ? distinctDeviceDescTestMakeModelData.find(x => x.DeviceType === DeviceType
        && x.FullEqDesc === FullEqDesc)?.FullEqDesc : distinctDeviceDescTestMakeModelData.filter(x => x.DeviceType === DeviceType && x.Status === 'Active')[0]?.FullEqDesc;
const getOptionsForDeviceType = (distinctDeviceDescTestMakeModelData, DeviceType) => distinctDeviceDescTestMakeModelData.filter(x => x.DeviceType === DeviceType
    && x.Status === 'Active').map(x => ({ name: x.FullEqDesc, value: x.FullEqDesc }));

const testEquipDeviceFirst = 'Test Equipment Device Type 1';
const testEquipDeviceSecond = 'Test Equipment Device Type 2';
const testEquipDeviceThird = 'Test Equipment Device Type 3';
const testEquipDeviceFourth = 'Test Equipment Device Type 4';
const testEquipDevicefifth = 'Test Equipment Device Type 5';
const testEquipDescFirst = 'Test Equip Description 1';
const testEquipDescSecond = 'Test Equip Description 2';
const testEquipDescThird = 'Test Equip Description 3';
const testEquipDescForth = 'Test Equip Description 4';
const testEquipDescFifth = 'Test Equip Description 5';
const testEqPlaceholder = 'Search Test Equipment';
const testEqDescPlaceholder = 'Search Test Equip Description';

export const DeviceTypesSectionData = (selectedTag, formData, distinctDeviceTypeTestMakeModelData, distinctDeviceDescTestMakeModelData, formName) => {
    const optionObj = distinctDeviceTypeTestMakeModelData.map(x => ({ name: x.DeviceType, value: x.DeviceType }));
    const widthLabel = '36%';
    const inputWidth = '64%';
    const descInputWidthLabel = '68%';
    const descWidthLabel = '32%';
    return {
        rows: [
            {
                key: 1,
                fields: [{
                    key: 'F16EqType1',
                    label: testEquipDeviceFirst,
                    value: formData ? formData.F16EqType1 : '',
                    options: optionObj,
                    isRequired: true,
                    placeholder: testEqPlaceholder,
                    labelWidth: widthLabel,
                    width: inputWidth,
                    xs: 6,
                    marginTop: '15px'
                },
                {
                    key: 'F7FullEqDesc1',
                    label: testEquipDescFirst,
                    value: formData ? getValueForDeviceType(distinctDeviceDescTestMakeModelData, formData.F16EqType1, formData.F7FullEqDesc1) : '',
                    options: getOptionsForDeviceType(distinctDeviceDescTestMakeModelData, formData?.F16EqType1),
                    isRequired: true,
                    placeholder: testEqDescPlaceholder,
                    xs: 6,
                    marginLeft: '2rem',
                    width: descInputWidthLabel,
                    labelWidth: descWidthLabel,
                    marginTop: '15px'
                },
                {
                    key: 'F17EqType2',
                    label: testEquipDeviceSecond,
                    value: formData ? formData.F17EqType2 : '',
                    options: optionObj,
                    placeholder: testEqPlaceholder,
                    xs: 6,
                    labelWidth: widthLabel,
                    width: inputWidth,
                    borderTop: false
                },
                {
                    key: 'F8FullEqDesc2',
                    label: testEquipDescSecond,
                    value: formData ? getValueForDeviceType(distinctDeviceDescTestMakeModelData, formData.F17EqType2, formData.F8FullEqDesc2) : '',
                    options: getOptionsForDeviceType(distinctDeviceDescTestMakeModelData, formData?.F17EqType2),
                    placeholder: testEqDescPlaceholder,
                    xs: 6,
                    marginLeft: '2rem',
                    width: descInputWidthLabel,
                    labelWidth: descWidthLabel,
                    borderTop: false
                },
                {
                    key: 'F18EqType3',
                    label: testEquipDeviceThird,
                    value: formData ? formData.F18EqType3 : '',
                    options: optionObj,
                    placeholder: testEqPlaceholder,
                    xs: 6,
                    labelWidth: widthLabel,
                    width: inputWidth,
                    borderTop: false
                },
                {
                    key: 'F9FullEqDesc3',
                    label: testEquipDescThird,
                    value: formData ? getValueForDeviceType(distinctDeviceDescTestMakeModelData, formData.F18EqType3, formData.F9FullEqDesc3) : '',
                    options: getOptionsForDeviceType(distinctDeviceDescTestMakeModelData, formData?.F18EqType3),
                    placeholder: testEqDescPlaceholder,
                    xs: 6,
                    marginLeft: '2rem',
                    width: descInputWidthLabel,
                    labelWidth: descWidthLabel,
                    borderTop: false
                },
                {
                    key: 'F181EqType4',
                    label: testEquipDeviceFourth,
                    value: formData ? formData.F181EqType4 : '',
                    options: optionObj,
                    placeholder: testEqPlaceholder,
                    xs: 6,
                    labelWidth: widthLabel,
                    width: inputWidth,
                    borderTop: false
                },
                {
                    key: 'F91fullEqDesc4',
                    label: testEquipDescForth,
                    value: formData ? getValueForDeviceType(distinctDeviceDescTestMakeModelData, formData.F181EqType4, formData.F91fullEqDesc4) : '',
                    options: getOptionsForDeviceType(distinctDeviceDescTestMakeModelData, formData?.F181EqType4),
                    placeholder: testEqDescPlaceholder,
                    xs: 6,
                    marginLeft: '2rem',
                    width: descInputWidthLabel,
                    labelWidth: descWidthLabel,
                    borderTop: false
                },
                {
                    key: 'F182EqType5',
                    label: testEquipDevicefifth,
                    value: formData ? formData.F182EqType5 : '',
                    options: optionObj,
                    placeholder: testEqPlaceholder,
                    xs: 6,
                    labelWidth: widthLabel,
                    width: inputWidth,
                    borderTop: false
                },
                {
                    key: 'F92FullEqDesc5',
                    label: testEquipDescFifth,
                    value: formData ? getValueForDeviceType(distinctDeviceDescTestMakeModelData, formData.F182EqType5, formData.F92FullEqDesc5) : '',
                    options: getOptionsForDeviceType(distinctDeviceDescTestMakeModelData, formData?.F182EqType5),
                    placeholder: testEqDescPlaceholder,
                    xs: 6,
                    marginLeft: '2rem',
                    width: descInputWidthLabel,
                    labelWidth: descWidthLabel,
                    borderTop: false
                }]
            }
        ]
    };
};

export const selectDeviceTypesSectionData = (selectedTag, distinctTestMakeModelData, formName, formData) => {
    const distinctDeviceTypeTestMakeModelData = [];
    const distinctDeviceDescTestMakeModelData = [];
    distinctTestMakeModelData?.filter((data) => data.Status === 'Active').map(item => {
        const findItem = distinctDeviceTypeTestMakeModelData.find(x => x.DeviceType === item.DeviceType);
        if (!findItem) {
            distinctDeviceTypeTestMakeModelData.push(item);
        }
        return true;
    });
    distinctTestMakeModelData.map(item => {
        const findItem = distinctDeviceDescTestMakeModelData.find(x => x.FullEqDesc === item.FullEqDesc);
        if (!findItem) {
            distinctDeviceDescTestMakeModelData.push(item);
        }
        return true;
    });
    let filterViewData = DeviceTypesSectionData(selectedTag, formData, distinctDeviceTypeTestMakeModelData, distinctDeviceDescTestMakeModelData, formName);
    if (formName
        && (['P7-6', 'P6-10', 'C1-2', 'P8-1', 'M6-13', 'M6-16', 'M6-9', 'M3-1', 'P6-11', 'P7-16', 'P7-17', 'P7-23', 'P6-14.2',
            'P6-23.2', 'P7-27', 'P6-15', 'P6-20', 'P7-32', 'P7-31', 'P7-33', 'M7-1', 'P8-14.1', 'P6-13', 'P6-14', 'S7-1', 'M4-2', 'P6-29'].includes(formName))) {
        filterViewData = { rows: [] };
    }
    return filterViewData;
};
