import { createTableDefinitionObject } from '../SubSections/DPS/DPS.helpers';

export const TYPE = {
    NAME: 'NAME'
};

const tableDefinition = [
    createTableDefinitionObject('FormDeviceType', 'Device Type'),
    createTableDefinitionObject('FormManufacturer', 'Manufacturer'),
    createTableDefinitionObject('FormModel', 'Model'),
    createTableDefinitionObject('TestEqSn', 'Serial Number'),
    createTableDefinitionObject('CalDueDate', 'Cal.Due Date'),
    createTableDefinitionObject('Status', 'Status')
];

export const updatedTabledefination = tableDefinition.map((row) => {
    if (row.columnTitle === 'Device Type' || row.columnTitle === 'Status') {
        return {
            ...row,
            displaySort: true
        };
    }
    return row;
}
);
const addEditTestEquipmentFormConstants = {
    ADDTESTEQUIPMENT_SET_INITIALDATA: 'ADDTESTEQUIPMENT_SET_INITIALDATA',
    ADDTESTEQUIPMENT_RESET_DATA: 'ADDTESTEQUIPMENT_RESET_DATA',
    ADDTESTEQUIPMENT_UPDATE_DATA: 'ADDTESTEQUIPMENT_UPDATE_DATA',
    EDITTESTEQUIPMENT_SET_EXISTINGDATA: 'EDITTESTEQUIPMENT_SET_EXISTINGDATA',
    EDITTESTEQUIPMENT_RESET_DATA: 'EDITTESTEQUIPMENT_RESET_DATA',
    EDITTESTEQUIPMENT_UPDATE_DATA: 'EDITTESTEQUIPMENT_UPDATE_DATA',
    UPDATETESTEQUIPMENT_UNSAVED_CHANGES_LABEL: 'UPDATETESTEQUIPMENT_UNSAVED_CHANGES_LABEL'
};

export const TEST_ACTIONS = {
    ADD_TESTEQUIPMENTRECORD_PENDING: 'ADD_TESTEQUIPMENTRECORD_PENDING',
    ADD_TESTEQUIPMENTRECORD_SUCCESS: 'ADD_TESTEQUIPMENTRECORD_SUCCESS',
    ADD_TESTEQUIPMENTRECORD_FAILURE: 'ADD_TESTEQUIPMENTRECORD_FAILURE',
    EDIT_TESTEQUIPMENTRECORD_PENDING: 'EDIT_TESTEQUIPMENTRECORD_PENDING',
    EDIT_TESTEQUIPMENTRECORD_FAILURE: 'EDIT_TESTEQUIPMENTRECORD_FAILURE',
    EDIT_TESTEQUIPMENTRECORD_SUCCESS: 'EDIT_TESTEQUIPMENTRECORD_SUCCESS',
    GET_TEST_RECORDS_COUNT: 'GET_TEST_RECORDS_COUNT',
    RESET_TEST_TOTAL_COUNT: 'RESET_TEST_TOTAL_COUNT',
    GET_SEARCH_TEST_RECORDS_PENDING: 'GET_SEARCH_TEST_RECORDS_PENDING',
    GET_SEARCH_TEST_RECORDS_FAILURE: 'GET_SEARCH_TEST_RECORDS_FAILURE',
    GET_SEARCH_TEST_RECORDS_SUCCESS: 'GET_SEARCH_TEST_RECORDS_SUCCESS',
    GET_SORT_TEST_RECORDS_PENDING: 'GET_SORT_TEST_RECORDS_PENDING',
    GET_SORT_TEST_RECORDS_SUCCESS: 'GET_SORT_TEST_RECORDS_SUCCESS',
    GET_SORT_TEST_RECORDS_FAILURE: 'GET_SORT_TEST_RECORDS_FAILURE',
    DISPLAY_DEVICETYPE_SORTORDER: 'DISPLAY_DEVICETYPE_SORTORDER',
    DISPLAY_STATUS_SORTORDER: 'DISPLAY_STATUS_SORTORDER',
    GET_TEST_MAKE_MODEL_DATA_SUCCESS: 'GET_TEST_MAKE_MODEL_DATA_SUCCESS',
    GET_TEST_MAKE_MODEL_DATA_FAILURE: 'GET_TEST_MAKE_MODEL_DATA_FAILURE',
    ...addEditTestEquipmentFormConstants
};
