import { Dialog, DialogActions, DialogContent } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';

const ColorLedger = ({ handleOnClose }) => {
    const styleColorBox = {
        width: '60px',
        height: '20px',
        border: '1px solid black'
    };

    const styleMessage = {
        fontSize: '14px',
        color: 'black'
    };
    return (
        <Dialog
            open
            onClose={handleOnClose}
            scroll="paper"
            maxWidth="lg"
        >
            {/* <DialogTitle id="scroll-dialog-title" classes={{ root: classes.headerRoot }} className={classes.header}>{title}</DialogTitle> */}
            <DialogContent>
                <div style={{ display: 'grid', gridTemplateColumns: 'repeat(1, 18% 80%)', gap: '20px 10px', alignItems: 'center' }}>
                    <div style={{ ...styleColorBox, backgroundColor: 'rgb(255, 255, 0)' }} />
                    <div style={styleMessage}>Mandatory fields to be filled by user to submit the record</div>
                    <div style={{ ...styleColorBox, backgroundColor: 'rgb(255, 229, 175)' }} />
                    <div style={styleMessage}>Mandatory fields with data</div>
                    <div style={{ ...styleColorBox, backgroundColor: 'rgb(0, 176, 80)' }} />
                    <div style={styleMessage}>Fields that are required on one or many forms</div>
                    <div style={{ ...styleColorBox, backgroundColor: 'rgb(231, 246, 213)' }} />
                    <div style={styleMessage}>Required fields in the form for selected TRF ID</div>
                    <div style={{ ...styleColorBox, backgroundColor: 'rgb(240, 244, 255)' }} />
                    <div style={styleMessage}>Required fields in the form for selected Corrective ID</div>
                    <div style={{ ...styleColorBox, backgroundColor: 'rgb(241, 219, 255)' }} />
                    <div style={styleMessage}>Required fields in the form for selected Inspection ID</div>
                    <div style={{ ...styleColorBox, backgroundColor: 'rgb(232, 232, 232)' }} />
                    <div style={styleMessage}>Read Only Field/Data to be populated from different source</div>
                    <div style={{ ...styleColorBox, backgroundColor: 'rgb(255, 255, 255)' }} />
                    <div style={styleMessage}>Optional fields</div>
                </div>
            </DialogContent>
            <DialogActions />
        </Dialog>

    );
};

export default ColorLedger;

ColorLedger.propTypes = {
    handleOnClose: PropTypes.func
};
