import React, { useCallback, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import PropTypes from 'prop-types';
import { syncMRATData } from 'components/MRATSync/MRATSync';
import offlineStore from 'utils/OfflineDB';
import { useIdleTimer } from 'react-idle-timer';
import { useMsal, useAccount } from '@azure/msal-react';
import { entries } from 'idb-keyval';
import { useHistory } from 'react-router-dom';
import {
  updateMRATSyncInvalidationStatus,
  checkOfflineDataPresent,
  setCurrentView,
  saveUsersGroupDetails,
  disableMRATSyncButton
} from 'journeys/portal/Home/Home.actions';
import { getMratSecurityGroupInfo } from 'journeys/common/common.actions';
import { PublicClientApplication } from '@azure/msal-browser';
import { isMobile as isTablet } from 'react-device-detect';
import P652Form from 'journeys/portal/Forms/Forms/P652Form/P652Form';
import P821Form from 'journeys/portal/Forms/Forms/P821Form/P821Form';
import P861Form from 'journeys/portal/Forms/Forms/P861Form/P861Form';
import P618Form from 'journeys/portal/Forms/Forms/P618Form/P618Form';
import P69Form from 'journeys/portal/Forms/Forms/P69Form/P69Form';
import P615Form from 'journeys/portal/Forms/Forms/P615Form/P615Form';
import { getUserGroups } from 'utils/MsGraphApiCall';
import M613Form from 'journeys/portal/Forms/Forms/M613Form/M613Form';
import M69Form from 'journeys/portal/Forms/Forms/M69Form/M69Form';
import M610Form from 'journeys/portal/Forms/Forms/M610Form/M610Form';
import M611Form from 'journeys/portal/Forms/Forms/M611Form/M611Form';
import M65Form from 'journeys/portal/Forms/Forms/M65Form/M65Form';
import M31Form from 'journeys/portal/Forms/Forms/M31Form/M31Form';
import ErrorPage from 'journeys/common/error';
import M614Form from 'journeys/portal/Forms/Forms/M614Form/M614Form';
import M615Form from 'journeys/portal/Forms/Forms/M615Form/M615Form';
import P871Form from 'journeys/portal/Forms/Forms/P871Form/P871Form';
import P716Form from 'journeys/portal/Forms/Forms/P716Form/P716Form';
import P717Form from 'journeys/portal/Forms/Forms/P717Form/P717Form';
import P721Form from 'journeys/portal/Forms/Forms/P721Form/P721Form';
import P723Form from 'journeys/portal/Forms/Forms/P723Form/P723Form';
import P6142Form from 'journeys/portal/Forms/Forms/P6142Form/P6142Form';
import P614Form from 'journeys/portal/Forms/Forms/P614Form/P614Form';
import P724Form from 'journeys/portal/Forms/Forms/P724Form/P724Form';
import C12Form from 'journeys/portal/Forms/Forms/C12Form/C12Form';
import C13Form from 'journeys/portal/Forms/Forms/C13Form/C13Form';
import C132Form from 'journeys/portal/Forms/Forms/C132Form/C132Form';
import C14Form from 'journeys/portal/Forms/Forms/C14Form/C14Form';
import P741Form from 'journeys/portal/Forms/Forms/P741Form/P741Form';
import P713Form from 'journeys/portal/Forms/Forms/P713Form/P713Form';
import P732Form from 'journeys/portal/Forms/Forms/P732Form/P732Form';
import P733Form from 'journeys/portal/Forms/Forms/P733Form/P733Form';
import P891Form from 'journeys/portal/Forms/Forms/P891Form/P891Form';
import P731Form from 'journeys/portal/Forms/Forms/P731Form/P731Form';
import P8141Form from 'journeys/portal/Forms/Forms/P8141Form/P8141Form';
import { LoginComp } from './journeys/portal/tryLogin/tryLogin';
import HomePage from './journeys/portal/Home/Home';
import DPSPage from './journeys/portal/SubSections/DPS/DPS';
import AddDpsForm from './journeys/portal/SubSections/AddEditDpsForm/AddDpsForm';
import EditDpsScreen from './journeys/portal/SubSections/AddEditDpsForm/EditDpsScreen';
import MOSelectionPage from './journeys/portal/MOSelection/MOSelection';
import TagSelectionMOScreenPage from './journeys/portal/TagSelectionMOScreen/TagSelectionMOScreen';
import AddTestEquipmentForm from './journeys/portal/TestEquipment/AddEditTestEquipmentForm/AddTestEquipmentForm';
import EditTestEquipmentForm from './journeys/portal/TestEquipment/AddEditTestEquipmentForm/EditTestEquipmentForm';
import TestEquipmentPage from './journeys/portal/TestEquipment/TestEquipment';
import M61Form from './journeys/portal/Forms/Forms/M61Form/M61Form';
import M63Form from './journeys/portal/Forms/Forms/M63Form/M63Form';
import M64Form from './journeys/portal/Forms/Forms/M64Form/M64Form';
import M66Form from './journeys/portal/Forms/Forms/M66Form/M66Form';
import M67Form from './journeys/portal/Forms/Forms/M67Form/M67Form';
import M612Form from './journeys/portal/Forms/Forms/M612Form/M612Form';
import M616Form from './journeys/portal/Forms/Forms/M616Form/M616Form';
import P71Form from './journeys/portal/Forms/Forms/P71Form/P71Form';
import P72Form from './journeys/portal/Forms/Forms/P72Form/P72Form';
import P73Form from './journeys/portal/Forms/Forms/P73Form/P73Form';
import P730Form from './journeys/portal/Forms/Forms/P730Form/P730Form';
import P78Form from './journeys/portal/Forms/Forms/P78Form/P78Form';
import P712Form from './journeys/portal/Forms/Forms/P712Form/P712Form';
import P714Form from './journeys/portal/Forms/Forms/P714Form/P714Form';
import P718Form from './journeys/portal/Forms/Forms/P718Form/P718Form';
import P719Form from './journeys/portal/Forms/Forms/P719Form/P719Form';
import P720Form from './journeys/portal/Forms/Forms/P720Form/P720Form';
import P715Form from './journeys/portal/Forms/Forms/P715Form/P715Form';
import P74Form from './journeys/portal/Forms/Forms/P74Form/P74Form';
import P77Form from './journeys/portal/Forms/Forms/P77Form/P77Form';
import P79Form from './journeys/portal/Forms/Forms/P79 Form/P79Form';
import P75Form from './journeys/portal/Forms/Forms/P75Form/P75Form';
import P76Form from './journeys/portal/Forms/Forms/P76Form/P76Form';
import P728Form from './journeys/portal/Forms/Forms/P728Form/P728Form';
import P725Form from './journeys/portal/Forms/Forms/P725Form/P725Form';
import P726Form from './journeys/portal/Forms/Forms/P726Form/P726Form';
import P727Form from './journeys/portal/Forms/Forms/P727Form/P727Form';
import P729Form from './journeys/portal/Forms/Forms/P729Form/P729Form';
import P81Form from './journeys/portal/Forms/Forms/P81Form/P81Form';
import P710Form from './journeys/portal/Forms/Forms/P710Form/P710Form';
import P61Form from './journeys/portal/Forms/Forms/P61Form/P61Form';
import P64Form from './journeys/portal/Forms/Forms/P64Form/P64Form';
import P65Form from './journeys/portal/Forms/Forms/P65Form/P65Form';
import P67Form from './journeys/portal/Forms/Forms/P67Form/P67Form';
import P68Form from './journeys/portal/Forms/Forms/P68Form/P68Form';
import P62Form from './journeys/portal/Forms/Forms/P62Form/P62Form';
import P63Form from './journeys/portal/Forms/Forms/P63Form/P63Form';
import P642Form from './journeys/portal/Forms/Forms/P642Form/P642Form';
import P632Form from './journeys/portal/Forms/Forms/P632Form/P632Form';
import P612Form from './journeys/portal/Forms/Forms/P612Form/P612Form';
import P610Form from './journeys/portal/Forms/Forms/P610Form/P610Form';
import P617Form from './journeys/portal/Forms/Forms/P617Form/P617Form';
import P620Form from './journeys/portal/Forms/Forms/P620Form/P620Form';
import P611Form from './journeys/portal/Forms/Forms/P611Form/P611Form';
import P622Form from './journeys/portal/Forms/Forms/P622Form/P622Form';
import P66Form from './journeys/portal/Forms/Forms/P66Form/P66Form';
import P811Form from './journeys/portal/Forms/Forms/P811Form/P811Form';
import P8101Form from './journeys/portal/Forms/Forms/P8101Form/P8101Form';
import P8111Form from './journeys/portal/Forms/Forms/P8111Form/P8111Form';
import P8121Form from './journeys/portal/Forms/Forms/P8121Form/P8121Form';
import P616Form from './journeys/portal/Forms/Forms/P616Form/P616Form';
import P851Form from './journeys/portal/Forms/Forms/P851Form/P851Form';
import P831Form from './journeys/portal/Forms/Forms/P831Form/P831Form';
import P841Form from './journeys/portal/Forms/Forms/P841Form/P841Form';
import P881Form from './journeys/portal/Forms/Forms/P881Form/P881Form';
import M68Form from './journeys/portal/Forms/Forms/M68Form/M68Form';
import C11Form from './journeys/portal/Forms/Forms/C11Form/C11Form';
import P6012Form from './journeys/portal/Forms/Forms/P6012Form/P6012Form';
import P711Form from './journeys/portal/Forms/Forms/P711Form/P711Form';
import M71Form from './journeys/portal/Forms/Forms/M71Form/M71Form';
import P613Form from './journeys/portal/Forms/Forms/P613Form/P613Form';
import P6022Form from './journeys/portal/Forms/Forms/P6022Form/P6022Form';
import TRFSelection from './journeys/portal/TagSelectionMOScreen/SubSection/TRFSelection';
import UserRoleSelector from './journeys/portal/UserRoleSelector/index';
import { msalConfig } from './authConfig';
import SavedTRF from './journeys/portal/SavedTRFPrintScreen/SavedTRFPrintScreen';
import { getUserRole } from './journeys/portal/UserRoleSelector/UserRole';
import P722Form from 'journeys/portal/Forms/Forms/P722Form/P722Form';
import Info from 'journeys/portal/Home/InformationPage/Info';
import { SplashScreen } from 'elements/SplashScreen';
import P8171Form from 'journeys/portal/Forms/Forms/P8171Form/P8171Form';
import P6232Form from 'journeys/portal/Forms/Forms/P6232Form/P6232Form';
import P8131Form from 'journeys/portal/Forms/Forms/P8131Form/P8131Form';
import S72Form from './journeys/portal/Forms/Forms/S72Form/S72Form';
import S71Form from 'journeys/portal/Forms/Forms/S71Form/S71Form';
import S73Form from 'journeys/portal/Forms/Forms/S73Form/S73Form';
import S74Form from 'journeys/portal/Forms/Forms/S74Form/S74Form';
import M42Form from 'journeys/portal/Forms/Forms/M42Form/M42Form';
import P626Form from 'journeys/portal/Forms/Forms/P626Form/P626Form';
import P629Form from 'journeys/portal/Forms/Forms/P629Form/P629Form';
import P627Form from 'journeys/portal/Forms/Forms/P627Form/P627Form';

// eslint-disable-next-line
const MULTI_ROLE_ERROR = `You have been assigned with multiple roles for MRAT application. \
You are not authorized to access MRAT application with multiple roles. Please contact system 
administrator for single role assignment.`;

const UNAUTHORIZED_ACCESS = 'You do not have access to MRAT application. Please contact system administrator.';

const AppContainer = (props) => {
  const [error, setError] = useState('');
  const { currentView, setCurrentAppView, isMRATOnline,
    updateInvalidationStatus, checkOfflineFormDataPresent, disableSyncButton } = props;
  const dispatch = useDispatch();
  const timeout = 14400000;
  const [userGroups, setUserGroups] = useState([]);
  const {
    getRemainingTime,
  } = useIdleTimer({
    timeout
  });
  const msalInstance = new PublicClientApplication(msalConfig);
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const appInsights = useAppInsightsContext();
  const history = useHistory();

  const handleLogOut = () => {
    const allAccounts = msalInstance.getAllAccounts();
    const logoutRequest = {
      account: allAccounts[0]
    };
    if (logoutRequest.account.username.toLowerCase().includes('bp')) {
      instance.logoutRedirect();
    }
    instance.logoutRedirect(logoutRequest);
  };

  const isUserEligibleForMultiRoleSelection = useCallback((accessibleRoles, prodExclusionUsers) => {
    if (accessibleRoles.length === 1) {
      return false;
    }

    if (process.env.REACT_APP_ENV === 'Prod'
      || process.env.REACT_APP_MULTI_ROLE_LOGIN_SIMULATE_AS_PROD.toLowerCase() === 'true') {
      // check if all roles are read-only roles
      const allViewRoles = accessibleRoles.every(group => group.RoleId === 1 /* Viewer */);
      // if user is in exception list or read-only roles allow for role selection screen
      if ((prodExclusionUsers
        && prodExclusionUsers.some(userId => userId.toLowerCase() === account.username.toLowerCase()))
        || allViewRoles) {
        return true;
      }
    } else if (accessibleRoles.length > 1) {
      return true;
    }

    return false;
  }, [account]);

  const trackAIUserDetails = useCallback((userGroupInfo) => {
    const appInsightUserDetails = {
      userName: account.name,
      groupName: userGroupInfo[0].Name,
      userRole: getUserRole(userGroupInfo[0].RoleId),
      userEmailId: account.username
    };
    appInsights.trackEvent({ name: 'user_Details', properties: { appInsightUserDetails } });
  }, [account, appInsights]);

  const handleUserGroupsData = useCallback((userGroupsData, groups) => {
    // get all group name's and roles
    const userGroupInfo = groups.SecurityGroupInfo
      .filter(group => userGroupsData.includes(group.Id.toLowerCase()));
    // verify if user is eligible for role selection screen
    // else redirect user to home screen directly
    if (isUserEligibleForMultiRoleSelection(userGroupInfo, groups.ExclusionUsers)) {
      setUserGroups(userGroupInfo);
      dispatch(setCurrentView('userRoleSelector'));
    } else if (userGroupsData.length > 1) {
      setError(MULTI_ROLE_ERROR);
      dispatch(setCurrentView('error'));
    } else {
      dispatch(saveUsersGroupDetails(userGroupsData[0]))
        .then(() => {
          trackAIUserDetails(userGroupInfo);
          if (getUserRole(userGroupInfo[0].RoleId) === 'Administrator') {
            dispatch(setCurrentView('admin'));
            history.push('/admin');
          } else {
            dispatch(setCurrentView('home'));
          }
        });
    }
  }, [dispatch, isUserEligibleForMultiRoleSelection, trackAIUserDetails, history]);

  const handleMultiRoleSelection = useCallback(() => {
    getMratSecurityGroupInfo().then((groups) => {
      // get all groupIds to pass to graph api.
      const groupIds = groups.SecurityGroupInfo.map(group => group.Id);
      // call graph api to get user's accessible group ids
      getUserGroups(groupIds)
        .then(userGroupsData => {
          // if user has at least one group access
          if (userGroupsData && userGroupsData.length > 0) {
            // get all group name's and roles
            handleUserGroupsData(userGroupsData, groups);
          } else {
            setError(UNAUTHORIZED_ACCESS);
            dispatch(setCurrentView('error'));
          }
        }).catch(() => {
          setError('');
          dispatch(setCurrentView('error'));
        });
    });
  }, [dispatch, handleUserGroupsData]);

  useEffect(() => {
    if (account && currentView === 'splash') {
      handleMultiRoleSelection();
    }
  }, [account, currentView, dispatch, handleMultiRoleSelection]);

  useEffect(() => {
    const id = !isTablet && setInterval(() => {
      if (getRemainingTime() === 0) {
        handleLogOut();
        clearInterval(id);
      }
    }, 1000);
    return () => clearInterval(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const id = setInterval(() => {
      entries(offlineStore).then((storesOfflineValues) => {
        isMRATOnline && storesOfflineValues.length !== 0 && syncMRATData({
          storesOfflineValues,
          updateInvalidationStatus,
          checkOfflineFormDataPresent,
          setCurrentAppView,
          disableSyncButton
        });
      });
    }, 600000);
    return () => clearInterval(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMRATOnline]);
  if (!isMRATOnline && !isTablet && (process.env.REACT_APP_ENV === 'Prod' || process.env.REACT_APP_ENV === 'Test')) {
    return <div />;
  }
  return (
    <>
      {currentView === 'splash' && <SplashScreen />}
      {currentView === 'error' && <ErrorPage message={error} />}
      {currentView === 'login' && <LoginComp />}
      {currentView === 'userRoleSelector' && userGroups.length > 1
        && <UserRoleSelector userGroups={userGroups} />}
      {currentView === 'home' && <HomePage />}
      {currentView === 'dps' && <DPSPage />}
      {currentView === 'addDpsForm' && <AddDpsForm />}
      {currentView === 'editDpsForm' && <EditDpsScreen />}
      {currentView === 'info' && <Info />}
      {currentView === 'moselection' && <MOSelectionPage />}
      {currentView === 'tagselectionmoscreen' && <TagSelectionMOScreenPage />}
      {currentView === 'testequipment' && <TestEquipmentPage />}
      {currentView === 'addTestEquipmentform' && <AddTestEquipmentForm />}
      {currentView === 'editTestEquipmentform' && <EditTestEquipmentForm />}
      {currentView === 'savedTRF' && <SavedTRF />}
      {currentView === 'NA' && <TRFSelection />}
      {currentView === 'G1-1' && <TRFSelection />}
      {currentView === 'C1-1' && <C11Form />}
      {currentView === 'C1-2' && <C12Form />}
      {currentView === 'C1-3' && <C13Form />}
      {currentView === 'C1-3.2' && <C132Form />}
      {currentView === 'C1-4' && <C14Form />}
      {currentView === 'P6-13.2' && <TRFSelection />}
      {currentView === 'P6-19' && <TRFSelection />}
      {currentView === 'P7-13' && <P713Form />}
      {currentView === 'P7-20' && <P720Form />}
      {currentView === 'P7-21' && <P721Form />}
      {currentView === 'P7-22' && <P722Form />}
      {currentView === 'P7-25' && <P725Form />}
      {currentView === 'P7-26' && <P726Form />}
      {currentView === 'P8-9.1' && <P891Form />}
      {currentView === 'M3-1' && <M31Form />}
      {currentView === 'M6-1' && <M61Form />}
      {currentView === 'M6-2' && <TRFSelection />}
      {currentView === 'M6-3' && <M63Form />}
      {currentView === 'M6-4' && <M64Form />}
      {currentView === 'M6-5' && <M65Form />}
      {currentView === 'M6-6' && <M66Form />}
      {currentView === 'M6-7' && <M67Form />}
      {currentView === 'M6-8' && <M68Form />}
      {currentView === 'M6-9' && <M69Form />}
      {currentView === 'M6-10' && <M610Form />}
      {currentView === 'M6-11' && <M611Form />}
      {currentView === 'M6-12' && <M612Form />}
      {currentView === 'M6-13' && <M613Form />}
      {currentView === 'M6-14' && <M614Form />}
      {currentView === 'M6-15' && <M615Form />}
      {currentView === 'M6-16' && <M616Form />}
      {currentView === 'M7-1' && <M71Form />}
      {currentView === 'P6-1' && <P61Form />}
      {currentView === 'P6-1.2' && <P612Form />}
      {currentView === 'P6-2' && <P62Form />}
      {currentView === 'P6-2.2' && <P622Form />}
      {currentView === 'P6-3' && <P63Form />}
      {currentView === 'P6-3.2' && <P632Form />}
      {currentView === 'P6-4' && <P64Form />}
      {currentView === 'P6-4.2' && <P642Form />}
      {currentView === 'P6-5' && <P65Form />}
      {currentView === 'P6-5.2' && <P652Form />}
      {currentView === 'P6-6' && <P66Form />}
      {currentView === 'P6-7' && <P67Form />}
      {currentView === 'P6-8' && <P68Form />}
      {currentView === 'P6-9' && <P69Form />}
      {currentView === 'P6-10' && <P610Form />}
      {currentView === 'P6-17' && <P617Form />}
      {currentView === 'P6-20' && <P620Form />}
      {currentView === 'P6-11' && <P611Form />}
      {currentView === 'P6-12' && <P6012Form />}
      {currentView === 'P6-13' && <P613Form />}
      {currentView === 'P6-14.2' && <P6142Form />}
      {currentView === 'P6-14' && <P614Form />}
      {currentView === 'P6-23.2' && <P6232Form />}
      {currentView === 'P7-1' && <P71Form />}
      {currentView === 'P7-2' && <P72Form />}
      {currentView === 'P7-3' && <P73Form />}
      {currentView === 'P7-30' && <P730Form />}
      {currentView === 'P7-4' && <P74Form />}
      {currentView === 'P7-5' && <P75Form />}
      {currentView === 'P7-6' && <P76Form />}
      {currentView === 'P7-7' && <P77Form />}
      {currentView === 'P7-8' && <P78Form />}
      {currentView === 'P7-9' && <P79Form />}
      {currentView === 'P7-10' && <P710Form />}
      {currentView === 'P7-12' && <P712Form />}
      {currentView === 'P7-14' && <P714Form />}
      {currentView === 'P7-18' && <P718Form />}
      {currentView === 'P7-16' && <P716Form />}
      {currentView === 'P7-17' && <P717Form />}
      {currentView === 'P7-19' && <P719Form />}
      {currentView === 'P7-23' && <P723Form />}
      {currentView === 'P7-15' && <P715Form />}
      {currentView === 'P7-28' && <P728Form />}
      {currentView === 'P7-29' && <P729Form />}
      {currentView === 'P7-32' && <P732Form />}
      {currentView === 'P7-33' && <P733Form />}
      {currentView === 'P7-34' && <TRFSelection />}
      {currentView === 'P7-35' && <TRFSelection />}
      {currentView === 'P7-11' && <P711Form />}
      {currentView === 'P8-1' && <P81Form />}
      {currentView === 'P8-1.1' && <P811Form />}
      {currentView === 'P8-2.1' && <P821Form />}
      {currentView === 'P8-6.1' && <P861Form />}
      {currentView === 'P8-7.1' && <P871Form />}
      {currentView === 'P8-8.1' && <P881Form />}
      {currentView === 'P8-5.1' && <P851Form />}
      {currentView === 'P8-3.1' && <P831Form />}
      {currentView === 'P8-4.1' && <P841Form />}
      {currentView === 'P8-10.1' && <P8101Form />}
      {currentView === 'P8-11.1' && <P8111Form />}
      {currentView === 'P8-12.1' && <P8121Form />}
      {currentView === 'P8-13.1' && <P8131Form />}
      {currentView === 'P8-14.1' && <P8141Form />}
      {currentView === 'P8-17.1' && <P8171Form />}
      {currentView === 'P6-16' && <P616Form />}
      {currentView === 'P7-24' && <P724Form />}
      {currentView === 'P7-27' && <P727Form />}
      {currentView === 'P7-4.1' && <P741Form />}
      {currentView === 'P7-31' && <P731Form />}
      {currentView === 'P6-15' && <P615Form />}
      {currentView === 'P6-18' && <P618Form />}
      {currentView === 'P6-22' && <P6022Form />}
      {currentView === 'S7-1' && <S71Form />}
      {currentView === 'S7-2' && <S72Form />}
      {currentView === 'S7-3' && <S73Form />}
      {currentView === 'S7-4' && <S74Form />}
      {currentView === 'M4-2' && <M42Form />}
      {currentView === 'P6-26' && <P626Form />}
      {currentView === 'P6-29' && <P629Form />}
      {currentView === 'P6-27' && <P627Form />}
    </>
  );
};

const mapStateToProps = ({ Home, AppData }) => ({
  currentView: Home.currentView,
  isMRATOnline: AppData.isMRATOnline
});

const mapDispatchToProps = dispatch => ({
  dispatch,
  setCurrentAppView: (view) => dispatch(setCurrentView(view)),
  updateInvalidationStatus: (localState) => dispatch(updateMRATSyncInvalidationStatus(localState)),
  checkOfflineFormDataPresent: () => dispatch(checkOfflineDataPresent()),
  disableSyncButton: (status) => dispatch(disableMRATSyncButton(status))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AppContainer);

AppContainer.propTypes = {
  checkOfflineFormDataPresent: PropTypes.func,
  currentView: PropTypes.string,
  disableSyncButton: PropTypes.func,
  isMRATOnline: PropTypes.bool,
  setCurrentAppView: PropTypes.func,
  updateInvalidationStatus: PropTypes.func
};
