import axios from 'axios';
import { APIEndpoints } from 'helpers/APILists';
import { TAGS_ACTIONS } from './TagSelectionMOScreen.constants';
import { sortFunction } from '../SubSections/DPS/DPS.helpers';
import { setCurrentView } from '../Home/Home.actions';
import { MESSAGE_TYPE } from '../../common/common.constants';

export const setFilters = () => (dispatch, getState) => {
    const { TagSelectionMOScreen: { selectedRowTags, flocSearchString } } = getState();
    const tagSelectionFilters = {
        DeckHullBldg: [...new Set(selectedRowTags.map((row) => row.DeckHullBldg))],
        Quad: [...new Set(selectedRowTags.map((row) => row.Quad))],
        Level: [...new Set(selectedRowTags.map((row) => row.Level))],
        Room: [...new Set(selectedRowTags.map((row) => row.Room))],
        Location2: [...new Set(selectedRowTags.map((row) => row.Location2))]
    };
    dispatch({
        type: TAGS_ACTIONS.LOAD_FILTERS,
        payload: {
            testStatusFilter: 'All',
            tagSelectionMOScreenFilters: tagSelectionFilters,
            clearFilters: true,
            selectedRowTagsTableData: selectedRowTags,
            selectedTagSelectionMOScreenFilters: { DeckHullBldg: '', Quad: '', Level: '', Room: '', Location2: '' },
        }
    });
    !flocSearchString && dispatch({
        type: TAGS_ACTIONS.GET_TAGRECORDS_TOTALCOUNT,
        payload: {
            totalCount: selectedRowTags.length
        }
    });
};

export const deSelectFilters = (filter) => (dispatch, getState) => {
    const { TagSelectionMOScreen: { selectedTagSelectionMOScreenFilters } } = getState();
    let selectedFilters = { ...selectedTagSelectionMOScreenFilters };
    if (filter.DeckHullBldg) {
        selectedFilters = { ...selectedFilters, DeckHullBldg: '', Quad: '', Level: '', Room: '', Location2: '' };
    } else if (filter.Quad) {
        selectedFilters = { ...selectedFilters, Quad: '', Level: '', Room: '', Location2: '' };
    } else if (filter.Level) {
        selectedFilters = { ...selectedFilters, Level: '', Room: '', Location2: '' };
    } else if (filter.Room) {
        selectedFilters = { ...selectedFilters, Room: '', Location2: '' };
    } else if (filter.Location2) {
        selectedFilters = { ...selectedFilters, Location2: '' };
    }
    dispatch({
        type: TAGS_ACTIONS.DESELECT_FILTERS,
        payload: {
            clearFilters: false,
            selectedTagSelectionMOScreenFilters: selectedFilters
        }
    });
    dispatch(getFilteredData(filter));
};

export const setCheckBoxFilters = (filter) => (dispatch, getState) => {
    const { TagSelectionMOScreen: { selectedTagSelectionMOScreenFilters } } = getState();
    let selectedFilters = { ...selectedTagSelectionMOScreenFilters };
    if (filter.DeckHullBldg) {
        selectedFilters = { DeckHullBldg: '', Quad: '', Level: '', Room: '', Location2: '' };
    } else if (filter.Quad) {
        selectedFilters = { ...selectedFilters, ...filter, Quad: '', Level: '', Room: '', Location2: '' };
    } else if (filter.Level) {
        selectedFilters = { ...selectedFilters, ...filter, Level: '', Room: '', Location2: '' };
    } else if (filter.Room) {
        selectedFilters = { ...selectedFilters, ...filter, Room: '', Location2: '' };
    } else if (filter.Location2) {
        selectedFilters = { ...selectedFilters, ...filter, Location2: '' };
    }
    dispatch({
        type: TAGS_ACTIONS.SET_CHECKBOX_FILTERS,
        payload: {
            clearFilters: false,
            selectedTagSelectionMOScreenFilters: selectedFilters
        }
    });
    dispatch(getFilteredData(filter));
};

export const setTestStatusFilters = (filter) => async (dispatch, getState) => {
    const { MOSelection: { selectedRow } } = getState();
    await dispatch(getUpdatedSelectedTagsForTagMOScreenData(selectedRow));
    const { TagSelectionMOScreen: { selectedRowTags, selectedTagSelectionMOScreenFilters } } = getState();
    let selectedRowTagsTableData = [];
    if (filter === 'All') {
        selectedRowTagsTableData = selectedRowTags;
    } else {
        selectedRowTagsTableData = filter === 'Tested' ? selectedRowTags.filter((item) => item.DateTested) : selectedRowTags.filter((item) => !item.DateTested);
    }
    const tagSelectionFilters = {
        DeckHullBldg: [...new Set(selectedRowTagsTableData.map((row) => row.DeckHullBldg))],
        Quad: [...new Set(selectedRowTagsTableData.map((row) => row.Quad))],
        Level: [...new Set(selectedRowTagsTableData.map((row) => row.Level))],
        Room: [...new Set(selectedRowTagsTableData.map((row) => row.Room))],
        Location2: [...new Set(selectedRowTagsTableData.map((row) => row.Location2))]
    };
    dispatch({
        type: TAGS_ACTIONS.SET_TESTSTATUS_FILTERS,
        payload: {
            clearFilters: false,
            totalCount: selectedRowTagsTableData.length,
            selectedRowTagsTableData,
            testStatusFilter: filter,
            tagSelectionMOScreenFilters: tagSelectionFilters,
        }
    });
    for (const key in selectedTagSelectionMOScreenFilters) {
        if (selectedTagSelectionMOScreenFilters[key]) {
            dispatch(getTestStatusFilteredData());
            break;
        }
    }
};

export const setSelectedFilters = (filter) => (dispatch, getState) => {
    const { TagSelectionMOScreen: { selectedTagSelectionMOScreenFilters } } = getState();
    let selectedFilters = { ...selectedTagSelectionMOScreenFilters };
    if (filter.DeckHullBldg) {
        selectedFilters = { ...selectedFilters, ...filter, Quad: '', Level: '', Room: '', Location2: '' };
    } else if (filter.Quad) {
        selectedFilters = { ...selectedFilters, ...filter, Level: '', Room: '', Location2: '' };
    } else if (filter.Level) {
        selectedFilters = { ...selectedFilters, ...filter, Room: '', Location2: '' };
    } else if (filter.Room) {
        selectedFilters = { ...selectedFilters, ...filter, Location2: '' };
    } else if (filter.Location2) {
        selectedFilters = { ...selectedFilters, ...filter };
    }
    dispatch({
        type: TAGS_ACTIONS.SET_FILTERS,
        payload: {
            clearFilters: false,
            selectedTagSelectionMOScreenFilters: selectedFilters
        }
    });
    dispatch(getFilteredData(filter));
};

const notTestedFilterData = (item, selectedTagSelectionMOScreenFilters) => {
    if (new Set(Object.values(selectedTagSelectionMOScreenFilters)).size === 1 && Object.values(selectedTagSelectionMOScreenFilters)[0] === '') {
        if (item.DateTested) {
            return false;
        }
    } else {
        for (const key in selectedTagSelectionMOScreenFilters) {
            if (Object.prototype.hasOwnProperty.call(selectedTagSelectionMOScreenFilters, key)
                && selectedTagSelectionMOScreenFilters[key]
                && (item.DateTested || item[key].toLowerCase() !== selectedTagSelectionMOScreenFilters[key].toLowerCase())) {
                return false;
            }
        }
    }
    return true;
};
const testedFilterData = (item, selectedTagSelectionMOScreenFilters) => {
    if (new Set(Object.values(selectedTagSelectionMOScreenFilters)).size === 1 && Object.values(selectedTagSelectionMOScreenFilters)[0] === '') {
        if (!item.DateTested) {
            return false;
        }
    } else {
        for (const key in selectedTagSelectionMOScreenFilters) {
            if (Object.prototype.hasOwnProperty.call(selectedTagSelectionMOScreenFilters, key)
                && selectedTagSelectionMOScreenFilters[key]
                && (!item.DateTested || item[key].toLowerCase() !== selectedTagSelectionMOScreenFilters[key].toLowerCase())) {
                return false;
            }
        }
    }
    return true;
};
const allFilterData = (item, selectedTagSelectionMOScreenFilters) => {
    for (const key in selectedTagSelectionMOScreenFilters) {
        if (Object.prototype.hasOwnProperty.call(selectedTagSelectionMOScreenFilters, key)
            && selectedTagSelectionMOScreenFilters[key]
            && item[key].toLowerCase() !== selectedTagSelectionMOScreenFilters[key].toLowerCase()) {
            return false;
        }
    }
    return true;
};

const getFilteredData = (filter) => async (dispatch, getState) => {
    const { MOSelection: { selectedRow } } = getState();
    await dispatch(getUpdatedSelectedTagsForTagMOScreenData(selectedRow));
    const { TagSelectionMOScreen: { selectedRowTags, tagSelectionMOScreenFilters, selectedTagSelectionMOScreenFilters, testStatusFilter } } = getState();
    let tableData = [];
    let tagSelectionFilters = tagSelectionMOScreenFilters;
    if (testStatusFilter === 'Not Tested') {
        tableData = selectedRowTags.filter((item) => notTestedFilterData(item, selectedTagSelectionMOScreenFilters));
    } else if (testStatusFilter === 'Tested') {
        tableData = selectedRowTags.filter((item) => testedFilterData(item, selectedTagSelectionMOScreenFilters));
    } else if (testStatusFilter === 'All') {
        tableData = selectedRowTags.filter((item) => allFilterData(item, selectedTagSelectionMOScreenFilters));
    }
    if (filter.DeckHullBldg) {
        tagSelectionFilters = {
            ...tagSelectionMOScreenFilters,
            Quad: [...new Set(tableData.map((row) => row.Quad))],
            Level: [...new Set(tableData.map((row) => row.Level))],
            Room: [...new Set(tableData.map((row) => row.Room))],
            Location2: [...new Set(tableData.map((row) => row.Location2))]
        };
    } else if (filter.Quad) {
        tagSelectionFilters = {
            ...tagSelectionMOScreenFilters,
            Level: [...new Set(tableData.map((row) => row.Level))],
            Room: [...new Set(tableData.map((row) => row.Room))],
            Location2: [...new Set(tableData.map((row) => row.Location2))]
        };
    } else if (filter.Level) {
        tagSelectionFilters = {
            ...tagSelectionMOScreenFilters,
            Room: [...new Set(tableData.map((row) => row.Room))],
            Location2: [...new Set(tableData.map((row) => row.Location2))]
        };
    } else if (filter.Room) {
        tagSelectionFilters = {
            ...tagSelectionMOScreenFilters,
            Location2: [...new Set(tableData.map((row) => row.Location2))]
        };
    }
    dispatch({
        type: TAGS_ACTIONS.GET_SELECTEDROWTAGS,
        payload: {
            clearFilters: false,
            totalCount: tableData.length,
            selectedRowTagsTableData: tableData,
            tagSelectionMOScreenFilters: tagSelectionFilters,
        }
    });
};

export const getTestStatusFilteredData = () => async (dispatch, getState) => {
    const { MOSelection: { selectedRow } } = getState();
    await dispatch(getUpdatedSelectedTagsForTagMOScreenData(selectedRow));
    const { TagSelectionMOScreen: { selectedRowTags, selectedTagSelectionMOScreenFilters, flocSearchString, testStatusFilter, tagSelectionMOScreenFilters } } = getState();
    let tableData = [];
    let tagSelectionFilters = tagSelectionMOScreenFilters;
    if (testStatusFilter === 'Not Tested') {
        tableData = selectedRowTags.filter((item) => notTestedFilterData(item, selectedTagSelectionMOScreenFilters));
    } else if (testStatusFilter === 'Tested') {
        tableData = selectedRowTags.filter((item) => testedFilterData(item, selectedTagSelectionMOScreenFilters));
    } else if (testStatusFilter === 'All') {
        tableData = selectedRowTags.filter((item) => allFilterData(item, selectedTagSelectionMOScreenFilters));
    }
    for (const key in selectedTagSelectionMOScreenFilters) {
        if (selectedTagSelectionMOScreenFilters[key] && key === 'DeckHullBldg') {
            tagSelectionFilters = {
                ...tagSelectionMOScreenFilters,
                Quad: [...new Set(tableData.map((row) => row.Quad))],
                Level: [...new Set(tableData.map((row) => row.Level))],
                Room: [...new Set(tableData.map((row) => row.Room))],
                Location2: [...new Set(tableData.map((row) => row.Location2))]
            };
            break;
        } else if (selectedTagSelectionMOScreenFilters[key] && key === 'Quad') {
            tagSelectionFilters = {
                ...tagSelectionMOScreenFilters,
                Level: [...new Set(tableData.map((row) => row.Level))],
                Room: [...new Set(tableData.map((row) => row.Room))],
                Location2: [...new Set(tableData.map((row) => row.Location2))]
            };
            break;
        } else if (selectedTagSelectionMOScreenFilters[key] && key === 'Level') {
            tagSelectionFilters = {
                ...tagSelectionMOScreenFilters,
                Room: [...new Set(tableData.map((row) => row.Room))],
                Location2: [...new Set(tableData.map((row) => row.Location2))]
            };
            break;
        } else if (selectedTagSelectionMOScreenFilters[key] && key === 'Room') {
            tagSelectionFilters = {
                ...tagSelectionMOScreenFilters,
                Location2: [...new Set(tableData.map((row) => row.Location2))]
            };
            break;
        }
    }
    dispatch({
        type: TAGS_ACTIONS.GET_TESTSTATUS_SELECTEDROWTAGS,
        payload: {
            selectedRowTagsTableData: tableData,
            tagSelectionMOScreenFilters: tagSelectionFilters
        }
    });
    !flocSearchString && dispatch({
        type: TAGS_ACTIONS.GET_TAGRECORDS_TOTALCOUNT,
        payload: {
            totalCount: tableData.length,
        }
    });
};
const selectedRowTagsFilters = (res) => res.data.map((item) => ({
    ...item,
    DeckHullBldg: item.DeckHullBldg === '' ? ' ' : item.DeckHullBldg,
    Quad: item.Quad === '' ? ' ' : item.Quad,
    Level: item.Level === '' ? ' ' : item.Level,
    Room: item.Room === '' ? ' ' : item.Room,
    Location2: item.Location2 === '' ? ' ' : item.Location2
}));

export const getUpdatedSelectedTagsForTagMOScreenData = ({ HdWorkOrder }) => async (dispatch, getState) => {
    const { AppData: { isMRATOnline }, Home: { selectedAssetName, selectedBarrierType, regionName, selectedWorkType },
    TagSelectionMOScreen: { sortHdLocationAscOrder } } = getState();
    const filterData = { Asset: selectedAssetName, Region: regionName, WorkType: selectedWorkType, BarrierType: selectedBarrierType, MO: HdWorkOrder };
    if (isMRATOnline) {
        dispatch({
            type: TAGS_ACTIONS.GET_TAGRECORDS_PENDING,
            payload: {
                tableLoading: true
            }
        });
        await axios.post(APIEndpoints.GetVwSearchTag, filterData)
            .then(async (res) => {
                const selectedRowTags = selectedRowTagsFilters(res);
                if (selectedRowTags && selectedRowTags.length > 0) {
                    await dispatch({
                        type: TAGS_ACTIONS.GET_TAGRECORDS_SUCCESS,
                        payload: {
                            tableLoading: false,
                            selectedRowTags
                        }
                    });
                    await dispatch(sortTags(selectedRowTags, 'HdLocation', sortHdLocationAscOrder));
                } else {
                    await dispatch(getUpdatedAllTagBiSelectionMOScreenData({ HdWorkOrder }));
                }
                return true;
            });
    }
};

export const getUpdatedAllTagBiSelectionMOScreenData = ({ HdWorkOrder }) => async (dispatch, getState) => {
    const { AppData: { isMRATOnline }, Home: { selectedAssetName, selectedBarrierType, regionName, selectedWorkType },
     TagSelectionMOScreen: { sortHdLocationAscOrder } } = getState();
    const filterData = { Asset: selectedAssetName, Region: regionName, WorkType: selectedWorkType, BarrierType: selectedBarrierType, MO: HdWorkOrder };
    if (isMRATOnline) {
        dispatch({
            type: TAGS_ACTIONS.GET_TAGRECORDS_PENDING,
            payload: {
                tableLoading: true
            }
        });
        await axios.post(APIEndpoints.GetVwSearchTagBi, filterData)
            .then(async (res) => {
                const selectedRowTags = selectedRowTagsFilters(res);
                if (selectedRowTags && selectedRowTags.length > 0) {
                    await dispatch({
                        type: TAGS_ACTIONS.GET_TAGRECORDS_SUCCESS,
                        payload: {
                            tableLoading: false,
                            selectedRowTags
                        }
                    });
                    await dispatch(sortTags(selectedRowTags, 'HdLocation', sortHdLocationAscOrder));
                } else {
                    await dispatch({
                        type: TAGS_ACTIONS.GET_TAGRECORDS_SUCCESS,
                        payload: {
                            tableLoading: false,
                        }
                    });
                }
                return true;
            });
    }
};

export const getSelectedTagsForTagMOScreenData = ({ HdWorkOrder }) => async (dispatch, getState) => {
    const { AppData: { isMRATOnline }, Home: { selectedAssetName, selectedBarrierType, regionName, selectedWorkType }, TagSelectionMOScreen: { flocSearchString } } = getState();
    const filterData = { Asset: selectedAssetName, Region: regionName, WorkType: selectedWorkType, BarrierType: selectedBarrierType, MO: HdWorkOrder };
    if (isMRATOnline) {
        dispatch({
            type: TAGS_ACTIONS.GET_TAGRECORDS_PENDING,
            payload: {
                tableLoading: true,
                flocSearchString: ''
            }
        });
        await axios.post(APIEndpoints.GetVwSearchTag, filterData)
            .then((res) => {
                const selectedRowTags = selectedRowTagsFilters(res);
                if (selectedRowTags && selectedRowTags.length > 0) {
                    const totalCount = selectedRowTags.length;
                    dispatch({
                        type: TAGS_ACTIONS.GET_TAGRECORDS_SUCCESS,
                        payload: {
                            flocSearchString: '',
                            tableLoading: false,
                            selectedRowTags
                        }
                    });
                    !flocSearchString && dispatch({
                        type: TAGS_ACTIONS.GET_TAGRECORDS_TOTALCOUNT,
                        payload: {
                            totalCount
                        }
                    });
                    dispatch(setFilters());
                } else {
                    // IF NO DATA then get data from the second url
                    dispatch(getAllTagBiSelectionMOScreenData({ HdWorkOrder }));
                }
                dispatch(setCurrentView('tagselectionmoscreen'));
                return true;
            });
        // maybe add a catch here with network status check and go OFFLINE here
    } else {
        // THIS IS OFFLINE MODE
        // If it does not find anything in vwsearchtags API (tags state) it goes to vwsearchtagsbi API (tagsBi state)
        dispatch(getAllOfflineTagSelectionMOScreenData({ HdWorkOrder }));
    }
};

export const getAllOfflineTagSelectionMOScreenData = ({ HdWorkOrder }) => async (dispatch, getState) => {
    const { Home: { tags, tagsBi }, TagSelectionMOScreen: { flocSearchString } } = getState();
    let data = tags?.filter(x => x.HdWorkOrder === HdWorkOrder) || [];
    if (data.length === 0) {
        data = tagsBi?.filter(x => x.HdWorkOrder === HdWorkOrder) || [];
    }
    const totalCount = data.length;
    dispatch({
        type: TAGS_ACTIONS.GET_TAGRECORDS_SUCCESS,
        payload: {
            flocSearchString: '',
            tableLoading: false,
            selectedRowTags: data
        }
    });
    !flocSearchString && dispatch({
        type: TAGS_ACTIONS.GET_TAGRECORDS_TOTALCOUNT,
        payload: {
            totalCount
        }
    });
    dispatch(setFilters());
    dispatch(setCurrentView('tagselectionmoscreen'));
};

export const getAllTagBiSelectionMOScreenData = ({ HdWorkOrder }) => async (dispatch, getState) => {
    const { AppData: { isMRATOnline }, Home: { selectedAssetName, selectedBarrierType, regionName, selectedWorkType }, TagSelectionMOScreen: { flocSearchString } } = getState();
    const filterData = { Asset: selectedAssetName, Region: regionName, WorkType: selectedWorkType, BarrierType: selectedBarrierType, MO: HdWorkOrder };
    if (isMRATOnline) {
        dispatch({
            type: TAGS_ACTIONS.GET_TAGRECORDS_PENDING,
            payload: {
                tableLoading: true
            }
        });
        await axios.post(APIEndpoints.GetVwSearchTagBi, filterData)
            .then((res) => {
                const selectedRowTags = selectedRowTagsFilters(res);
                // IF NO DATA then get data from the second url
                if (selectedRowTags && selectedRowTags.length > 0) {
                    const totalCount = selectedRowTags.length;
                    dispatch({
                        type: TAGS_ACTIONS.GET_TAGRECORDS_SUCCESS,
                        payload: {
                            flocSearchString: '',
                            tableLoading: false,
                            selectedRowTags
                        }
                    });
                    !flocSearchString && dispatch({
                        type: TAGS_ACTIONS.GET_TAGRECORDS_TOTALCOUNT,
                        payload: {
                            totalCount
                        }
                    });
                    dispatch(setFilters());
                    dispatch(setCurrentView('tagselectionmoscreen'));
                } else {
                    dispatch({
                        type: TAGS_ACTIONS.GET_TAGRECORDS_SUCCESS,
                        payload: {
                            tableLoading: false,
                        }
                    });
                }
                return true;
            });
        // maybe add a catch here with network status check and go OFFLINE here
    } else {
        // THIS IS OFFLINE MODE
        // If it does not find anything in vwsearchtags API (tags state) it goes to vwsearchtagsbi API (tagsBi state)
        dispatch(getAllOfflineTagSelectionMOScreenData({ HdWorkOrder }));
    }
};

export const setHdLocationSortOrder = (columnName) => (dispatch, getState) => {
    const { TagSelectionMOScreen: { sortHdLocationAscOrder, selectedRowTags, selectedRowTagsTableData, clearFilters } } = getState();
    dispatch({
        type: TAGS_ACTIONS.HDLOCATION_SORTORDER,
        payload: {
            sortHdLocationAscOrder: !sortHdLocationAscOrder,
        }
    });
    if (!clearFilters) {
        dispatch(sortTags(selectedRowTagsTableData, columnName, !sortHdLocationAscOrder));
    } else {
        dispatch(sortTags(selectedRowTags, columnName, !sortHdLocationAscOrder));
    }
};

export const sortTags = (tagsData, colForSorting, sortAscOrder) => (dispatch, getState) => {
    dispatch({
        type: TAGS_ACTIONS.GET_SORTTAGRECORDS_PENDING,
        payload: {
            tableLoading: true
        }
    });
    const { TagSelectionMOScreen: { clearFilters } } = getState();
    sortFunction(tagsData, colForSorting, sortAscOrder)
        .then(sortedData => {
            !clearFilters ? dispatch({
                type: TAGS_ACTIONS.GET_SORTTAGRECORDS_SUCCESS,
                payload: {
                    tableLoading: false,
                    selectedRowTagsTableData: sortedData
                }
            }) : dispatch({
                type: TAGS_ACTIONS.GET_SORTTAGRECORDS_SUCCESS,
                payload: {
                    tableLoading: false,
                    selectedRowTags: sortedData
                }
            });
        }
        )
        .catch(error => {
            dispatch({
                type: TAGS_ACTIONS.GET_SORTTAGRECORDS_FAILURE,
                payload: {
                    tableLoading: false,
                },
                meta: { messageType: MESSAGE_TYPE.ERROR, message: error.message, exception: error }
            });
        }
        );
};

function searchFLOCFunction(searchVal = '', searchSpace = []) {
    return new Promise((resolve, reject) => {
        try {
            if (searchVal === '') {
                resolve(searchSpace);
            } else {
                const filteredData = searchSpace.filter(row => row.HdLocation?.toLowerCase().includes(searchVal?.toLowerCase()));
                resolve(filteredData);
            }
        } catch (e) {
            reject(e);
        }
    }
    );
}

export const searchFLOC = (searchVal, hdWorkOrder) => async (dispatch, getState) => {
    const { TagSelectionMOScreen: { selectedRowTags, clearFilters, selectedRowTagsTableData } } = getState();
    clearFilters && await dispatch(getUpdatedSelectedTagsForTagMOScreenData({ HdWorkOrder: hdWorkOrder }));
    await dispatch({
        type: TAGS_ACTIONS.GET_SEARCHTAGRECORDS_PENDING,
        payload: {
            tableLoading: true,
            flocSearchString: searchVal
        }
    });

    const searchSpace = clearFilters ? selectedRowTags : selectedRowTagsTableData;
    searchFLOCFunction(searchVal, searchSpace)
        .then(searchedData => {
            const totalCount = searchedData.length;
            dispatch({
                type: TAGS_ACTIONS.GET_SEARCHTAGRECORDS_SUCCESS,
                payload: {
                    tableLoading: false,
                    searchedRowTagsTableData: searchedData,
                    totalCount
                }
            });
        }
        )
        .catch(error => {
            dispatch({
                type: TAGS_ACTIONS.GET_SEARCHTAGRECORDS_FAILURE,
                payload: {
                    tableLoading: false,
                },
                meta: { messageType: MESSAGE_TYPE.ERROR, message: error.message, exception: error }
            });
        }
        );
};
