import { test2Result, test3Result, asLeftTestResultFormula } from '../AsLeftIP_TransmitterSection.formulas';
import { disableTestSection } from '../../ALT_SolenoidLimitSection/AsLeftSolenoidLimitSection.data';
import { conditionalValueSelector } from 'helpers/Validation';
import { commonConstants } from 'journeys/portal/Forms/Sections.constants';

export const asLeftIPTransmitterTestResultData = (selectedTag, formData) => {
    const disableTest2Key = formData.F900AltTest2IpPositioner;
    const disableTest3Key = formData.F920AltTest3PositionTransmitter;
    const disableField = (formData.F61AsFoundResult === commonConstants.Pass_Result || formData.F619AsLeftTestRequired === commonConstants.No);
    return {
        key: 12,
        fields: [{
            key: 'Nodata',
            isLabel: 'true',
            xs: 4,
        },
        {
            key: 'F919AltTest2ResultLabel',
            value: 'Test 2 Result',
            xs: 2,
            width: '96%',
            labelWidth: '0',
            height: '1.2rem',
            textAlign: 'right',
            borderRight: '0',
            isLabelNew: true,
            borderTop: '0',
            readOnly: true,
            borderBottom: '0',
            borderLeft: '0',
        },
        {
            key: 'F919AltTest2Result',
            value: test2Result(formData),
            isDisabled: disableTestSection(disableTest2Key, disableField),
            xs: 2,
            width: '100%',
            labelWidth: '0',
            readOnly: true,
            height: '1.2rem',
            textAlign: 'center',
            borderTop: '0',
            isCalculatedNew: true,
        },
        {
            key: 'F939AltTest3ResultLabel',
            value: 'Test 3 Result',
            xs: 2,
            width: '96%',
            labelWidth: '0',
            height: '1.2rem',
            borderLeft: '0',
            borderRight: '0',
            textAlign: 'right',
            isLabelNew: true,
            readOnly: true,
            borderTop: '0',
            borderBottom: '0',
        },
        {
            key: 'F939AltTest3Result',
            value: test3Result(formData),
            isDisabled: disableTestSection(disableTest3Key, disableField),
            xs: 2,
            width: '100%',
            labelWidth: '0',
            height: '1.2rem',
            readOnly: true,
            textAlign: 'center',
            isCalculatedNew: true,
            borderRight: '0',
            borderTop: '0'
        },
        {
            key: 'Nodata',
            isLabel: 'true',
            xs: 8,
            marginBottom: '3.5px'
        },
        {
            key: 'F81AsLeftResultLabel',
            value: 'As-Left Result',
            width: '96%',
            readOnly: true,
            labelWidth: '0',
            height: '1.1rem',
            xs: 2,
            borderRight: '0',
            borderBottom: '0',
            borderLeft: '0',
            borderTop: '0',
            isLabelBold: true,
            textAlign: 'right',
            marginTop: '20px',
            marginBottom: '4px',
        },
        {
            key: 'F81AsLeftResult',
            value: conditionalValueSelector(disableField, '', asLeftTestResultFormula(formData)),
            readOnly: true,
            isDisabled: disableField,
            isCalculatedNew: true,
            xs: 2,
            labelWidth: '0',
            width: '100%',
            textAlign: 'center',
            height: '1.1rem',
            borderRight: '0',
            borderBottom: '0',
            marginTop: '20px',
            marginBottom: '4px',
        }]
    };
};
