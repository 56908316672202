import styled from 'styled-components';

export const SelectInput = styled.select`
font-size: 13pt;
color: rgb(51, 51, 51);
font-weight: normal;
font-style: normal;
text-align: left;
line-height: 1.2;
padding: 18px 5px 18px 5px;
min-width: 90%;
background-color: rgb(255, 255, 255);
border-style: solid;
border-width: 2px;
border-color: rgb(166, 166, 166);
border-radius:0;
-webkit-border-radius:0;
-webkit-appearance:none;

resize:none;
outline:0;
overflow-y:auto;
margin:0;

&:focus {
    border: 4px solid rgb(166, 166, 166) !important;
    margin:-2px;
}

&:hover {
    border: 2px solid rgb(51, 91, 3);
    background-color: rgb(212, 212, 212);
}

&:active {
    border: 4px solid rgb(166, 166, 166) !important;
    margin:-2px;
}
`;
