import axios from 'axios';
import DataGridComponent from 'components/DataGrid/DataGridComponent';
import React, { useEffect, useState } from 'react';
import {
    adfPipelineParameterHeaderData, adfPipelineParameterApiEndpoint,
    adfPipelineParameterSearchState,
    adfPipelineParameterInitialState
} from './AdfPipelineParameter.data';
import AdfPipelineParameterForm from './AdfPipelineParameterForm';
import { MESSAGE_TYPE } from 'journeys/common/common.constants';
import { trackErrors } from '../helper';
import { APIEndpoints } from 'helpers/APILists';

const addTitle = 'Add New Record for ADF Pipeline Parameter';
const editTitle = 'Edit Record for ADF Pipeline Parameter';

const AdfPipelineParameter = () => {
    const apiEndpoints = adfPipelineParameterApiEndpoint;
    const [showModal, setShowModal] = useState(false);
    const [editRowData, setEditRowData] = useState(adfPipelineParameterInitialState);
    const [modalTitle, setModalTitle] = useState(addTitle);
    const [refreshDatagrid, setRefreshDatagrid] = useState(false);
    const [rowDetails, setRowDetails] = useState({
        columnName: 'AdfPipelineParameterId',
        rowNumber: ''
    });
    const [dropdownsData, setDropdownsData] = useState({
        paramDataTypeOptions: []
    });

    useEffect(() => {
        axios.get(APIEndpoints.ADMIN_GetAdfPipelineParameter)
            .then((res) => {
                setDropdownsData(prev => ({
                    ...prev,
                    paramDataTypeOptions: [...new Set(res.data.filter(y => y.Status === 'Active').map(x => x.ParamDataType))]
                }));
            })
            .catch((err) => {
                trackErrors({
                    messageType: MESSAGE_TYPE.ERROR,
                    message: err.message,
                    exception: err
                });
            });
    }, []);

    const handleEditRecord = (obj) => {
        const rowData = obj.row;
        setModalTitle(editTitle);
        setShowModal(true);
        setEditRowData((prev) => ({
            ...prev,
            ...rowData
        }));
    };
    const handleAddRecord = () => {
        setEditRowData(adfPipelineParameterInitialState);
        setModalTitle(addTitle);
        setShowModal(true);
    };

    const handleModalClose = () => {
        setShowModal(false);
    };

    const handleRecordSubmit = (newId) => {
        setRefreshDatagrid(prev => !prev);
        setRowDetails(prev => ({ ...prev, rowNumber: newId }));
    };

    return (
        <>
            <DataGridComponent
                headerData={adfPipelineParameterHeaderData}
                apiEndpoint={apiEndpoints.read}
                editRecord={handleEditRecord}
                addRecord={handleAddRecord}
                initialData={adfPipelineParameterSearchState}
                rowDetails={rowDetails}
                refreshDatagrid={refreshDatagrid}
            />

            {showModal
                && (
                    <AdfPipelineParameterForm
                        isOpen={showModal}
                        onClose={handleModalClose}
                        title={modalTitle}
                        rowData={editRowData}
                        onRecordSubmit={handleRecordSubmit}
                        dropdownsData={dropdownsData}
                    />
                )}
        </>
    );
};

export default AdfPipelineParameter;
