import { parseDate } from 'helpers/DateFormatter';
import _ from 'lodash';

const getParsedTableData = (xyz) => xyz.map((item) => ({
  ...item,
  ScheduleStartDate: item.ScheduleStartDate && parseDate(`${item.ScheduleStartDate}Z`),
  ScheduleFinishDate: item.ScheduleFinishDate && parseDate(`${item.ScheduleFinishDate}Z`),
  DateTested: item.DateTested && parseDate(`${item.DateTested}Z`)
}));

export const formatTableData = (data) => {
  const tableData = _.map(data, (ele) => ({
    Mi: ele.Mi,
    HdWorkOrder: ele.MoNumber,
    HdLocationMO: ele.MoFunctionalLocation,
    ScheduleStartDate: ele.MoScheduleStartDate,
    ScheduleFinishDate: ele.MoScheduleFinishDate,
    HdLocation: ele.TagFunctionalLocation,
    DateTested: ele.TagFirstDateTested,
    CompletedBy: ele.TagFirstTestDoneBy,
    TrfShortDesc: ele.TagTestRecordId,
    TestResult: ele.TestResult,
    AsFoundResult: ele.AsFoundResult
  }));
  return getParsedTableData(tableData);
};

const filterByColumn = (column, searchVal, searchSpace, comparator) => {
  const data = searchVal.map((obj) => obj.value);
  if (data.length === 0 || [...new Set(searchSpace.map((x) => x[column]))].length === data.length) {
    return searchSpace;
  }
  return data.flatMap(item => searchSpace.filter(row => comparator(row[column], item)));
};

export function searchFunction(columnTobeSearched = '', searchVal = '', searchSpace = []) {
  if (searchVal === 'Open') {
    return searchSpace.filter(item => item.MoFunctionalLocation !== null);
  }

  if (searchVal === 'Closed') {
    return searchSpace.filter(item => item.MoFunctionalLocation === null);
  }

  if (searchVal === 'all') {
    return searchSpace;
  }

  if (columnTobeSearched === 'TagFirstDateTested' && searchVal === null) {
    return searchSpace;
  }

  if (['TagFunctionalLocation', 'TagFirstTestDoneBy', 'TagTestRecordId'].includes(columnTobeSearched)) {
    return filterByColumn(columnTobeSearched, searchVal, searchSpace, (a, b) => a?.toLowerCase().includes(b?.toLowerCase()));
  }

  if (['TestResult', 'AsFoundResult'].includes(columnTobeSearched)) {
    return filterByColumn(columnTobeSearched, searchVal, searchSpace, (a, b) => a?.toLowerCase() === b?.toLowerCase());
  }

  if (columnTobeSearched === 'TagFirstDateTested') {
    return searchSpace.filter(row => parseDate(`${row.TagFirstDateTested}Z`) === parseDate(searchVal));
  }

  return searchSpace.filter(row => row[columnTobeSearched]?.toLowerCase().includes(searchVal?.toLowerCase()));
}

export const checkNoFilters = (filters) => {
  let flag = true;
  const values = Object.values(filters);
  for (const value of values) {
    if (!value || value.length !== 0) {
      flag = false;
      break;
    }
  }
  return flag;
};
