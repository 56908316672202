import React, { useState } from 'react';
import { DpsUploadHeaderData, DpsUploadInitialState, DpsUploadSearchState, dpsUploadApiEndpoint } from './DpsUpload.data';
import DataGridComponent from 'components/DataGrid/DataGridComponent';
import DpsUploadForm from './DpsUploadForm';

const modalTitle = 'Upload DPS Data Excel File';

const DpsUpload = () => {
    const apiEndpoints = dpsUploadApiEndpoint;
    const [editRowData, setEditRowData] = useState(DpsUploadInitialState);
    const [showModal, setShowModal] = useState(false);
    const [refreshDatagrid, setRefreshDatagrid] = useState(false);
    const [rowDetails, setRowDetails] = useState({
        columnName: 'RowId',
        rowNumber: ''
    });

    const handleAddRecord = () => {
        setShowModal(true);
        setEditRowData(DpsUploadInitialState);
    };

    const handleEditRecord = (obj) => {
        setShowModal(false);
    };

    const handleModalClose = () => {
        setShowModal(false);
        setRefreshDatagrid(prev => !prev);
    };

    const handleRecordSubmit = (newId) => {
        setRowDetails(prev => ({ ...prev, rowNumber: newId }));
    };

    return (
        <>
            <DataGridComponent
                headerData={DpsUploadHeaderData}
                apiEndpoint={apiEndpoints.read}
                addRecord={handleAddRecord}
                rowDetails={rowDetails}
                initialData={DpsUploadSearchState}
                refreshDatagrid={refreshDatagrid}
                editRecord={handleEditRecord}
            />
            {showModal
                && (
                    <DpsUploadForm
                        isOpen={showModal}
                        onClose={handleModalClose}
                        title={modalTitle}
                        rowData={editRowData}
                        onRecordSubmit={handleRecordSubmit}
                    />
                )}
        </>
    );
};
export default DpsUpload;
