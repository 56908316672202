import axios from 'axios';
import { del, get, set } from 'idb-keyval';
import { isMobile as isTablet } from 'react-device-detect';
import offlineStore from 'utils/OfflineDB';
import trfImageStore from 'utils/TRFImageDB';
import { APIEndpoints } from 'helpers/APILists';
import { formatDate } from 'helpers/DateFormatter';
import base64toBlob from 'components/Camera/CaptureImage/Base64toBlob';
import { Trim } from 'helpers/Validation';
import { MESSAGE_TYPE } from '../../common/common.constants';
import { FORM_ACTIONS } from './Forms.constants';

export const errorMsg = 'Network Error';
const successMsg = 'TRF Image saved successfully';

const buildTRFFormData = (img) => {
    const formData = new FormData();
    Object.keys(img).forEach((key) => {
        formData.append(key, img[key]);
    });
    return formData;
};

export const getAllTRFFormImage = () => async (dispatch, getState) => {
    const { Forms: { selectedTag } } = getState();
    const filterData = { MO: selectedTag.HdWorkOrder, Mi: selectedTag.Mi, HdLocation: selectedTag.HdLocation };
    await axios.post(APIEndpoints.GetTRFImages, filterData)
        .then((res) => {
            const data = res.data.map((item) => ({
                ...item,
                Trfimage1: `Image1_${item.HdLocation}`,
                Trfimage2: `Image2_${item.HdLocation}`,
                Trfimage3: `Image3_${item.HdLocation}`
            }));
            dispatch({
                type: FORM_ACTIONS.GET_TRF_FORM_IMAGE,
                payload: {
                    trfFormImage: data
                }
            });
        });
};

export const getPrintImage = (checkedFormData) => async (dispatch, getState) => axios.post(APIEndpoints.GetTRFImagesForPrinting, checkedFormData)
    .then((res) => res.data);
export const submitNewTRFImage = (image, userName, uniqueFormName, imgDraftState) => async (dispatch, getState) => {
    dispatch({
        type: FORM_ACTIONS.FORM_ACTIONS_SUBMITNEWIMAGEDATA_PENDING,
    });
    const { AppData: { isMRATOnline }, Home: { selectedAssetName, regionName, selectedWorkType }, Forms: { selectedTag, selectedTrfId } } = getState();
    const Image = base64toBlob(image);
    const today = new Date();
    const creationDate = formatDate(today);
    const TRFImageData = {
        TrfId: selectedTrfId ?? Trim(selectedTag?.TrfId),
        TRFImage1: Image,
        TRFImage2: null,
        TRFImage3: null,
        BPRegion: regionName,
        HdSite: selectedAssetName,
        HdWorkOrder: selectedTag?.HdWorkOrder,
        Mi: selectedTag?.Mi === null ? '' : selectedTag?.Mi,
        MaintenanceItemPm: selectedTag?.MaintenanceItemPm === null ? '' : selectedTag?.MaintenanceItemPm,
        HdLocation: selectedTag?.HdLocation,
        HdWorkType: selectedWorkType,
        DeviceType: isTablet ? 'Tablet' : 'Laptop',
        UserMode: isMRATOnline ? 'Online' : 'Offline',
        CreationDate: creationDate,
        CreatedBy: userName?.name,
        ModifiedBy: userName?.name,
        DateLastModified: creationDate,
        InDraftState: imgDraftState
    };
    const trfImageFormData = buildTRFFormData(TRFImageData);
    await axios.post(APIEndpoints.SubmitTRFImages, trfImageFormData).then((res) => {
        dispatch({
            type: FORM_ACTIONS.FORM_ACTIONS_SUBMITTRFIMAGE_SUCCESS,
            meta: { message: successMsg }
        });
    }).catch((err) => {
        if (err.message === errorMsg) {
            const offlineObject = {
                url: err.config.url,
                body: TRFImageData
            };
            set(`TRFImages_${uniqueFormName}`, offlineObject, offlineStore)
                .then(() => {
                    dispatch({
                        type: FORM_ACTIONS.FORM_ACTIONS_SUBMITTRFIMAGE_OFFLINE,
                        meta: { messageType: MESSAGE_TYPE.ERROR, message: err.message, exception: err }
                    });
                })
                .catch((offlineErr) => {
                    dispatch({
                        type: FORM_ACTIONS.FORM_ACTIONS_SUBMITTRFIMAGE_FAILURE,
                        meta: { messageType: MESSAGE_TYPE.ERROR, message: offlineErr.message, exception: offlineErr }
                    });
                });
        } else {
            dispatch({
                type: FORM_ACTIONS.FORM_ACTIONS_SUBMITTRFIMAGE_FAILURE,
                meta: { messageType: MESSAGE_TYPE.ERROR, message: err.message, exception: err }
            });
        }
    });
};

export const updateExistingTRFImage = (image, userName, rowid, uniqueFormName, imgDraftState) => async (dispatch, getState) => {
    dispatch({
        type: FORM_ACTIONS.FORM_ACTIONS_SUBMITNEWIMAGEDATA_PENDING,
    });
    const { AppData: { isMRATOnline }, Home: { selectedAssetName, regionName, selectedWorkType }, Forms: { selectedTag, selectedTrfId } } = getState();
    const Image = base64toBlob(image);
    const today = new Date();
    const creationDate = formatDate(today);
    const TRFImageData = {
        Rowid: rowid,
        TrfId: selectedTrfId ?? Trim(selectedTag?.TrfId),
        TRFImage1: Image,
        TRFImage2: null,
        TRFImage3: null,
        BPRegion: regionName,
        HdSite: selectedAssetName,
        DeviceType: isTablet ? 'Tablet' : 'Laptop',
        UserMode: isMRATOnline ? 'Online' : 'Offline',
        HdWorkOrder: selectedTag?.HdWorkOrder,
        Mi: selectedTag?.Mi === null ? '' : selectedTag?.Mi,
        MaintenanceItemPm: selectedTag?.MaintenanceItemPm === null ? '' : selectedTag?.MaintenanceItemPm,
        HdLocation: selectedTag?.HdLocation,
        HdWorkType: selectedWorkType,
        ModifiedBy: userName?.name,
        DateLastModified: creationDate,
        InDraftState: imgDraftState
    };
    const trfImageFormData = buildTRFFormData(TRFImageData);
    await axios.put(APIEndpoints.SubmitExistingTRFImages, trfImageFormData).then((res) => {
        dispatch({
            type: FORM_ACTIONS.FORM_ACTIONS_SUBMITTRFIMAGE_SUCCESS,
            meta: { message: successMsg }
        });
    }).catch((err) => {
        if (err.message === errorMsg) {
            const offlineObject = {
                url: err.config.url,
                body: TRFImageData,
                requestType: 'PUT'
            };
            set(`TRFImages_${uniqueFormName}`, offlineObject, offlineStore)
                .then(() => {
                    dispatch({
                        type: FORM_ACTIONS.FORM_ACTIONS_SUBMITTRFIMAGE_OFFLINE,
                        meta: { messageType: MESSAGE_TYPE.ERROR, message: err.message, exception: err }
                    });
                })
                .catch((offlineErr) => {
                    dispatch({
                        type: FORM_ACTIONS.FORM_ACTIONS_SUBMITTRFIMAGE_FAILURE,
                        meta: { messageType: MESSAGE_TYPE.ERROR, message: offlineErr.message, exception: offlineErr }
                    });
                });
        } else {
            dispatch({
                type: FORM_ACTIONS.FORM_ACTIONS_SUBMITTRFIMAGE_FAILURE,
                meta: { messageType: MESSAGE_TYPE.ERROR, message: err.message, exception: err }
            });
        }
    });
};

export const submitP8Image = async (imgData) => {
    const img = base64toBlob(imgData);
    const imgObj = {
        Name: 'SRA Form Image',
        Data: img
    };
    await set('SRA_Form_Image', imgObj, trfImageStore);
};

export const submitNewP8TRFImage = (image, userName, uniqueFormName) => async (dispatch, getState) => {
    dispatch({
        type: FORM_ACTIONS.FORM_ACTIONS_SUBMITNEWIMAGEDATA_PENDING,
    });
    const { AppData: { isMRATOnline }, Home: { selectedAssetName, regionName, selectedWorkType }, Forms: { selectedTag, selectedTrfId } } = getState();
    const Image = base64toBlob(image);
    const P8ImageData = await get('SRA_Form_Image', trfImageStore);
    const P8Image = P8ImageData.Data;
    del('SRA_Form_Image', trfImageStore);
    const today = new Date();
    const creationDate = formatDate(today);
    const TRFImageData = {
        TrfId: selectedTrfId ?? Trim(selectedTag?.TrfId),
        TRFImage1: P8Image,
        TRFImage2: Image,
        TRFImage3: null,
        BPRegion: regionName,
        HdSite: selectedAssetName,
        HdWorkOrder: selectedTag?.HdWorkOrder,
        Mi: selectedTag?.Mi === null ? '' : selectedTag?.Mi,
        DeviceType: isTablet ? 'Tablet' : 'Laptop',
        UserMode: isMRATOnline ? 'Online' : 'Offline',
        MaintenanceItemPm: selectedTag?.MaintenanceItemPm === null ? '' : selectedTag?.MaintenanceItemPm,
        HdLocation: selectedTag?.HdLocation,
        HdWorkType: selectedWorkType,
        CreationDate: creationDate,
        CreatedBy: userName?.name,
        ModifiedBy: userName?.name,
        DateLastModified: creationDate
    };
    const trfImageFormData = buildTRFFormData(TRFImageData);
    await axios.post(APIEndpoints.SubmitTRFImages, trfImageFormData).then((res) => {
        dispatch({
            type: FORM_ACTIONS.FORM_ACTIONS_SUBMITTRFIMAGE_SUCCESS,
            meta: { message: successMsg }
        });
    }).catch((err) => {
        if (err.message === errorMsg) {
            const offlineObject = {
                url: err.config.url,
                body: TRFImageData
            };
            set(`TRFImages_${uniqueFormName}`, offlineObject, offlineStore)
                .then(() => {
                    dispatch({
                        type: FORM_ACTIONS.FORM_ACTIONS_SUBMITTRFIMAGE_OFFLINE,
                        meta: { messageType: MESSAGE_TYPE.ERROR, message: err.message, exception: err }
                    });
                })
                .catch((offlineErr) => {
                    dispatch({
                        type: FORM_ACTIONS.FORM_ACTIONS_SUBMITTRFIMAGE_FAILURE,
                        meta: { messageType: MESSAGE_TYPE.ERROR, message: offlineErr.message, exception: offlineErr }
                    });
                });
        } else {
            dispatch({
                type: FORM_ACTIONS.FORM_ACTIONS_SUBMITTRFIMAGE_FAILURE,
                meta: { messageType: MESSAGE_TYPE.ERROR, message: err.message, exception: err }
            });
        }
    });
};

export const updateExistingP8TRFImage = (image, userName, rowid, uniqueFormName) => async (dispatch, getState) => {
    dispatch({
        type: FORM_ACTIONS.FORM_ACTIONS_SUBMITNEWIMAGEDATA_PENDING,
    });
    const { AppData: { isMRATOnline }, Home: { selectedAssetName, regionName, selectedWorkType }, Forms: { selectedTag, selectedTrfId } } = getState();
    const Image = base64toBlob(image);
    const P8ImageData = await get('SRA_Form_Image', trfImageStore);
    const P8Image = P8ImageData.Data;
    del('SRA_Form_Image', trfImageStore);
    const today = new Date();
    const creationDate = formatDate(today);
    const TRFImageData = {
        Rowid: rowid,
        TrfId: selectedTrfId ?? Trim(selectedTag?.TrfId),
        TRFImage1: P8Image,
        TRFImage2: Image,
        TRFImage3: null,
        BPRegion: regionName,
        HdSite: selectedAssetName,
        HdWorkOrder: selectedTag?.HdWorkOrder,
        DeviceType: isTablet ? 'Tablet' : 'Laptop',
        UserMode: isMRATOnline ? 'Online' : 'Offline',
        Mi: selectedTag?.Mi === null ? '' : selectedTag?.Mi,
        MaintenanceItemPm: selectedTag?.MaintenanceItemPm === null ? '' : selectedTag?.MaintenanceItemPm,
        HdLocation: selectedTag?.HdLocation,
        HdWorkType: selectedWorkType,
        ModifiedBy: userName?.name,
        DateLastModified: creationDate
    };
    const trfImageFormData = buildTRFFormData(TRFImageData);
    await axios.put(APIEndpoints.SubmitExistingTRFImages, trfImageFormData).then((res) => {
        dispatch({
            type: FORM_ACTIONS.FORM_ACTIONS_SUBMITTRFIMAGE_SUCCESS,
            meta: { message: successMsg }
        });
    }).catch((err) => {
        if (err.message === errorMsg) {
            const offlineObject = {
                url: err.config.url,
                body: TRFImageData,
                requestType: 'PUT'
            };
            set(`TRFImages_${uniqueFormName}`, offlineObject, offlineStore)
                .then(() => {
                    dispatch({
                        type: FORM_ACTIONS.FORM_ACTIONS_SUBMITTRFIMAGE_OFFLINE,
                        meta: { messageType: MESSAGE_TYPE.ERROR, message: err.message, exception: err }
                    });
                })
                .catch((offlineErr) => {
                    dispatch({
                        type: FORM_ACTIONS.FORM_ACTIONS_SUBMITTRFIMAGE_FAILURE,
                        meta: { messageType: MESSAGE_TYPE.ERROR, message: offlineErr.message, exception: offlineErr }
                    });
                });
        } else {
            dispatch({
                type: FORM_ACTIONS.FORM_ACTIONS_SUBMITTRFIMAGE_FAILURE,
                meta: { messageType: MESSAGE_TYPE.ERROR, message: err.message, exception: err }
            });
        }
    });
};
