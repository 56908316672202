import styled from 'styled-components';

export const AbsoluteCenterDiv = styled.div`
        position: fixed;
        height: 300px;
        width: 300px;
        top: 50%;
        left: 50%;
        text-align: center;
        transform: translate(-50%, -50%);
`;

export const FlashingLabel = styled.div`
    font-size: xx-large;
`;
