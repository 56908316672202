import React from 'react';
import PropTypes from 'prop-types';
import { HeaderButton } from './Button.styled';

const HeaderButtonMaker = ({ buttonText, handleButton, headerButtonWidth, headerButtonHeight, isDisabled }) => (
    <HeaderButton
        type="button"
        onClick={() => handleButton()}
        headerButtonWidth={headerButtonWidth}
        headerButtonHeight={headerButtonHeight}
        disabled={isDisabled}
    >
        {buttonText}
    </HeaderButton>
);

export const PrimaryHeaderButton = ({ buttonText, handleButton }) => (
    <HeaderButtonMaker
        buttonText={buttonText}
        handleButton={handleButton}
    />
);

export const SecondaryHeaderButton = ({ buttonText, handleButton, isDisabled }) => (
    <HeaderButtonMaker
        buttonText={buttonText}
        handleButton={handleButton}
        headerButtonWidth={123}
        headerButtonHeight={59}
        isDisabled={isDisabled}
    />
);

export const CustomHeaderButton = ({ buttonText, handleButton, headerButtonWidth, headerButtonHeight }) => (
    <HeaderButtonMaker
        buttonText={buttonText}
        handleButton={handleButton}
        headerButtonWidth={headerButtonWidth}
        headerButtonHeight={headerButtonHeight}
    />
);

HeaderButtonMaker.propTypes = {
    buttonText: PropTypes.any,
    handleButton: PropTypes.func.isRequired,
    headerButtonHeight: PropTypes.number,
    headerButtonWidth: PropTypes.number,
    isDisabled: PropTypes.bool
};

PrimaryHeaderButton.propTypes = {
    buttonText: PropTypes.any.isRequired,
    handleButton: PropTypes.func.isRequired
};

SecondaryHeaderButton.propTypes = {
    buttonText: PropTypes.any.isRequired,
    handleButton: PropTypes.func.isRequired,
    isDisabled: PropTypes.bool
};
CustomHeaderButton.propTypes = {
    buttonText: PropTypes.any,
    handleButton: PropTypes.func.isRequired,
    headerButtonHeight: PropTypes.number.isRequired,
    headerButtonWidth: PropTypes.number.isRequired,
};
