import DataGridComponent from 'components/DataGrid/DataGridComponent';
import React, { useState } from 'react';
import {
    prodMultiroleLoginExclusionHeaderData, prodMultiroleLoginExclusionApiEndpoint,
    prodMultiroleLoginExclusionSearchState,
    prodMultiroleLoginExclusionInitialState
} from './ProdMultiroleLoginExclusion.data';
import ProdMultiroleLoginExclusionForm from './ProdMultiroleLoginExclusionForm';
import { trackErrors } from '../helper';
import { MESSAGE_TYPE } from 'journeys/common/common.constants';

const addTitle = 'Add New Record for Role Login Exclusion';
const editTitle = 'Edit Record for Role Login Exclusion';

const ProdMultiroleLoginExclusion = () => {
    const apiEndpoints = prodMultiroleLoginExclusionApiEndpoint;
    const [showModal, setShowModal] = useState(false);
    const [editRowData, setEditRowData] = useState(prodMultiroleLoginExclusionInitialState);
    const [modalTitle, setModalTitle] = useState(addTitle);
    const [refreshDatagrid, setRefreshDatagrid] = useState(false);
    const [rowDetails, setRowDetails] = useState({
        columnName: 'RowId',
        rowNumber: ''
    });
    const handleEditRecord = (obj) => {
        const rowData = obj.row;
        setModalTitle(editTitle);
        setShowModal(true);
        setEditRowData((prev) => ({
            ...prev,
            ...rowData
        }));
    };
    const handleAddRecord = () => {
        setEditRowData(prodMultiroleLoginExclusionInitialState);
        setModalTitle(addTitle);
        setShowModal(true);
    };

    const handleModalClose = () => {
        setShowModal(false);
    };

    const handleRecordSubmit = (newId) => {
        setRefreshDatagrid(prev => !prev);
        setRowDetails(prev => ({ ...prev, rowNumber: newId }));
    };

    const parseData = (data) => new Promise((resolve, reject) => {
        (async () => {
            try {
                const result = data.map(item => ({
                    ...item,
                   Active: item?.Active ? 'Active' : 'Inactive',
                }));
            resolve(result);
            } catch (err) {
                trackErrors({
                    messageType: MESSAGE_TYPE.ERROR,
                    message: err.message,
                    exception: err
                });
                reject();
            }
        })();
    });

    return (
        <>
            <DataGridComponent
                headerData={prodMultiroleLoginExclusionHeaderData}
                apiEndpoint={apiEndpoints.read}
                editRecord={handleEditRecord}
                addRecord={handleAddRecord}
                initialData={prodMultiroleLoginExclusionSearchState}
                rowDetails={rowDetails}
                refreshDatagrid={refreshDatagrid}
                dataParser={parseData}
            />

            {showModal
                && (
                    <ProdMultiroleLoginExclusionForm
                        isOpen={showModal}
                        onClose={handleModalClose}
                        title={modalTitle}
                        rowData={editRowData}
                        onRecordSubmit={handleRecordSubmit}
                    />
                )}
        </>
    );
};

export default ProdMultiroleLoginExclusion;
