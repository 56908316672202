import { FoundResultAsFoundResultFormula, FoundResultAsFoundResultFormulaP622 } from './P6012.formulas';
import { disableDpsFields, signalToTriggerIsErrorCheck } from '../../../SignalToTrigger/SignalToTrigger.data';

export const FoundResultSectionDataP6012 = (selectedTag, formName, formData) => {
    let AftResultFormula;
    if (['P6-22', 'P7-22', 'P7-15', 'P7-14', 'P7-18', 'P6-12', 'P7-29', 'P8-8.1', 'P8-12.1', 'P7-30', 'P6-27'].includes(formName)) {
        AftResultFormula = FoundResultAsFoundResultFormulaP622(formData, formName);
    } else {
        AftResultFormula = FoundResultAsFoundResultFormula(formData);
    }
    return {
        rows: [{
            key: 1,
            fields: [{
                key: 'F101AftToleranceLabel',
                value: 'Tolerance',
                xs: 2,
                width: '100%',
                readOnly: true,
                labelWidth: '0',
                height: '1.1rem',
                borderRight: '0',
                borderBottom: '0',
                marginLeft: '0.26rem',
                borderLeft: '0',
                isLabelNew: true,
                marginBottom: '4px',
                marginRight: '0',
                marginTop: '3.3px',
                borderTop: '0',
            },
            {
                key: 'F101AftTolerance',
                value: (selectedTag.DateTested && !signalToTriggerIsErrorCheck(formName, formData)) ? formData?.F101AftTolerance
                 : disableDpsFields(formData.F472SignalToTriggerLevelPriortoTest, selectedTag?.ToleranceInInstrumentEu, formData?.F101AftTolerance, formName),
                xs: 2,
                width: '100%',
                labelWidth: '0',
                type: 'number',
                maxLength: '10',
                textAlign: 'center',
                height: '1.16rem',
                borderBottom: '0',
                isRequired: !signalToTriggerIsErrorCheck(formName, formData),
                isDisabled: signalToTriggerIsErrorCheck(formName, formData),
                readOnly: signalToTriggerIsErrorCheck(formName, formData),
            },
            {
                key: 'F102AftResultLabel',
                value: 'As-Found Result',
                width: '100%',
                readOnly: true,
                labelWidth: '0',
                xs: 2,
                borderRight: '0',
                borderBottom: '0',
                borderTop: '0',
                borderLeft: '0',
                isLabelBold: true,
                textAlign: 'end',
                marginTop: '4px',
                height: '1rem',
            },
            {
                key: 'F102AftResult',
                value: AftResultFormula,
                readOnly: true,
                isCalculatedNew: true,
                xs: 2,
                width: '100%',
                labelWidth: '0',
                textAlign: 'center',
                height: '1.16rem',
                borderBottom: '0',
            }]
        }]
    };
};
