import styled, { css } from 'styled-components';

export const TextBox = styled.input`
font-size: 13pt;
color: rgb(51, 51, 51);
font-weight: normal;
font-style: normal;
text-align: left;
line-height: 1.2;
padding: 5px;

background-color: rgb(255, 255, 255);
border-style: solid;
border-width: 2px;
border-color: rgb(166, 166, 166);
border-radius:0;
-webkit-border-radius:0;
-webkit-appearance:none;
height: 46px;
resize:none;
outline:0;
overflow-y:auto;
margin:0;

&:focus {
    border: 4px solid rgb(166, 166, 166) !important;
    margin:-2px;
}

&:hover {
    border: 2px solid rgb(51, 91, 3);
}

&:active {
    border: 4px solid rgb(166, 166, 166) !important;
    margin:-2px;
}

${props => props.label && css`
    :hover {
        border: 2px rgb(166, 166, 166) !important;    
    }
`}
`;
