import { FieldHasValue } from 'helpers/Validation';
import { commonConstants } from 'journeys/portal/Forms/Sections.constants';

export const FoundResultFormula = (formData) => {
    if (FieldHasValue(formData.F783AltMovedTargetPositionActivateSwitch)
        && FieldHasValue(formData.F784AltMovedTargetPositionDeactivateSwitch)
        && FieldHasValue(formData.F785AltConfirmSwitchActivatesCorrectly)
        && FieldHasValue(formData.F786AltConfirmSwitchDeactivatesCorrectly)) {
        return (formData.F783AltMovedTargetPositionActivateSwitch === 'YES'
            && formData.F784AltMovedTargetPositionDeactivateSwitch === 'YES'
            && formData.F785AltConfirmSwitchActivatesCorrectly === 'YES'
            && formData.F786AltConfirmSwitchDeactivatesCorrectly === 'YES')
            ? commonConstants.PASS_Result : commonConstants.FAIL_Result;
    }
    return '';
};
